import { Grid, Typography, Divider, Collapse } from "@mui/material";

export const NotifDetails = ({ open, data }) => {
    return (
        <Grid xs={12}>
            <Collapse in={open}>
                <Grid xs={12} container>
                    <Grid xs={1.5} style={{ backgroundColor: "white" }}>
                        <Typography
                            style={{
                                fontSize: "10px",
                                color: "#BAC4CC",
                                fontFamily: "inherit",
                                marginBottom: 5,
                            }}
                        >
                            Device:
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "10px",
                                color: "#BAC4CC",
                                fontFamily: "inherit",
                                marginBottom: 5,
                            }}
                        >
                            Category:
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "10px",
                                color: "#BAC4CC",
                                fontFamily: "inherit",
                            }}
                        >
                            Users:
                        </Typography>
                    </Grid>
                    <Grid xs={9}>
                        <Typography
                            style={{
                                fontSize: "10px",
                                color: "#31515C",
                                fontFamily: "inherit",
                                marginBottom: 5,
                            }}
                        >
                            {data.device}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "10px",
                                color: "#31515C",
                                fontFamily: "inherit",
                                marginBottom: 5,
                            }}
                        >
                            {data.category}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "10px",
                                color: "#31515C",
                                fontFamily: "inherit",
                                marginBottom: 5,
                            }}
                        >
                            {data.users}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: 15, marginBottom: 10 }}></Divider>
            </Collapse>
        </Grid>
    );
};
