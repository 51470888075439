import { SnackbarContent,Snackbar } from '@mui/material';
import Fade from '@mui/material/Fade';
export const Succsess =({title,snackopen,action,position })=>{
    
    return<Snackbar
    open={snackopen}
     onClose={action}
    // transitionDuration={2000}
    key={Fade}
    anchorOrigin={{vertical: position!==undefined ? position.vertical:"bottom" ,horizontal:position!==undefined ?position.horizontal: "right"} }
    
    
    autoHideDuration={4000}
    // key={state.Transition.name}
  >
    {/* <SnackbarContent style={{
  backgroundColor:'teal',
}}
message={
}
/> */}
<svg xmlns="http://www.w3.org/2000/svg" width="367" height="48" viewBox="0 0 367 48">
<path id="Path_11865" data-name="Path 11865" d="M0,0H357a10.136,10.136,0,0,1,7.675,3.59A9.76,9.76,0,0,1,367,10V38a10,10,0,0,1-10,10H0Z" fill="#e9fdd1"/>
<path onClick={action} style={{cursor:"pointer"}} id="fi-rr-cross-small" d="M16.144,5.956h0a.849.849,0,0,0-1.2,0L11.05,9.849,7.156,5.956a.849.849,0,0,0-1.2,0h0a.849.849,0,0,0,0,1.2L9.849,11.05,5.956,14.943a.849.849,0,0,0,0,1.2h0a.849.849,0,0,0,1.2,0L11.05,12.25l3.894,3.894a.849.849,0,0,0,1.2,0h0a.849.849,0,0,0,0-1.2L12.25,11.05l3.894-3.894A.849.849,0,0,0,16.144,5.956Z" transform="translate(320.01 13.293)" fill="#aab6bf"/>
<g  id="Group_17942" data-name="Group 17942" transform="translate(65 15.428)">
<text id="Icon_Uploaded" data-name="Icon Uploaded" transform="translate(28 13.572)" fill="#aab6bf" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">{title}</tspan></text>
<g id="Group_17941" data-name="Group 17941">
  <circle id="Ellipse_1247" data-name="Ellipse 1247" cx="8" cy="8" r="8" transform="translate(0 -0.428)" fill="#aab6bf"/>
  <path id="fi-rr-check" d="M10.061,4.269l-6.22,6.219a.45.45,0,0,1-.638,0L.8,8.081a.45.45,0,0,0-.638,0h0a.45.45,0,0,0,0,.638l2.406,2.406a1.354,1.354,0,0,0,1.913,0L10.7,4.906a.45.45,0,0,0,0-.637h0a.45.45,0,0,0-.638,0Z" transform="translate(2.57 0.172)" fill="#fff" stroke="#fff" stroke-linecap="square" stroke-width="0.5"/>
</g>
</g>
</svg>

  </Snackbar>
}
export const DeletandBlock =({title,action,snackopen,text1,text2})=>{
    console.log(text1)
    return<Snackbar
    open={snackopen}
    onClose={action}
    
    
    
    
    autoHideDuration={4000}
    // key={state.Transition.name}
  >
       <svg xmlns="http://www.w3.org/2000/svg" width="366.1" height="49" viewBox="0 0 366.1 49">
    <g id="Group_17954" data-name="Group 17954" transform="translate(-146.475 -861.373)">
      <path id="Path_11839" data-name="Path 11839" d="M0,0H356.125A9.988,9.988,0,0,1,366.1,10V39a9.988,9.988,0,0,1-9.975,10H0Z" transform="translate(146.475 861.373)" fill="#fff5e5"/>
      <text id="Bahar_Blocked" data-name="Bahar Blocked" transform="translate(220.574 890.373)" fill="#aab6bf" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">{text1} {text2}</tspan></text>
      <path id="fi-rr-cross-small" d="M16.144,5.956h0a.849.849,0,0,0-1.2,0L11.05,9.849,7.156,5.956a.849.849,0,0,0-1.2,0h0a.849.849,0,0,0,0,1.2L9.849,11.05,5.956,14.943a.849.849,0,0,0,0,1.2h0a.849.849,0,0,0,1.2,0L11.05,12.25l3.894,3.894a.849.849,0,0,0,1.2,0h0a.849.849,0,0,0,0-1.2L12.25,11.05l3.894-3.894A.849.849,0,0,0,16.144,5.956Z" transform="translate(465.584 874.666)" fill="#d0dbe5"/>
    </g>
  </svg> 
  </Snackbar>
  
  
}
