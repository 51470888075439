import { gql, useMutation } from '@apollo/client';
export const QUESTIONS = gql`

query {
    interestList {
        id
        text
        createdAt
        updatedAt
    }
    getInterestStatistics {
        name
        count
      }
}

`;
export const ADDINTREST =gql`

mutation (
    $text: String
) {
    addInterest(
        input: {
            text:$text
        }
    ) {
        data{
            id
            text
            createdAt
        }    
    }
}
`
export const EDIT_INTREST =gql`

mutation (
    $text: String,
    $id:ID
) {
    editInterest(
        input: {
            text:$text,id:$id
        }
    ) {
        data{
            text
        }    
    }
}
`
export const DELETE_INTREST =gql`

mutation (
    
    $id:ID!
) {
    removeInterest(
        id:$id
    ) {
        message 
    }
}
`


// data:{username:$username