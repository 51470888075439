import React, { useEffect } from "react";
import {
    Dialog,
    Grid,
    TextField,
    Typography,
    Button,
    IconButton,
} from "@mui/material";
import { useStyles } from "../../../../../StartPage/Styles";
import { useState } from "react";
import { Customdivider } from "../divider/Customdivider";
import { makeStyles } from "@mui/styles";
import { Test } from "../../Category/Icons/SearchIcons";
import { Styles } from "./Styles";
import { baseurl, baseurl2 } from "../../../../../BaseUrl";
// import { AddIcon, Camera } from "../../Category/Icons/Camera";
const useStyles1 = makeStyles(() => ({
    paper: {
        minWidth: "700px",
        maxHeight: "400px",
        borderRadius: "20px !important",
    },
    paper1: {
        minWidth: "950px",
        maxHeight: "300px",
        borderRadius: "20px !important",
    },
}));
export const Editdialog = ({ i, close,inputvalue, submit, item, type, title ,customsubmit }) => {
    const [helperText, setHelperText] = useState({ show: false, text: "" });
    const Styles2 = Styles();

    const [value, setvalue] = useState("");
    const [image, setimage] = useState(`${baseurl}` + `${item?.image}`);
    const [formData, setFormData] = useState({
        id: "",
        name: "",
    });
    const handleinput = (e) => {
        setvalue({ ...formData, name: e.target.value });
        setFormData({ ...formData, name: e.target.value });
        inputvalue(e.target.value)
    };
    const submitValidator = () => {
        if (formData.name === undefined || formData.name === "") {
            setHelperText({ show: true, text: "* This feild is Required " });
        } else if (
            formData.image === undefined ||
            formData.image === "" ||
            formData.image === {}
        ) {
            setHelperText({
                show: true,
                text: "* Choose at least one image  ",
            });
        } else {
            setHelperText({ show: false, text: "" });
            submit(formData);
        }
    };

    useEffect(() => {
        setvalue(item);
        if (type === "Add") {
            setvalue({ ...formData, name: "" });
        } else {
            setFormData({ id: item?._id, name: item?.name });
        }
        setimage(`${baseurl}` + `${item?.image}`)
    }, [i]);
    console.log(item);
    return (
        <Dialog
            open={i}
            close={() => close(false)}
            classes={{ paper: Styles2.paper }}
            style={{ minWidth: "900px !important" }}
        >
            <div className={Styles2.inner}>
                <Typography className={Styles2.Header}>{title}</Typography>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(false);
                        // alert("hello")
                        // submitValidator();
                        if(customsubmit){
                            customsubmit()
                        }else{
                            submitValidator();
                        }
                    }}
                    style={{ display: "flex", paddingTop: 50 }}
                >
                    <div
                        style={{
                            backgroundImage: `url(${image})`,
                            borderRadius: 60,
                            height: 90,
                            width: 90,
                            backgroundSize: "cover",
                        }}
                    >
                        <div
                            style={{
                                marginTop: 45,
                                marginLeft: 55,
                            }}
                        >
                            <IconButton variant="contained" component="label">
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            image: e.target.files[0],
                                        });
                                       
                                        var reader = new FileReader();
                                        setimage(e.target.files[0]);
                                        reader.onload = function (event) {
                                            setimage(event.target.result);
                                        };

                                        reader.readAsDataURL(e.target.files[0]);
                                        console.log(image,'imagee');
                                    }}
                                    hidden
                                />
                                {/* <Camera /> */}
                            </IconButton>
                        </div>
                    </div>
                    <div>
                        <div style={{ width: "570px" }}>
                            <TextField
                                id="title"
                                fullWidth
                                value={value?.name}
                                error={helperText.show}
                                helperText={helperText.text}
                                onChange={handleinput}
                                InputProps={{
                                    startAdornment: <Test text={"Name"} />,
                                }}
                                inputProps={{
                                    style: {
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        paddingLeft: 5,
                                    },
                                }}
                                style={{
                                    marginLeft: 60,
                                    marginTop: "15px",
                                }}
                                className={Styles2.AddSport}
                            />
                        </div>
                    </div>
                </form>
                <Grid
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: 60,
                        paddingRight: 40,
                    }}
                >
                    <Button
                        onClick={() => {
                            close(false);
                            
                        }}
                        className={Styles2.cancel}
                    >
                        Cancel
                    </Button>
                    <submit>
                        <Button
                            type="submit"
                            onClick={() => {
                                if(customsubmit){
                                    customsubmit()
                                }else{
                                    submitValidator();
                                }
                                
                            }}
                            className={Styles2.submit}
                        >
                            Submit
                        </Button>
                    </submit>
                </Grid>
            </div>
        </Dialog>
    );
};

export const Editadd = ({ open, action, actiontext, submitForm, close }) => {
    const classs = useStyles1();
    const styles = Styles();
    return (
        <Dialog
            classes={{ paper: classs.paper }}
            open={open}
            style={{ width: "800px !important" }}
        >
            <Grid style={{ width: "100%", height: "300px", borderRadius: 50 }}>
                <Grid xs={9.75} style={{ margin: "auto" }}>
                    <Typography className={Styles.Header}>Add Sport</Typography>

                    <Customdivider size={12} />
                    <Grid style={{ margin: "auto" }}>
                        <Grid
                            style={{
                                border: "1px solid #D0DBE5",
                                marginTop: 45,
                                paddingBlock: 10,
                                borderRadius: "15px",
                            }}
                            container
                        >
                            <Grid
                                xs={6}
                                style={{
                                    borderRight: "1px solid #D0DBE5",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBlock: "5px",
                                }}
                            >
                                <Typography
                                    style={{
                                        marginLeft: 3,
                                        margin: "auto",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                        fontSize: 12,
                                        color: "#31515C",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        color: "#3b3bff",
                                    }}
                                >
                                    Download Translates
                                </Typography>
                            </Grid>
                            <Grid
                                xs={6}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBlock: "5px",
                                }}
                            >
                                {" "}
                                <Typography
                                    style={{
                                        marginLeft: 3,
                                        margin: "auto",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                        color: "#3b3bff",
                                        fontSize: 12,
                                        color: "#31515C",
                                        pointer: "cursor",
                                        textDecoration: "underline",
                                        color: "#3b3bff",
                                    }}
                                >
                                    Import Translate File
                                </Typography>{" "}
                            </Grid>
                        </Grid>
                        <Grid
                            xs={12}
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                                marginTop: "20px",
                            }}
                        >
                            {" "}
                            <div style={{ display: "flex" }}>
                                <Button
                                    onClick={close}
                                    style={{
                                        backgroundColor: "#FFF",
                                        height: 40,
                                        width: 110,
                                        marginRight: 30,
                                        marginTop: "3px",
                                        border: "1px solid ##3939F8",
                                        textTransform: "none",
                                        borderRadius: 8,
                                        color: "##3939F8",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    onClick={() => {
                                        submitForm();
                                    }}
                                    style={{
                                        backgroundColor: "#3b3bff",
                                        height: 40,
                                        width: 110,
                                        textTransform: "none",
                                        color: "white",
                                        borderRadius: 8,
                                        marginTop: "3px",
                                        border: "1px solid #e9eef3",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export const AddSport = ({
    open,
    close,
    submit,
    value,
    type,
    vv,
    title,
    fileget,
    languagepage,
    err,
}) => {
    const classs = useStyles1();
    const classes = useStyles();

    const [formData, setFormData] = useState({});
    const [file, setfile] = useState({});
    const [filename, setfilename] = useState("");

    React.useEffect(() => {
        setFormData({ ...value });

        console.log(formData);
    }, [value]);
    const changeInput = (e) => {
        if (type === "add_lang") {
            setFormData({
                ...formData,
                title: e.target.value,
                name: e.target.value,
            });
        } else {
            setFormData({ ...formData, name: e.target.value });
        }
    };
    const changeinput2 = (e) => {
        if (languagepage) {
            setFormData({ ...formData, title: e.target.value });
        }
    };

    return (
        <Dialog
            classes={{ paper: classs.paper1 }}
            open={open}
            style={{ width: "800px !important" }}
        >
            <Grid
                style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: 50,
                    paddingBottom: "30px",
                }}
            >
                <Grid xs={9.75} style={{ margin: "auto" }}>
                    <Typography
                        className={Styles.Header}
                        style={{
                            marginLeft: 3,
                            paddingTop: 40,
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            fontSize: 18,
                            color: "#31515C",
                        }}
                    >
                        Add Sport
                    </Typography>

                    <Customdivider size={12} />
                    <Grid style={{ margin: "auto" }}>
                        <Grid container style={{ height: "220px" }}>
                            <Grid
                                xs={4}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K")`,
                                        borderRadius: 60,
                                        height: 90,
                                        width: 90,
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: 45,
                                            marginLeft: 55,
                                        }}
                                    >
                                        <IconButton
                                            variant="contained"
                                            component="label"
                                        >
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    var fdata = new FormData();
                                                    setfile(e.target.files[0]);

                                                    fdata.append(
                                                        "file",
                                                        e.target.files[0]
                                                    );
                                                    setfilename(
                                                        e.target.files[0].name
                                                    );
                                                    console.log(
                                                        e.target.files[0]
                                                    );
                                                    fileget(e.target.files[0]);
                                                }}
                                                hidden
                                            />
                                            {/* <AddIcon/> */}
                                        </IconButton>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                xs={8}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: "15px" }}
                                >
                                    <TextField
                                        id="title"
                                        fullWidth
                                        value={
                                            formData.name ??
                                            formData.event ??
                                            ""
                                        }
                                        onChange={changeInput}
                                        InputProps={{
                                            startAdornment: (
                                                <Test text={title} />
                                            ),
                                        }}
                                        inputProps={{
                                            style: {
                                                paddingTop: 12,
                                                paddingBottom: 12,
                                                paddingLeft: 5,
                                            },
                                        }}
                                        className={classes.AddSport}
                                    />
                                    <TextField
                                        id="title"
                                        fullWidth
                                        value={
                                            formData.title ??
                                            formData.event ??
                                            ""
                                        }
                                        onChange={changeinput2}
                                        InputProps={{
                                            startAdornment: (
                                                <Test
                                                    text={
                                                        languagepage
                                                            ? languagepage
                                                            : title
                                                    }
                                                />
                                            ),
                                        }}
                                        inputProps={{
                                            style: {
                                                paddingTop: 12,
                                                paddingBottom: 12,
                                                paddingLeft: 5,
                                            },
                                        }}
                                        style={{
                                            display: languagepage
                                                ? "block"
                                                : "none",
                                            marginTop: "15px",
                                        }}
                                        className={classes.AddSport}
                                    />
                                    {err ===
                                    "res.input.image.then is not a function" ? (
                                        <Typography
                                            style={{
                                                margin: 10,
                                                color: "#d80027",
                                            }}
                                        >
                                            Choose at least one image
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                    {err ===
                                    "play validation failed: name: Path `name` is required., title: Path `title` is required." ? (
                                        <Typography
                                            style={{
                                                margin: 10,
                                                color: "#d80027",
                                            }}
                                        >
                                            Name is Required!
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                    {err === "file type is wrong !" ? (
                                        <Typography
                                            style={{
                                                margin: 10,
                                                color: "#d80027",
                                            }}
                                        >
                                            File type is wrong (only PNG)!
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                xs={10.8}
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                    marginBottom: "35px",
                }}
            >
                {" "}
                <Grid>
                    <Button
                        onClick={close}
                        style={{
                            backgroundColor: "#FFF",
                            height: 40,
                            width: 110,
                            marginRight: 30,
                            marginTop: "3px",
                            border: "1px solid ##3939F8",
                            textTransform: "none",
                            borderRadius: 8,
                            color: "#3939F8",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={() => {
                            if (type === "edit") {
                                submit(formData);
                                console.log(formData);
                            } else if (type === "add") {
                                submit(formData);
                                setFormData({});
                            } else if (type === "add_lang") {
                                setFormData({
                                    ...formData,
                                    title: formData.name,
                                });
                                console.log("here");
                                submit(formData);
                            }
                        }}
                        style={{
                            backgroundColor: "#3b3bff",
                            height: 40,
                            width: 110,
                            textTransform: "none",
                            color: "white",
                            borderRadius: 8,
                            marginTop: "3px",
                            border: "1px solid #e9eef3",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                        }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};
