import { makeStyles } from "@mui/styles";

export const Styles = makeStyles({
    searchpart: {
        backgroundColor: "white",
        borderRadius: "1vw !important",
        boxShadow: "4px 4px 20px #d7e3eb !important",
        padding: "12px 40px !important",
    },
    outlet: {
        paddingTop: "4vh !important",
        paddingLeft: "4vw !important",
        backgroundColor: "white !important",
        borderRadius: "1vw !important",
        marginTop: "15px !important",
        boxShadow: "4px 4px 20px #d7e3eb !important",
    },
    title: {
        color: "#31515C",
        fontSize: "11px !important",
        fontWeight: "bold !important",
        fontFamily: "Montserrat-Medium !important !important",
        display: "flex",
        alignItems: "center",
    },
    plusicon: {
        display: "flex",
        justifyContent: "center",
        marginBottom: -20,
    },
    addsportcontainer: {
        backgroundColor: "white",
        paddingLeft: 56,
        borderRadius: 20,
        backgroundColor: "white",
        marginTop: 20,
        marginBottom: 20,
    },
    buttonpart: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    container: {
        backgroundColor: "#F2F5F8 !important",
        marginTop: "18px !important",
        "&:hover": {
            backgroundColor: "#EAEEFA",
        },
    },
    title2: {
        marginLeft: 3,
        paddingTop: 34,
        paddingBottom: 27,
        fontFamily: "Montserrat-Regular !important-Regular !important",
        fontSize: 18,
    },

    iconstyle: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        paddingRight: "25px",
    },
    checkboxdad: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#DDE5EC",
    },
    checkboxinner: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    listcontainer: {
        backgroundColor: "#F2F5F8",
        marginTop: 10,
        "&:hover": { backgroundColor: "red" },
    },
    checkboxstyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    itemname: {
        color: "#31515C !important",
        fontSize: "11px !important",
        fontFamily: "Montserrat-Regular !important !important",
    },
    garbage: {
        width: "50%",
        paddingLeft: 10,
        margin: "auto",
    },
    speeddialinner: {
        position: "absolute",
        left: "94.5%",
        bottom: "7%","MuiButtonBase-root":{
            backgroundColor:"red"
        }
    },
    paginationinner: {
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10
    },
    speeddialinner: {
        position: "absolute",
        left: "94.5%",
        bottom: "7%",
        ".MuiButtonBase-root":{backgroundColor:"red"}
    },
});
