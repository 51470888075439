import { Button } from "@mui/material";
export const SearchIcon = ({ action }) => (
  <Button
    onClick={() => {
      action();
    }}
    style={{
      width: "80px",
      height: "57px",
      backgroundColor: "#F1F3F7",
      borderColor: "#000",
      borderWidth:"1px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "20px 0px 0px 20px",
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "21px", width: "21px" }}
      cursor="pointer"
      viewBox="0 0 23.798 23.294"
    >
      <g id="search" transform="translate(1 1)">
        <circle
          id="Oval"
          cx="8.89"
          cy="8.89"
          r="8.89"
          fill="none"
          stroke="#93A2C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <path
          id="Path"
          d="M5.471,5.471,0,0"
          transform="translate(15.913 15.409)"
          fill="none"
          stroke="#93A2C0"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="2"
        />
      </g>
    </svg>
  </Button>
);
// export const Test =()=><span style={{borderRight:"1px solid grey",paddingLeft:10,fontSize:"12px",backgroundColor:"none",paddingRight:5,width:"25%",fontFamily:"Montserrat-Regular !important"}}>{props.text}</span>
export const Test = (props) => (
  <span
    style={{
      color: "#31515C",
      borderRight: "1px solid #D0DBE5",
      paddingLeft: 10,
      fontSize: "12px",
      backgroundColor: "none",
      paddingRight: 5,
      width: "25%",
      fontFamily: "Montserrat-Regular !important-Regular !important",
    }}
  >
    {props.text}
  </span>
);
