import { Typography } from "@mui/material"
import { styles } from "./styles"

export const Tablecell = ({text,disable})=>{
    const style= styles()
    return(
    <Typography
                className={disable?style.celldisable: style.cell}
            >
                {text}
            </Typography>
)

}