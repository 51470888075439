import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Card, Grid, Typography } from "@mui/material";
import moment from "moment";
import { Bar_Chart } from "../../../../components/Charts/BarChart";
import Divider from "@mui/material/Divider";


//
import { QUESTIONS, STATISTICS } from "../../../../api/Questions";
import { AlertDialog } from "../../../../Components1/MainDashboard/Container/components/Dialog/AlertDialogs";
import { Editdialog } from "../../../../Components1/MainDashboard/Container/components/editadd/editadd";
import { Succsess } from "../../../../Components1/MainDashboard/Container/components/feedbacks/Feedbacks";
import { PlusIcon } from "../../../../Components1/MainDashboard/Container/Languages/icons/plusicon";
import { Backdrops } from "../../../../components/Backdrops";
import DataTable from "../../../../components/DataTables";
import ActionButtons from "../../../../components/Buttons/ActionButtons";
import { CSV_Downloader } from "../../../../components/CSV_Downloader";
import { SearchBar } from "../../../../components/Searchbar";
import Scrollbars from "react-custom-scrollbars-2";

function Questions() {
  const [inputvalue, setinputvalue] = useState("");
  const [questionsList, setQuestionsList] = useState([]);
  const [open, setopen] = useState(false);
  const [val, setval] = useState([]);
  const [openadd, setopenadd] = useState(false);
  const [opendialog, setopendialog] = useState(false);
  const [type, settype] = useState("");
  const [item, setitem] = useState("");
  const [message, setMessage] = useState("");
  const [snackopen, setsnackopen] = useState(false);
  const { data, loading, error } = useQuery(QUESTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setQuestionsList(data.interestList);
      console.log(data)
    },
  });


  var formdata = new FormData();
  const HeaderData = [
    { field: "text", title: "Text" },
    { field: "CreatedTime", title: "Created Date", type: "dateEn" },
    // { field: "action", title: "Action" },
  ];

  if (loading) return <Backdrops loading={loading} />;
  if (error)
    return (
      <span>
        Error Fetching Data ! <br /> Please Reload
      </span>
    );
  if (questionsList !== null) {
    return (
      <Grid spacing={1} style={{ marginTop: "15px" }}>
        <SearchBar />
        <Grid item xs={12} style={{ marginLeft: 10 }}>
          <Grid
            style={{
              height: "calc(80vh - 110px)",
              borderRadius: "15px",
              marginTop: "15px",
              // paddingLeft:"8px",
            }}
            spacing={1}
            container
          >
            {/* <UsersLists loading={loading} data1={data } error = {error}/> */}
            <Grid
              item
              xs={8}
              style={{
                backgroundColor: "white",
                // width: "66%",
                // height: "100%",
                borderRadius: "15px",
                boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                // marginLeft: "8px",
                marginRight: 0,
                // paddingLeft: "15px",
              }}
            >
              <Scrollbars style={{ borderRadius: "15px" }}>
                <DataTable
                  // xs={5}

                  // title="Sports"
                  loading={loading}
                  headData={HeaderData}
                  data={data?.interestList ?? []}
                  CreatedTime={(item) =>
                    moment(item?.createdAt).format("DD/MMM/YYYY")
                  }
                  action={(item) => (
                    <ActionButtons
                      functions={{
                        remove: () => <AlertDialog />,
                        edit: () => "",
                      }}
                      remove
                      edit
                    />
                  )}
                />
              </Scrollbars>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                paddingTop: 0,
                paddingLeft: 15,
                height: 500,
                backgroundColor: "none",
              }}
            >
              <Card
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "48%",
                  borderRadius: "15px",
                  boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "",
                }}
              >
                <Grid
                  style={{
                    justifyContent: "center",
                    display: "inline-block",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#31515c",
                      margin: 20,
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Total Interests : {data?.interestList.length}
                  </Typography>
                  <Typography style={{ color: "#31515c", margin: 20 }}>
                    {/* <CSV_Downloader dataCSV={data?.allPlayList} title={'Users Data'} fileName={'Sports Data.csv'}/> */}
                    {/* <CSV_Downloader dataCSV={data?.interestList} /> */}
                  </Typography>
                </Grid>
              </Card>

              <Card
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "48%",
                  borderRadius: "15px",
                  boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 15,
                  paddingTop:75,
                  // marginBottom: "",
                }}
              >
                {/* <Grid
                  style={{
                    justifyContent: "center",
                    display: "inline-block",
                    alignItems: "center",
                  }}
                > */} 
                {/* <Grid
            item
            xs={12}
            style={{ marginLeft: 70, marginTop: 30, marginBottom: 30 }}
          >
            <Typography>Sports</Typography>
          </Grid>
          <Divider
            style={{
              margin: "20px auto 0px auto",
              width: "85%",
              borderWidth: "2px",
              borderColor: "#F2F2FF",
            }}
          /> */}
                <Bar_Chart data={data?.getInterestStatistics} color='#F6AACB'/>
                  
                {/* </Grid> */}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default Questions;
