import React, { PureComponent , useState} from "react";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// const data = [
//   {
//     name: "Page A",
//     data: 40,
//   },
//   {
//     name: "Page B",
//     data: 30,
//   },
//   {
//     name: "Page C",
//     data: 20,
//   },
//   {
//     name: "Page D",
//     data: 27,
//   },
//   {
//     name: "Page E",
//     data: 18,
//   },
//   {
//     name: "Page F",
//     data: 23,
//   },
//   {
//     name: "Page G",
//     data: 34,
//   },
//   {
//     name: "Page H",
//     data: 40,
//   },
//   {
//     name: "Page I",
//     data: 30,
//   },
//   {
//     name: "Page J",
//     data: 20,
//   },
//   {
//     name: "Page K",
//     data: 27,
//   },
//   {
//     name: "Page L",
//     data: 18,
//   },
//   {
//     name: "Page M",
//     data: 23,
//   },
//   {
//     name: "Page N",
//     data: 34,
//   },
//   {
//     name: "Page O",
//     data: 40,
//   },
//   {
//     name: "Page P",
//     data: 30,
//   },
//   {
//     name: "Page Q",
//     data: 20,
//   },
//   {
//     name: "Page R",
//     data: 27,
//   },
//   {
//     name: "Page S",
//     data: 18,
//   },
//   {
//     name: "Page T",
//     data: 23,
//   },
//   {
//     name: "Page U",
//     data: 34,
//   },
//   {
//     name: "Page V",
//     data: 40,
//   },
//   {
//     name: "Page W",
//     data: 30,
//   },
//   {
//     name: "Page X",
//     data: 20,
//   },
//   {
//     name: "Page Y",
//     data: 27,
//   },
//   {
//     name: "Page Z",
//     data: 18,
//   },
//   {
//     name: "Page A",
//     data: 40,
//   },
//   {
//     name: "Page B",
//     data: 30,
//   },
//   {
//     name: "Page C",
//     data: 20,
//   },
//   {
//     name: "Page D",
//     data: 27,
//   },
//   {
//     name: "Page E",
//     data: 18,
//   },
//   {
//     name: "Page F",
//     data: 23,
//   },
//   {
//     name: "Page G",
//     data: 34,
//   },
//   {
//     name: "Page H",
//     data: 40,
//   },
//   {
//     name: "Page I",
//     data: 30,
//   },
//   {
//     name: "Page J",
//     data: 20,
//   },
//   {
//     name: "Page K",
//     data: 27,
//   },
//   {
//     name: "Page L",
//     data: 18,
//   },
//   {
//     name: "Page M",
//     data: 23,
//   },
//   {
//     name: "Page N",
//     data: 34,
//   },
//   {
//     name: "Page O",
//     data: 40,
//   },
//   {
//     name: "Page P",
//     data: 30,
//   },
//   {
//     name: "Page Q",
//     data: 20,
//   },
//   {
//     name: "Page R",
//     data: 27,
//   },
//   {
//     name: "Page S",
//     data: 18,
//   },
//   {
//     name: "Page T",
//     data: 23,
//   },
//   {
//     name: "Page U",
//     data: 34,
//   },
//   {
//     name: "Page V",
//     data: 40,
//   },
//   {
//     name: "Page W",
//     data: 30,
//   },
//   {
//     name: "Page X",
//     data: 20,
//   },
//   {
//     name: "Page Y",
//     data: 27,
//   },
//   {
//     name: "Page Z",
//     data: 18,
//   },{
//     name: "Page A",
//     data: 40,
//   },
//   {
//     name: "Page B",
//     data: 30,
//   },
//   {
//     name: "Page C",
//     data: 20,
//   },
//   {
//     name: "Page D",
//     data: 27,
//   },
//   {
//     name: "Page E",
//     data: 18,
//   },
//   {
//     name: "Page F",
//     data: 23,
//   },
//   {
//     name: "Page G",
//     data: 34,
//   },
//   {
//     name: "Page H",
//     data: 40,
//   },
//   {
//     name: "Page I",
//     data: 30,
//   },
//   {
//     name: "Page J",
//     data: 20,
//   },
//   {
//     name: "Page K",
//     data: 27,
//   },
//   {
//     name: "Page L",
//     data: 18,
//   },
//   {
//     name: "Page M",
//     data: 23,
//   },
//   {
//     name: "Page N",
//     data: 34,
//   },
//   {
//     name: "Page O",
//     data: 40,
//   },
//   {
//     name: "Page P",
//     data: 30,
//   },
//   {
//     name: "Page Q",
//     data: 20,
//   },
//   {
//     name: "Page R",
//     data: 27,
//   },
//   {
//     name: "Page S",
//     data: 18,
//   },
//   {
//     name: "Page T",
//     data: 23,
//   },
//   {
//     name: "Page U",
//     data: 34,
//   },
//   {
//     name: "Page V",
//     data: 40,
//   },
//   {
//     name: "Page W",
//     data: 30,
//   },
//   {
//     name: "Page X",
//     data: 20,
//   },
//   {
//     name: "Page Y",
//     data: 27,
//   },
//   {
//     name: "Page Z",
//     data: 18,
//   },
  
// ];

export const Bar_Chart = ({data, color}) =>{
    const [Data, setData] = React.useState([])
    React.useEffect(() => {
        setData(data)
        console.log({data});
    }, [data])
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart margin={{ top: 30, left: 20, right: 50, bottom: 100 }} width={50} height={20} data={Data ?? []}>
          <XAxis dataKey="name" fontSize={12}/>
          <YAxis fontSize={12}/>
          <Tooltip cursor={{fill: '#F2F2FF'}}/>
          <Bar Count="name" dataKey="count" fill={color} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
