import { Card, Grid, Typography } from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";

import { useQuery } from "@apollo/client";
import moment from "moment";
//
import DataTable from "../../../../components/DataTables";
import { SearchBar } from "../../../../components/Searchbar";
import { CSV_Downloader } from "../../../../components/CSV_Downloader";
import { ACTIVITY_LIST } from "../../../../api/Activities/ActivitiesAPI";
import { Backdrops } from "../../../../components/Backdrops";


function Activities() {
  // const [inputvalue, setinputvalue] = useState("");
  // const [questionsList, setQuestionsList] = useState([]);
  // const [open, setopen] = useState(false);
  // const [val, setval] = useState([]);
  // const [openadd, setopenadd] = useState(false);
  // const [opendialog, setopendialog] = useState(false);
  // const [type, settype] = useState("");
  // const [item, setitem] = useState("");
  // const [message,setMessage] = useState('')
  // const [snackopen,setsnackopen] = useState(false)
  // const [addIntrest] = useMutation(ADDINTREST);
  // const [deleteIntrest] = useMutation(DELETE_INTREST);
  // const [editIntrest] = useMutation(EDIT_INTREST);
  const { data, loading, error } = useQuery(ACTIVITY_LIST, {
    variables: {
      ActivityFilter: {
        year: 2023,
        // year: yearFilter,
        // month: monthFilter,
        // day: dayFilter,
        // os: 'android',
      }
    }
  });
  console.log({ data });
  console.log({ error });
  var formdata = new FormData();
  const HeaderData = [
    { field: "name", title: "Name" },
    { field: "UserIP", title: "IP" },
    { field: "OperatingSystem", title: "OS" },
    { field: "UserAction", title: "Action" },
    { field: "screen", title: "Screen" },
    { field: "Time", title: "Time" },
  ];
  if (loading) return <Backdrops loading={loading} />;
  if (error)
    return (
      <span>
        Error Fetching Data ! <br /> Please Reload
      </span>
    );
  if (data !== null) {
    return (
      <Grid spacing={1} style={{marginTop: "15px",}}>
          <SearchBar />
            <Grid
              style={{
                height: "calc(80vh - 110px)",
                borderRadius: "15px",
                marginTop: "15px",
                paddingLeft:"10px",
              }}
              spacing={1}
              container
            >
              {/* <UsersLists loading={loading} data1={data } error = {error}/> */}
              <Grid
                item
                xs={8}
                style={{
                  backgroundColor: "white",
                  // width: "66%",
                  // height: "100%",
                  borderRadius: "15px",
                  boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  // marginLeft: "8px",
                  marginRight: 0,
                  // paddingLeft: "15px",
                }}
              >
                <Scrollbars style={{ borderRadius: "15px" }}>
                  <DataTable
                    // xs={5}
                    // title="Activities"
                    loading={loading}
                    headData={HeaderData}
                    data={data?.activityList ?? []}
                    UserIP={(item) => item.ip.replace("::ffff:", "")}
                    UserAction={(item) =>
                      item.action.includes("Error_")
                        ? item.action.replace(item.action, "Error")
                        : item.action
                    }
                    name={(item) =>
                      item?.userId?.firstName == null &&
                      item?.userId?.lastName == null
                        ? "Not Logged In"
                        : item?.userId?.firstName + " " + item?.userId?.lastName
                    }
                    OperatingSystem={(item) =>
                      item?.userId?.os == null ? "---" : item?.userId?.os
                    }
                    Time={(item) =>
                      moment(item?.createdAt).format("DD/MMM/YYYY - HH:mm:ss")
                    }
                  />
                </Scrollbars>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  paddingTop: 0,
                  paddingLeft: 15,
                  height: 500,
                  backgroundColor: "none",
                }}
              >
                <Card
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "48%",
                    borderRadius: "15px",
                    boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    // marginBottom: "",
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "center",
                      display: "inline-block",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#31515c",
                        margin: 20,
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Total Activities : {data?.activityList.length}
                    </Typography>
                    <Typography style={{ color: "#31515c", margin: 20 }}>
                      {/* <CSV_Downloader dataCSV={data?.activityList} title={'Download Activities Data'} fileName={"Activities Data"}/> */}
                      <CSV_Downloader dataCSV={data?.activityList} />
                    </Typography>
                  </Grid>
                </Card>

                <Card
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    height: "48%",
                    borderRadius: "15px",
                    boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    marginTop: 15,
                    // marginBottom: "",
                  }}
                >
                  <Grid
                    style={{
                      justifyContent: "center",
                      display: "inline-block",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#31515c",
                        margin: 20,
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* Total Users : {data?.usersList.length} */}
                    </Typography>
                  </Grid>
                </Card>

                {/* <Card
                            
                            style={{
                                backgroundColor: "white",
                                width: "100%",
                                height: "47.6%",
                                marginTop: "5%",
                                borderRadius: "15px",
                                boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                                justifyContent: "center",
                                alignItems:"center",
                                display: 'flex'
                                
                            }}
                        >
                             
                        </Card> */}
              </Grid>
            </Grid>
            </Grid>
    );
  }
}
export default Activities;

// <Grid style={{ padding: 15 }} >

//     <Typography style={{ fontSize: 18 }}>
//         {item.text}
//     </Typography>
//     <IconButton
//         style={{ marginLeft: 15 }}
//         onClick={() => {
//             setval(item);
//             console.log(item);
//             setopen(true);
//             setitem({
//                 name: item.text,
//                 id: item.id,
//             });
//             setinputvalue("");
//             settype("edit");
//         }}
//     >
//         <Pencail action={() => {}} />
//     </IconButton>
//     <IconButton
//         style={{ marginLeft: 15 }}
//         onClick={() => {
//            setopendialog(true)
//            setval(item)
//         }}
//     >
//         <Garbage action={() => {

//         }} />
//     </IconButton>
// </Grid>

{
  /* <form >
                    <input type ="file"  onChange={(e)=>{
                        formdata.append('file',e.target.files[0] )
                        var reader = new FileReader();
                        setchange(e.target.files[0])
                        reader.onload = function(event) {
                            setchange(event.target.result)
                          };
                          
                        reader.readAsDataURL(e.target.files[0]);
                        console.log(reader )
                        }} />
                </form>
                <img style={{width:150,height:150}}  src={change}/> */
}
