import * as React from "react";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import { EncoyIcon } from "../../../../assets/svg/EncoyIcon";
import Divider from "@mui/material/Divider";
import { useStyles } from "./Styles";
import { Outlet } from "react-router-dom";
import { Languages } from "./Header/Languages";
import { Lists } from "./Lists";

import { HeaderBadge } from "./Header/HeaderBadge";
import { useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { Customdivider } from "../../../../Components1/MainDashboard/Container/components/divider/Customdivider";
import { SearchBar } from "../../../../components/Searchbar";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListItemText from "@mui/material/ListItemText";
import { Typography, Avatar } from "@mui/material";
import English from "../../../../assets/svg/Languages/English.svg";
import NotifBell from "../../../../assets/svg/NotifBell.svg";
import { padding } from "@mui/system";
import MontserratBold from "../../../../assets/Fonts/Montserrat-Bold.ttf"

var Main = () => {
  const classes = useStyles();
  const nav = useNavigate();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Grid
      item
      xs={12}
      thumbSize={50}
      autoHideTimeout={10}
      autoHide
      hideTracksWhenNotNeeded={true}
      container
      spacing={2.5}
      style={{
        padding: "42px 2.4vw",
        paddingRight: "50px",
        height: "100vh",
        overflow: "hidden",
        // marginTop: "15px",
        backgroundColor: "#EAEEFA",
      }}
    >
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"
      ></meta>
      <Grid item xs={12} style={{ marginLeft: 8 }}>
        <Grid
          style={{
            height: "calc(100vh - 90px)",
            borderRadius: "15px",
            // marginTop: "15px",
          }}
          spacing={1}
          container
        >
          <Grid item xs={2.6} style={{ height: "calc(100vh - 84px)" }}>
            <Scrollbars
              thumbSize={50}
              autoHideTimeout={10}
              autoHide
              hideTracksWhenNotNeeded={true}
              className={classes.Nav}
              style={{
                backgroundColor: "white",
                borderRadius: "15px",
                height: "calc(100vh - 95px)",
                boxShadow: "4px 4px 20px #d7e3eb",
                position: "fixed",
                width: "20%",
                marginTop: "15px",
              }}
            >
              <Grid
                style={{
                  height: "10%",
                  width: "40%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "30%",
                  marginTop: "15%",
                  marginBottom: "10%",
                }}
              >
                <EncoyIcon />
              </Grid>
              <Divider
                style={{
                  // marginTop: 15,
                  // marginTop: "15%",

                  marginBottom: "10%",
                  margin: "15px auto",
                  width: "75%",
                  borderWidth: "2px",
                  borderColor: "#F2F2FF",
                }}
              />

              <Lists style={{ marginBottom: 500 }} />
              <Divider
                style={{
                  // marginTop: 15,
                  marginBottom: 20,
                  marginTop: "15%",

                  margin: "15px auto",
                  width: "75%",
                  borderWidth: "2px",
                  borderColor: "#F2F2FF",
                }}
              />
              {/* Exit */}
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
              >
                <ListItemButton
                  sx={{
                    paddingBlock: "6px !important",
                    "&:hover": {
                      backgroundColor: "#F2F2FF",
                    },
                  }}
                  style={{
                    marginInline: "1.3333vw",
                    borderRadius: "25px",
                    width: "95%",
                    justifyContent: "center",
                    margin: "15px 30px",
                  }}
                >
                  <ListItemIcon style={{ minWidth: "53px" }}>
                    <ExitToAppIcon style={{ color: "93A2C0" }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "93A2C0" }}
                    className={classes.Text}
                    primary={
                      <Typography
                        className={classes.Text}
                        style={{
                          color: "#93A2C0",
                          fontSize: "13px",
                          fontFamily:
                            "Montserrat-Regular !important-Regular !important",
                        }}
                      >
                        Log Out
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>
              {/* Exit */}
            </Scrollbars>
          </Grid>

          <Grid item xs={9.4}>
            <Card
              fullWidth
              style={{
                backgroundColor: "white",
                borderRadius: "15px",
                boxShadow: "4px 4px 20px #d7e3eb",
                padding: "10px",
                paddingTop: "15pxs",
                marginTop: "15px",
              }}
            >
      <Grid
        container
        spacing={1.2}
        style={{
          // justifyContent: "center",

          // paddingTop: "1vh",
          // paddingBottom: "1vh",
        }}
      >
                <Grid
                  item
                  xs={6}
                          
                  style={{ justifyContent: "flex-start", display: "flex",}}
                >
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    style={{ width: 300, marginLeft:15}}
                  >
                    <List component="nav" disablePadding>
                      <ListItemButton
                        button
                        component={""}
                        // classes={{ selected: classes.active }}
                        sx={{
                          // paddingBlock: "6px !important",
                          "&:hover": {
                            backgroundColor: "#F2F2FF",
                          },
                        }}
                        style={{
                          // color: "#93A2C0",
                          borderRadius: "50px",

                          justifyContent: "center",
                          // margin: "5px auto",
                          marginTop: "3px",

                          // backgroundColor: "#F2F2FF",
                        }}
                      >
                        <ListItemIcon>
                          <Avatar
                            //  style={{border: "solid 0.5px #000"}}
                            sx={{
                              width: "40px",
                              height: "40px",
                              margin: "1.5%",
                            }}
                            alt={"English"}
                            src={English}
                          />
                        </ListItemIcon>
                        <ListItemText primary={""} />
                        {open ? <ExpandMore /> : <ExpandLess />}
                      </ListItemButton>
                    </List>
                  </Collapse>
                </Grid>
                <Grid
                  item
                  xs={5.5}
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    style={{ width: 300 }}
                  >
                    <List component="nav" disablePadding>
                      <ListItemButton
                        button
                        component={""}
                        // classes={{ selected: classes.active }}
                        sx={{
                          // paddingBlock: "6px !important",
                          "&:hover": {
                            backgroundColor: "#F2F2FF",
                          },
                        }}
                        style={{
                          // color: "#93A2C0",
                          borderRadius: "50px",
                          width: "80%",
                          justifyContent: "center",
                          // margin: "5px auto",
                          marginTop: "3px",
                          // backgroundColor: "#F2F2FF",
                        }}
                      >
                        <ListItemText
                          primary={"Encoy Admin"}
                          style={{ textAlign: "right", marginRight: 15, width: 100, }}
                        />
                        <ListItemIcon>
                          <Avatar
                            style={{ border: "solid 1px #C0C0FF" }}
                            sx={{
                              width: "40px",
                              height: "40px",
                              margin: "1.5%",
                            }}
                            alt={"Arshia"}
                            src={""}
                          />
                        </ListItemIcon>
                        {open ? <ExpandMore /> : <ExpandLess />}
                      </ListItemButton>
                    </List>
                  </Collapse>
                </Grid>
                <Grid
                  item
                  xs={0.5}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {" "}
                  <ListItemButton
                    button
                    component={""}
                    // classes={{ selected: classes.active }}
                    sx={{
                      // paddingBlock: "6px !important",
                      "&:hover": {
                        backgroundColor: "#F2F2FF",
                      },
                    }}
                    style={{
                      // color: "#93A2C0",
                      marginRight: "50px",
                      borderRadius: "50px",
                      width: "30%",
                      justifyContent: "center",
                      alignItems: "center",

                      // margin: "5px auto",
                      // marginTop: "3px",
                      // backgroundColor: "#F2F2FF",
                    }}
                  >
                    <Avatar
                      // style={{border: "solid 1px #C0C0FF"}}
                      sx={{
                        width: "20px",
                        height: "25px",
                        // margin: "1.5%",
                        // padding: "0",
                        
                      }}
                      alt={"Bell"}
                      src={NotifBell}
                    />
                  </ListItemButton>
                </Grid>
              </Grid>
            </Card>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Main;
