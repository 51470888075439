export const CheckedIcon=()=>{
    return <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.35"
    height="19.35"
    viewBox="0 0 19.35 19.35"
>
    <g id="Group_17972" data-name="Group 17972">
        <path
            id="Path_11521"
            data-name="Path 11521"
            d="M15.318,0H4.031A4.036,4.036,0,0,0,0,4.031V15.319A4.036,4.036,0,0,0,4.031,19.35H15.318a4.036,4.036,0,0,0,4.031-4.031V4.031A4.036,4.036,0,0,0,15.318,0Zm2.419,15.319a2.419,2.419,0,0,1-2.419,2.419H4.031a2.419,2.419,0,0,1-2.419-2.419V4.031A2.419,2.419,0,0,1,4.031,1.612H15.318a2.419,2.419,0,0,1,2.419,2.419Z"
            fill="#3b3bff"
        />
        <path
            id="Path_11522"
            data-name="Path 11522"
            d="M8.243,14.07l-3.16-3.16a.806.806,0,0,0-1.14,0h0a.806.806,0,0,0,0,1.14L7.1,15.21a1.612,1.612,0,0,0,2.281,0L16.843,7.75a.806.806,0,0,0,0-1.14h0a.806.806,0,0,0-1.14,0Z"
            transform="translate(-0.719 -1.234)"
            fill="#3b3bff"
        />
    </g>
</svg>
}