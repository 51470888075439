import React from "react";
import {
    Collapse,
    Dialog,
    Grid,
    TextField,
    Typography,
    Button,
} from "@mui/material";
import { useStyles } from "../../../../StartPage/Styles";
import { Test, Test1 } from "../Category/Icons/SearchIcons";
import { useState } from "react";
import { Customdivider } from "../components/divider/Customdivider";
import { makeStyles } from "@mui/styles";

const useStyles1 = makeStyles(() => ({
    paper: {
        minWidth: "700px",
        maxHeight: "300px",
        borderRadius: "20px !important",
    },
}));


export const AddLanguage = ({
    open,
    action,
    actiontext,
    submitForm,
    close,
}) => {
    const classs = useStyles1();
    return (
        <Dialog
            classes={{ paper: classs.paper }}
            open={open}
            style={{ width: "800px !important" }}
        >
            <Grid style={{ width: "100%", height: "300px", borderRadius: 50 }}>
                <Grid xs={9.75} style={{ margin: "auto" }}>
                    <Typography
                        style={{
                            marginLeft: 3,
                            paddingTop: 40,
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            fontSize: 18,
                            color: "#31515C",
                        }}
                    >
                        Add Sport
                    </Typography>

                    <Customdivider size={12} />
                    <Grid style={{ margin: "auto" }}>
                        <Grid
                            style={{
                                border: "1px solid #D0DBE5",
                                marginTop: 45,
                                paddingBlock: 10,
                                borderRadius: "15px",
                            }}
                            container
                        >
                            <Grid
                                xs={6}
                                style={{
                                    borderRight: "1px solid #D0DBE5",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBlock: "5px",
                                }}
                            >
                                <Typography
                                    style={{
                                        marginLeft: 3,
                                        margin: "auto",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                        fontSize: 12,
                                        color: "#31515C",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        color: "#3b3bff",
                                    }}
                                >
                                    Download Translates
                                </Typography>
                            </Grid>
                            <Grid
                                xs={6}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBlock: "5px",
                                }}
                            >
                                <label
                                    style={{
                                        marginLeft: 3,
                                        margin: "auto",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                        color: "#3b3bff",
                                        fontSize: 12,
                                        color: "#31515C",
                                        pointer: "cursor",
                                    }}
                                >
                                    <input
                                        hidden
                                        onChange={(e) => {
                                            console.log(e.target.id.value);
                                        }}
                                        type="file"
                                    ></input>
                                    <Typography
                                        style={{
                                            marginLeft: 3,
                                            margin: "auto",
                                            fontFamily: "Montserrat-Regular !important-Regular !important",
                                            color: "#3b3bff",
                                            fontSize: 12,
                                            color: "#31515C",
                                            pointer: "cursor",
                                            textDecoration: "underline",
                                            color: "#3b3bff",
                                        }}
                                    >
                                        Import Translate File
                                    </Typography>
                                </label>
                            </Grid>
                        </Grid>
                        <Grid
                            xs={12}
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "100%",
                                justifyContent: "flex-end",
                                marginTop: "51px",
                            }}
                        >
                            {" "}
                            <div style={{ display: "flex" }}>
                                <Button
                                    onClick={close}
                                    style={{
                                        backgroundColor: "#FFF",
                                        height: 40,
                                        width: 110,
                                        marginRight: 30,
                                        marginTop: "3px",
                                        border: "1px solid #3939F8",
                                        textTransform: "none",
                                        borderRadius: 8,
                                        color: "#3939F8",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    onClick={() => {
                                        submitForm();
                                    }}
                                    style={{
                                        backgroundColor: "#3b3bff",
                                        height: 40,
                                        width: 110,
                                        textTransform: "none",
                                        color: "white",
                                        borderRadius: 8,
                                        marginTop: "3px",
                                        border: "1px solid #e9eef3",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};
const useStyles2 = makeStyles(() => ({
    paper: {
        minWidth: "700px",
        maxHeight: "400px",
        borderRadius: "20px !important",
    },
    paper1: {
        minWidth: "950px",
        maxHeight: "300px",
        borderRadius: "20px !important",
    },
}));
export const EditLanguage = ({
    open,
    close,
    submitForm,
    value,
    err,
    loading,
}) => {
    const classes = useStyles();
    const paper1 = useStyles2();
    const [formData, setFormData] = useState({});
    console.log(loading);
    React.useEffect(() => {
        setFormData({ ...value });
    }, [value]);
    const changeInput = (e) => {
        setFormData({ ...formData, text1: e.target.value });
        console.log(formData);
    };
    const changeInput1 = (e) => {
        setFormData({ ...formData, text2: e.target.value });
        console.log(formData);
    };
    return (
        <Dialog
            classes={{ paper: paper1.paper1 }}
            open={open}
            style={{ width: "800px !important" }}
        >
            <form onSubmit={(e)=>{e.preventDefault(false)}}>
            <Grid
                style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: 50,
                    paddingBottom: "30px",
                }}
            >
                <Grid xs={9.75} style={{ margin: "auto" }}>
                    <Typography
                        style={{
                            marginLeft: 3,
                            paddingTop: 40,
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            fontSize: 18,
                            color: "#31515C",
                        }}
                    >
                        Add New Translate
                    </Typography>

                    <Customdivider size={12} />
                    
                    <Grid style={{ margin: "auto" }}>
                        <Grid container style={{ height: "120px" }}>
                            <Grid xs={12} style={{ paddingTop: "30px" }}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: "15px" }}
                                >
                                    <TextField
                                        id="title"
                                        fullWidth
                                        value={formData.text1 ?? ""}
                                        onChange={changeInput}
                                        InputProps={{
                                            startAdornment: (
                                                <Test text="Text" />
                                            ),
                                        }}
                                        inputProps={{
                                            style: {
                                                paddingTop: 12,
                                                paddingBottom: 12,
                                                paddingLeft: 5,
                                            },
                                        }}
                                        className={classes.AddSport}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: "25px" }}
                                >
                                    <TextField
                                        id="title"
                                        fullWidth
                                        value={formData.text2 ?? ""}
                                        onChange={changeInput1}
                                        InputProps={{
                                            startAdornment: (
                                                <Test text="Translate" />
                                            ),
                                        }}
                                        inputProps={{
                                            style: {
                                                paddingTop: 12,
                                                paddingBottom: 12,
                                                paddingLeft: 5,
                                            },
                                        }}
                                        className={classes.AddSport}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                xs={10.8}
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                    marginBottom: "35px",
                }}
            >
                {" "}
                <Grid>
                    <Button
                        onClick={close}
                        style={{
                            backgroundColor: "#FFF",
                            height: 40,
                            width: 110,
                            marginRight: 30,
                            marginTop: "3px",
                            border: "1px solid #3939F8",
                            textTransform: "none",
                            borderRadius: 8,
                            color: "#000",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                    type ="submit"
                        onClick={() => {
                            //   console.log(formData)
                            
                            submitForm(formData);
                            console.log(formData);
                        }}
                        style={{
                            backgroundColor: "#3b3bff",
                            height: 40,
                            width: 110,
                            textTransform: "none",
                            color: "white",
                            borderRadius: 8,
                            marginTop: "3px",
                            border: "1px solid #e9eef3",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                        }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
            </form>
        </Dialog>
    );
};
