import { gql } from "@apollo/client";

export const ADMIN_LOGIN = gql`
        mutation ($username: String!, $password: String!) {
            adminLogin(data: { username: $username, password: $password }) {
                message
                access_token
            }
        }
    `;
    export const ADD_ADMIN = gql`
        mutation ($username: String!, $password: String!) {

            adminSignUp(data:{username:$username,password:$password}){
                message
                access_token
            }

            
        }
    `;