import * as React from "react";
import AuthRoute from "./AuthRoute";


import {
    Route,
    Routes,
    useParams,
    BrowserRouter,
    Navigate,
} from "react-router-dom";
import { Editadmin } from "../Pages/MainDashboard/Container/Management/EditAdmin";
// import { Addnewadmin } from "../Pages/MainDashboard/Container/Management/AddNewAdmin";
import { Translates } from "../Pages/MainDashboard/Container/Languages/language";
import { EventsDetails } from "../Pages/MainDashboard/Container/Events/EventDetails";
import { MessagePart } from "../Pages/MainDashboard/Container/Notiffication/Sendmessage";
import { Sendmessage } from "../Pages/MainDashboard/Container/Notiffication/notifpart/Sendmessage";
import Category1 from "../Pages/MainDashboard/Container/Category";
import Users from "../Pages/MainDashboard/Container/Users";
import { Sidebar } from "../Pages/MainDashboard/SideBarMenu";
import Main from "../Pages/MainDashboard/Container/Menu/Main"
// import { Translates } from "../Components/MainDashboard/Container/Languages/Translates";
function Pagg() {
    const { id } = useParams();
    return (
        <div>
            <span>{id}</span>
        </div>
    );
}
const MainRoute = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="auth" element={<AuthRoute />} />
                <Route path="dashboard" element={<Main />}>
                    <Route path="users/:id" element={''}>
                        <Route
                            path="joined-events"
                            element={'<Joinedevents />'}
                        />
                        <Route
                            path="created-events"
                            element={''}
                        />
                        <Route
                            path="created-events/:id"
                            element={''}
                        />
                        <Route path="joined-events/:id" element={''} />

                    </Route>
                    <Route path="language/:id" element={<Translates />} />
                    <Route path="catgory/:id" element={<Pagg />} />
                    <Route path="events/:id" element={<EventsDetails />} />
                    <Route
                        path="notify"
                        element={<MessagePart />}
                    >
                        <Route
                            path="mobile-notification"
                            element={<Sendmessage title={"Short Text"} />}
                        />
                        <Route
                            path="email"
                            element={<Sendmessage title={"Subject"} />}
                        />
                        <Route
                            path="sms"
                            element={<Sendmessage title={"Title"} />}
                        />
                    </Route>
                    <Route path="management/:id" element={<Editadmin />} />
                    <Route
                        path="management/add-admin"
                        element={''}
                    />

                    <Route path={"category/:id"} element={<Category1 />} />
                    <Route path={"users/:id"} element={<Users />} />

                    {Sidebar.map((item) => {
                        return (
                            <Route
                                path={`${item.path}`}
                                element={item.component}
                            >
                                <Route path="*/:id" element={<Pagg />} />
                            </Route>
                        );
                    })}
                </Route>

                <Route
                    path="/"
                    element={
                        localStorage.getItem("@token") === undefined ||localStorage.getItem("@token") === null ||
                        localStorage.getItem("@token") === "" ? (
                            <AuthRoute />
                        ) : (
                            <Navigate replace to="dashboard/main" />
                        )
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default MainRoute;
// element={<Navigate replace to="auth/login" />}
