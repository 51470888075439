import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { makeStyles } from "@mui/styles";
import { Customdivider } from "../divider/Customdivider";



export function AlertDialog(props) {
    return (
        <Dialog
            open={props.open}
            sx={{ "& .MuiPaper-root": { borderRadius: "18px" } }}
            style={{ borderRadius: "200px" }}
        >
            <Box
                style={{
                    width: "481px",
                    height: "282px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div style={{ width: "100%", margin: "auto" }}>
                    <div
                        style={{
                            display: "flex",
                            margin: "auto",
                            justifyContent: "center",
                        }}
                    >
                        <WarningAmberIcon
                            color="error"
                            style={{ width: "50px", height: "120px" }}
                        />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            style={{
                                color: "#161616",
                                marginBottom: 20,
                                fontFamily: "Montserrat-Regular !important",
                                fontSize: "15px",
                                fontWeight: "unset",
                            }}
                        >
                            {props.message}
                        </Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            id="cancell"
                            onClick={props.close}
                            style={{
                                background: "#FFF",
                                border: "1.5px solid #31515C",
                                color: "#31515C",
                                fontFamily: "Montserrat-Regular !important",
                                width: "127px",
                                height: "42px",
                                borderRadius: "10px",
                                justifyContent: "center",
                                textAlign: "center",
                                textJustify: "center",
                                textTransform: "none",
                                fontSize: "13px",
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={props.action}
                            style={{
                                marginLeft: 25,
                                background: "#FF9900",
                                fontFamily: "Montserrat-Regular !important",
                                width: "128px",
                                color: "white",
                                height: "42px",
                                borderRadius: "10px",
                                justifyContent: "center",
                                textAlign: "center",
                                textJustify: "center",
                                fontSize: "13px",
                                textTransform: "none",
                            }}
                        >
                            {props.actiontext}
                        </Button>
                    </div>
                </div>
            </Box>
        </Dialog>
    );
}
const useStyles1 = makeStyles(() => ({
    paper: {
        minWidth: "700px",
        maxHeight: "300px",
        borderRadius: "20px !important",
    },
}));
export const AddLanguage = ({
    open,
    action,
    actiontext,
    submitForm,
    close,
}) => {
    const classs = useStyles1();
    return (
        <Dialog
            classes={{ paper: classs.paper }}
            open={open}
            style={{ width: "800px !important" }}
        >
            <Grid style={{ width: "100%", height: "300px", borderRadius: 50 }}>
                <Grid xs={9.75} style={{ margin: "auto" }}>
                    <Typography
                        style={{
                            marginLeft: 3,
                            paddingTop: 40,
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            fontSize: 18,
                            color: "#31515C",
                        }}
                    >
                        Add Sport
                    </Typography>

                    <Customdivider size={12} />
                    <Grid style={{ margin: "auto" }}>
                        <Grid
                            style={{
                                border: "1px solid #D0DBE5",
                                marginTop: 45,
                                paddingBlock: 10,
                                borderRadius: "15px",
                            }}
                            container
                        >
                            <Grid
                                xs={6}
                                style={{
                                    borderRight: "1px solid #D0DBE5",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBlock: "5px",
                                }}
                            >
                                <Typography
                                    style={{
                                        marginLeft: 3,
                                        margin: "auto",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                        fontSize: 12,
                                        color: "#31515C",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        color: "#3b3bff",
                                    }}
                                >
                                    Download Translates
                                </Typography>            
                            </Grid>
                            <Grid
                                xs={6}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBlock: "5px",
                                }}
                            >
                                {" "}
                                <Typography
                                    style={{
                                        marginLeft: 3,
                                        margin: "auto",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                        color: "#3b3bff",
                                        fontSize: 12,
                                        color: "#31515C",
                                        pointer: "cursor",
                                        textDecoration: "underline",
                                        color: "#3b3bff",
                                    }}
                                >
                                    Import Translate File
                                </Typography>{" "}
                            </Grid>
                        </Grid>
                        <Grid
                            xs={12}
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                                marginTop: "20px",
                            }}
                        >
                            {" "}
                            <div style={{ display: "flex" }}>
                                <Button
                                    onClick={close}
                                    style={{
                                        backgroundColor: "#000",
                                        height: 40,
                                        width: 110,
                                        marginRight: 30,
                                        marginTop: "13px",
                                        border: "1px solid #31515C",
                                        textTransform: "none",
                                        borderRadius: "100px",
                                        color: "#31515C",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                    }}
                                >
                                    <Typography>Cancel</Typography>
                                </Button>

                                <Button
                                    onClick={() => {
                                        submitForm();
                                    }}
                                    style={{
                                        backgroundColor: "#3b3bff",
                                        height: 40,
                                        width: 110,
                                        textTransform: "none",
                                        color: "white",
                                        borderRadius: 8,
                                        marginTop: "3px",
                                        border: "1px solid #e9eef3",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                    }}
                                >
                                    Submit
                                </Button>

                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};
