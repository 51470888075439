import * as React from "react";
import {
    Avatar,
    Card,
    Divider,
    Grid,
    Typography,
    Tooltip,
} from "@mui/material";
import { AlertDialog } from "../../../../../Components1/MainDashboard/Container/components/Dialog/AlertDialogs";
import Scrollbars from "react-custom-scrollbars-2";

import { Styles } from "../../../../../Components1/MainDashboard/Container/Events/Styles";
import { Volounteers } from "../../../../../Components1/MainDashboard/Container/Events/EventDetails/Volounteers";
import { Eventcomments } from "../../../../../Components1/MainDashboard/Container/Events/EventDetails/Comments";

export var EventsDetails = () => {
    const user = [
        { title: "Mobile", vlaue: "09126283654" },
        { title: "Skill", vlaue: "Proffesional" },
        { title: "Created Events", vlaue: "13" },
    ];
    const rows = [
        "Event Name:",
        "Category:",

        "Type:",
        "Skill:",
        "Number of players:",
        "Gender:",
        "Date and time:",
        "Country:",
        "City:",
    ];
    const rowedtails = [
        "Lets play tenis with me",
        "Partner Sports",

        "Raket",
        "Begginer",
        5,
        "Female",
        "1/19/2022 11: 00 am",
        "Turkey",
        "Antalia",
    ];
    const volounteers = [
        { name: "fariba", email: "fariba@gmail.com", status: "rejected" },
        { name: "elham", email: "fariba@gmail.com", status: "accepted" },
    ];
    const [open, setopen] = React.useState();
    const classes = Styles();
    return (
        <Grid
            xs={12}
            style={{
                height: "86%",
                marginTop: 15,
                backgroundColor: "white",
                borderRadius: "1vw",
            }}
        >
            <Scrollbars style={{ height: "100%" }}>
                <Grid xs={10.55} style={{ margin: "auto" }}></Grid>
                <Grid xs={10.55} container style={{ margin: "auto" }}>
                    <Grid xs={4.55}>
                        <Typography
                            style={{
                                fontFamily: "Montserrat-Regular !important-Regular !important",
                                fontSize: "14px",
                                marginBottom: 7,
                                paddingTop: "60px",
                            }}
                        >
                            Lets Play Tenis
                        </Typography>
                        <Grid xs={12} style={{ paddingBottom: 38 }}>
                            <Divider
                                style={{
                                    border: "1px solid rgb(235, 242, 247)",
                                    backgroundColor: "rgb(235, 242, 247)",
                                }}
                            ></Divider>
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            style={{ paddingBottom: "60px" }}
                        >
                            <Grid xs={3.7}>
                                {rows.map((item) => {
                                    return (
                                        <Typography
                                            className={classes.firstcoloumn}
                                        >
                                            {item}
                                        </Typography>
                                    );
                                })}
                            </Grid>
                            <Grid xs={8} style={{ marginLeft: "8px" }}>
                                {rowedtails.map((item) => {
                                    return (
                                        <Typography
                                            className={classes.secondcoloumn}
                                        >
                                            {item}
                                        </Typography>
                                    );
                                })}
                            </Grid>
                            <Grid xs={12} style={{ paddingTop: 38 }}>
                                <Divider
                                    style={{
                                        border: "1px solid rgb(235, 242, 247)",
                                        backgroundColor: "rgb(235, 242, 247)",
                                    }}
                                ></Divider>
                            </Grid>
                            <Grid xs={12} style={{ paddingTop: 38 }}>
                                <Typography
                                    style={{
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                        fontSize: "15px",
                                        marginBottom: 7,
                                    }}
                                >
                                    Creator:
                                </Typography>
                            </Grid>
                            <Volounteers
                                item={{
                                    name: "erfan",
                                    email: "erfan@gmail.com",
                                }}
                            />
                            <Grid xs={12} style={{ paddingTop: 18 }}>
                                <Typography
                                    style={{
                                        fontFamily: "Montserrat-Regular !important-Regular !important",
                                        fontSize: "15px",
                                        marginBottom: 7,
                                    }}
                                >
                                    Volunteers:
                                </Typography>
                            </Grid>
                            {volounteers.map((item) => (
                                <Volounteers item={item} />
                            ))}
                        </Grid>
                    </Grid>
                    <Grid
                        xs={7.45}
                        style={{ paddingLeft: "50px", marginTop: "60px" }}
                    >
                        <Grid>
                            <Typography
                                style={{
                                    fontFamily: "Montserrat-Regular !important-Regular !important",
                                    fontSize: "15px",
                                    marginBottom: 20,
                                }}
                            >
                                Comments
                            </Typography>
                            {user.map((item) => {
                                return (
                                    <Eventcomments
                                        action={() => {
                                            setopen(true);
                                        }}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <AlertDialog
                    open={open}
                    close={() => {
                        setopen(false);
                    }}
                    actiontext="
            Disable"
                />
            </Scrollbars>
        </Grid>
    );
};
