import React from "react";
import { makeStyles } from "@mui/styles";
export const Styles = makeStyles({
    Header: {
        marginLeft: 3,
        paddingTop: 40,
        fontFamily: "Montserrat-Regular !important-Regular !important !important",
        fontSize: 18,
        color: "#31515C !important",
    },
    paper: {
        minWidth: "800px",
        maxHeight: "200px",
        borderRadius: "20px !important",
    },
    cancel: {
        backgroundColor: "#FFF !important",
        height: 40,
        width: 110,
        marginRight: "30px !important",
        marginTop: "3px !important",
        border: "1px solid #3939F8 !important",
        textTransform: "none !important",
        borderRadius: "8px !important",
        color: "#3939F8 !important",
        fontFamily: "Montserrat-Regular !important-Regular !important !important",
    },
    image1: {
        backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K")`,
        borderRadius: 60,
        height: 90,
        width: 90,
    },
    inner: {
        minWidth: "90%",
        height: 350,
        backgroundColor: "white",
        paddingLeft: 40,
    },submit:{
        backgroundColor: "#3939F8 !important",
                            height: 40,
                            width: 110,
                            textTransform: "none !important",
                            color: "white !important",
                            borderRadius: "8px !important",
                            marginTop: "3px !important" ,
                            border: "1px solid #3939F8 !important",
                            fontFamily: "Montserrat-Regular !important-Regular !important !important",
    },
    AddSport:{
        
        input:{backgroundColor: "red",},
        
        backgroundColor: "#fff",
        borderWidth: 2,
        paddingTop:"0px",
        fontFamily: "Montserrat-Regular",
        letterSpacing: 10,
        fontStyle: "normal",
        border: "100px solid white",
        '& label.Mui-focused': {
            color: '#0084AD',
        }, '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow',  borderRadius: "15px"
        },
        
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#DBDBDB',  borderRadius: "15px"
            },
            '&:hover fieldset': {
                borderColor: '#0084AD'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0084AD', borderRadius: "15px", borderWidth: 0.5
            },'&MuiInputBase-input':{
                backgroundColor:"red"
            },
            '&.MuiInputBase-input':{
                backgroundColor:"red"
            },
            '&.MuiOutlinedInput-root': {

                
                
                fontSize:'16px',
                color:"#31515C",
                padding:0,
                letterSpacing:"0px",
                fontFamily:"Montserrat",paddingLeft:".5vw"
                
                   ,'&.MuiOutlinedInput-input input':{
                    backgroundColor: "red" 
                }             
                
                /* THIS THE SIZE OF THE TEXTFEILD  */
            },
            
        },
    },
});

export const Secondstyle = {
    cancel: {
        backgroundColor: "#FFF !important",
        height: 40,
        width: 110,
        marginRight: 30,
        marginTop: "3px !important",
        border: "1px solid #3939F8 !important",
        textTransform: "none !important",
        borderRadius: 8,
        color: "#3939F8 !important",
        fontFamily: "Montserrat-Regular !important-Regular !important !important",
    },
};
