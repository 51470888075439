 import { Styles } from "./styles";
 import {
    Checkbox,
    Grid,
    Tooltip,
    Typography,
} from "@mui/material";
import { CheckedIcon } from "./icons/Checkedicon";
import { Check } from "./icons/Check";
import { Customavatar } from "../components/avatar/customavatar";
import { Disableicon } from "../components/icons.js/actionicons";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../StartPage/Styles";
export const Singlecomment = ({item,onchangecheckbox,action})=>{
    const nav = useNavigate()
    const classes = useStyles();
    const stylee = Styles()
    return <Grid
    md={11.96}
    container
    style={{
        margin: "auto",
        paddingTop: "15px",
    }}
>
    <Grid
        md={0.45}
        style={{
            maxheight: "39px",
            paddingTop: "10px",
        }}
    >
        <Checkbox
            checked={item.select}
            checkedIcon={<CheckedIcon />}
            icon={
                <div
                    className={stylee.checkbox}
                >
                    <Check />
                </div>
            }
            sx={{
                color: "#D0DBE5",
                "&.Mui-checked": {
                    color: "#3b3bff",
                    borderRadius: 20,
                },
            }}
            classes={{ root: classes.rootw }}
            onChange={() => {
                onchangecheckbox()
            }}
            sx={{ padding: 0 }}
        />
    </Grid>
    <Grid
        md={0.9}
        style={{
            display: "flex",
        }}
    >
        <Customavatar
            customsize={{
                width: 39,
                maxheight: 39,
            }}
            link="erfan"
        />
    </Grid>
    <Grid md={4}>
        <Tooltip
            placement="bottom"
            title={
                item.disable
                    ? `THIS COMMET IS DISABLED : ${"%&*&*&!E@#$%^*!()_!#$%^&*()_#$%^&#$%^&*#@$%^&#$%^&"} `
                    : "  In publishing and graphic design, Lorem ipsum is a placeholder text commonly use"
            }
        >
            <Grid md={9.2}>
                <Typography
                    className={stylee.name}
                >
                    {item.name}
                </Typography>
                <Typography
                    className={
                        stylee.paragrapgh
                    }
                    style={{
                        textDecoration:
                            item.disable
                                ? "line-through"
                                : "none",
                        color: item.disable
                            ? "#a8b6ba "
                            : "#31515C",
                    }}
                >
                    In publishing and graphic
                    design, Lorem ipsum is a
                    placeholder text commonly
                    used to demonstrate the
                    visual form.
                </Typography>
            </Grid>
        </Tooltip>
    </Grid>
    <Grid
        md={1.5}
        className={stylee.cellcontainer}
        style={{ cursor: "pointer" }}
        onClick={() => {
            nav(`/Dashboard/events/Tenisevent`);
        }}
    >
        <div className={stylee.phoneanddate}>
            Tenis Event
        </div>
    </Grid>
    <Grid
        md={1.5}
        className={stylee.cellcontainer}
    >
        <div className={stylee.phoneanddate}>
            1400/11/21
        </div>
    </Grid>
    <Grid
        md={1.5}
        className={stylee.cellcontainer}
    >
        <div className={stylee.phoneanddate}>
            1400/11/21
        </div>
    </Grid>
    <Grid
        md={1}
        className={stylee.cellcontainer}
    >
        <div className={stylee.phoneanddate}>
            09126283654
        </div>
    </Grid>
    <Grid
        xs={0.82}
        className={stylee.cellcontainer}
    ></Grid>
    <Grid
        xs={0.1}
        className={stylee.cellcontainer}
    >
        <Tooltip
            placement="top"
            arrow
            title={
                item.disable
                    ? "Enable"
                    : "Disable!"
            }
        >
            <div>
                <Disableicon
                    action={() => {
                       action()
                    }}
                />
            </div>
        </Tooltip>
    </Grid>
</Grid>
}