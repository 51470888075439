import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useStyles } from "./Styles";
import { Sidebar } from "./../../SideBarMenu";
import { useEffect, useState } from "react";
import { Comments, Comments1 } from "./CommentsBadge";
import Scrollbars from "react-custom-scrollbars-2";
// NEW IMPORT
import * as React from "react";
import {useHistory} from "react-router-dom"
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import MainDashboard from "../../../../Components1/MainDashboard/Container/MainDashboard/MainDashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { set } from "date-fns";

export const Lists = () => {
  const [data, setdata] = useState(Sidebar);
  var loc = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
//   console.log(">>>>> ", data)
  return (
    <List component="nav">
      {data.map((Item) => {
        //  console.log(loc.pathname.slice(10,22).includes( Item.path))
        if (loc.pathname.includes(Item.path)) {
          Item.show = true;
        } else {
          Item.show = false;
        }
        return Item.extendable === undefined ? (
          <ListItemButton
            sx={{
              paddingBlock: "6px !important",
              "&:hover": {
                backgroundColor: "#F2F2FF",
              },
            }}
            style={{
              color: Item.show ? "#3b3bff" : "#93A2C0",
              // marginInline: "1.3333vw",
              borderRadius: "25px",
              width: "85%",
              justifyContent: "center",
              margin: "5px 30px",
              backgroundColor: Item.show ? "#DFDFFF" : "",
            }}
            onClick={(event) => {
              let data1 = data.map((e) => {
                if (e.id === Item.id) {
                  return { ...e, show: true };
                } else {
                  return { ...e, show: false };
                }
              });

              setdata(data1);
              // nav(`${Item.path}`);
              navigate(Item.path)
            }}
          >
            <ListItemIcon style={{ minWidth: "53px" }}>
              <Item.icon color={Item.show ? "#3b3bff" : "#93A2C0"} />
            </ListItemIcon>
            <ListItemText className={classes.Text} primary={Item.name} />
          </ListItemButton>
        ) : (
          <>
            <ListItemButton
              button
              classes={{ selected: classes.active }}
              selected={loc.pathname.includes(Item.path)}
              onClick={() => handleClick(data.path)}
              sx={{
                paddingBlock: "6px !important",
                "&:hover": {
                  backgroundColor: "#F2F2FF",
                },
              }}
              style={{
                color: Item.show ? "#110943" : "#93A2C0",
                marginInline: "1.3333vw",
                borderRadius: "25px",
                width: "85%",
                justifyContent: "center",
                margin: "5px 30px",
                backgroundColor: Item.show ? "#FFF" : "",
              }}
            >
              <ListItemIcon>
              <Item.icon color={Item.show ? "#110943" : "#93A2C0"} />

              </ListItemIcon>
              <ListItemText className={classes.Text} primary={Item.name} />

              {open ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
            <Collapse in={Item.show = open} timeout="auto" unmountOnExit>
            {/* in={Item.show ? open : !open } */}
              <List
                component="nav"
                disablePadding
                // className={classes.extendable}
              >
                
                {Item.extendable.map((ExtendItem) => (
                    
                  <ListItemButton
                    button
                    component={Link}
                    classes={{ selected: classes.active }}
                    selected={loc.pathname.includes(ExtendItem.path)}
                    to={`${Item.path}/${ExtendItem.path}`}
                    sx={{
                        paddingBlock: "6px !important",
                        "&:hover": {
                          backgroundColor: "#F2F2FF",
                        },
                      }}
                      style={{
                        color: loc.pathname.includes(ExtendItem.path) ? "#3b3bff" : "#93A2C0",
                        borderRadius: "25px",
                        width: "70%",
                        justifyContent: "center",
                        margin: "5px 50px",
                        // paddingRight:"50px",
                        backgroundColor: loc.pathname.includes(ExtendItem.path) ? "#F2F2FF" : "",
                      }}
                  >
                    <ListItemIcon>
                      <ExtendItem.icon color={loc.pathname.includes(ExtendItem.path) ? "#3b3bff" : "#93A2C0"} />
              {/* <Item.icon color={Item.show ? "#3b3bff" : "#93A2C0"} /> */}

                    </ListItemIcon>
                    <ListItemText className={classes.Text} primary={ExtendItem.name} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </>
        );
      })}
    </List>
  );
};
// NEW

//
