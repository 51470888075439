import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import { TextFields } from "../../Components1/MainDashboard/Container/components/TextFields/TextFeilds";
import { useStyles } from "./Styles.js";
import NidrPic from "./Nidrpic";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { MailIconn, PadLock } from "./icons";
import { ADMIN_LOGIN } from "../../api/Auth/Authapi";

const Login = (props) => {
    const nav = useNavigate();
    const [adminLogin, { data }] = useMutation(ADMIN_LOGIN);
    const [formData, setFormData] = React.useState({});
    const classes = useStyles();
    const changeInput = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };
    const onSubmit = (e) => {
        console.log(formData);
        adminLogin({ variables: formData })
            .then((res) => {
                console.log("login ::: ", res.data.adminLogin.access_token);
                localStorage.setItem(
                    "@token",
                    res.data.adminLogin.access_token
                );
                nav("/dashboard/main");
            })
            .catch((err) => {
                alert(err.message, "error");
                console.log(err.message);
            });
    };

    return (
        <Grid md={12} lg={12} className={classes.Container}>
            <Grid
                md={12}
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 30,
                }}
            >
                <Grid
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <NidrPic />
                </Grid>
                <div style={{ width: "100%" }}>
                    <Typography
                        variant="h6"
                        style={{
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            display: "flex",
                            fontSize: 24,
                            color: "#31515C",
                            marginBottom: 60,
                            textAlign: "center",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            marginTop: 8,
                            fontWeight: "normal",
                        }}
                    >
                        BE ONE OF US!!
                    </Typography>
                </div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(false);
                        onSubmit();
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: 5,
                        }}
                    >
                        <TextFields
                            onch={changeInput}
                            id={"username"}
                            Icon={<MailIconn />}
                            holder="Email"
                        />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: 15,
                        }}
                    >
                        <TextFields
                            onch={changeInput}
                            id="password"
                            Icon={<PadLock />}
                            holder="Password"
                            Feild="Password"
                        />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            alignContent: "center",
                            display: "flex",
                            backgroundColor: "white",
                            marginTop: 35,
                        }}
                    >
                        <Button
                            type="submit"
                            onSubmit={onSubmit}
                            // className={classes.Btn_Login}
                            style={{
                                height: 53,
                                width: 260,
                                borderRadius: 40,
                                backgroundColor: "#3b3bff",
                                color: "white",
                                fontsize: 200,
                                letterSpacing: 1,
                                fontFamily: "Montserrat-Regular !important-Regular !important",
                                fontSize: 15,
                                fontWeight: 100,
                            }}
                            onClick={onSubmit}
                        >
                            LOGIN
                        </Button>
                    </div>
                </form>
            </Grid>
        </Grid>
    );
};

export default Login;
