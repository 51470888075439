export const Userdata = [
    {
        avatar: "https://lbmslab.org/lab/wp-content/uploads/2018/09/beautiful-beauty-casual-762020.jpg",
        name: "elahe ",
        Mobile: "09126283654",
        Email: "erfan@gmail.com",
        select: false,
        id: 0,disable:false
    },
    {
        avatar: "https://lbmslab.org/lab/wp-content/uploads/2018/09/beautiful-beauty-casual-762020.jpg",
        name: "elham ",
        Mobile: "09126283654",
        Email: "erfan@gmail.com",
        select: false,
        id: 1,disable:false
    },
    {
        avatar: "https://lbmslab.org/lab/wp-content/uploads/2018/09/beautiful-beauty-casual-762020.jpg",
        name: "yusef ",
        Mobile: "09126283654",
        Email: "erfan@gmail.com",
        select: false,
        id: 2,disable:false
    },
    {
        avatar: "https://lbmslab.org/lab/wp-content/uploads/2018/09/beautiful-beauty-casual-762020.jpg",
        name: "erfan ",
        Mobile: "09126283654",
        Email: "erfan@gmail.com",
        select: false,disable:true,
        id: 3,
    },
    {
        avatar: "https://lbmslab.org/lab/wp-content/uploads/2018/09/beautiful-beauty-casual-762020.jpg",
        name: "karim ",
        Mobile: "09126283654",
        Email: "erfan@gmail.com",
        select: false,disable:false,
        id: 4,
    },
    {
        avatar: "https://lbmslab.org/lab/wp-content/uploads/2018/09/beautiful-beauty-casual-762020.jpg",
        name: "majid ",
        Mobile: "09126283654",
        Email: "erfan@gmail.com",
        select: false,
        id: 5,disable:true
    },
    {
        avatar: "https://lbmslab.org/lab/wp-content/uploads/2018/09/beautiful-beauty-casual-762020.jpg",
        name: "yusef ",
        Mobile: "09126283654",
        Email: "erfan@gmail.com",
        select: false,
        id: 6,disable:false
    },
];
