import { makeStyles } from "@mui/styles";

export const styles = makeStyles({
    cell: {
        fontWeight: "unset !important",
        fontFamily: "Montserrat-Regular !important-Regular !important !important",
        color: "#31515C !important",
        fontSize: "11px !important",
    },
    celldisable: {
        "&.MuiTypography-root": {
            fontFamily: "Montserrat-Regular !important-Regular !important",
            fontSize: "11px",
            letterSpacing: "0.5px",
            color: "#a9c1d6",
        },
    },
});
