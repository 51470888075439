import {
    Card,
    
    Grid,
    SpeedDial,
    SpeedDialAction,
    Typography,
} from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { Plusicon } from "../../../../../Components1/MainDashboard/Container/components/icons.js/actionicons";
import { SearchBar } from "../../../../../components/Searchbar";
import { Feild } from "../../../../../Components1/MainDashboard/Container/Languages/Translate/Feilds";
import { Header } from "../../../../../Components1/MainDashboard/Container/Languages/Translate/Header";
import { useEffect, useState } from "react";
import { Backdrops } from "../../../../../components/Backdrops";
import { AddLanguage, EditLanguage } from "../../../../../Components1/MainDashboard/Container/Languages/AddLanguage";
import { actions } from "../../../../../Components1/MainDashboard/Container/Languages/actions"; 
import { Styles } from "../../../../../Components1/MainDashboard/Container/Category/styles/styles";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import { ADD_TRANSLATE,REMOVE_TRANSLATES,
    FETCH_TRANSLATES,
 } from "../../../../../api/Languages/Languages";
import { Customdivider } from "../../../../../Components1/MainDashboard/Container/components/divider/Customdivider";

export const Translates = () => {
    const [page, setpage] = useState(1);
    var [data, setdata] = useState([]);
    const [openalert, setopenalert] = useState();
    const [openadd, setopenadd] = useState();
    const [val, setval] = useState({});
    const [openalertedit, setopenalertedit] = useState();
    const [removeTranslate] = useMutation(REMOVE_TRANSLATES);
    const [pagesp, setpagesp] = useState(0);
    const [addTranslate, data33] = useMutation(ADD_TRANSLATE);
    const { id } = useParams();

    const { loading, error, data2, refetch } = useQuery(FETCH_TRANSLATES, {
        fetchPolicy: "network-only",
        variables: {
            language: id,
            page: page,
        },
        onCompleted: (data1) => {
            setdata(data1.allTranslateAdmin.docs);
            setpagesp(data1.allTranslateAdmin.pages);
        },
    });
    useEffect(() => {
        refetch();
    }, [page]);
    const classes = Styles();
    useEffect(() => {
        setdata([...data]);
    }, [data.length]);
    if (loading) {
        return <Backdrops />;
    }
    return (
        <Grid style={{ height: "86%" }}>
            <Scrollbars
                thumbSize={50}
                autoHideTimeout={10}
                autoHide
                hideTracksWhenNotNeeded={true}
                style={{ height: "100%", marginTop: "15px" }}
            >
                <Grid container>
                    <Grid item xs={12}>
                            <SearchBar onsubmit={(e)=>{e.preventDefault();alert("hello :D ; "+`\n` + e.target.value)}}/>
                    </Grid>
                    <Grid
                        xs={12}
                        style={{
                            paddingTop: "4vh",
                            backgroundColor: "white",
                            borderRadius: "1vw",
                            marginTop: "15px",
                            boxShadow: "4px 4px 20px #d7e3eb",
                            display: "flex",
                        }}
                    >
                        <Grid xs={11.16} style={{ margin: "auto" }}>
                            <Grid xs={12}>
                                <Typography
                                    style={{
                                        color: "#31515C",
                                        fontFamily: "Montserrat-Regular !important",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        letterSpacing: "0.5px",
                                    }}
                                >
                                    Translate list
                                </Typography>
                                <Grid xs={12}>
                                 
                                    <Customdivider/>
                                    <Header />
                                    {data.map((item) => (
                                        <Feild
                                            delet={(e) => {
                                                removeTranslate({
                                                    variables: { id: e.id },
                                                })
                                                    .then((res) => {
                                                        setdata(
                                                            data.filter(
                                                                (item) => {
                                                                    return (
                                                                        item.id !==
                                                                        e.id
                                                                    );
                                                                }
                                                            )
                                                        );                                                        
                                                    })
                                                    .catch((err) => {
                                                        console.log(
                                                            err.message
                                                        );
                                                    });
                                            }}
                                            value={(e) => {
                                                setval(e);
                                            }}                                            
                                            setopenalert={() => {
                                                setopenalert(true);
                                            }}
                                            item={item}
                                            setopenedit={() => {
                                                setopenalertedit(true);
                                            }}
                                            close={() => {
                                                setopenalert(false);                                                
                                            }}
                                            submitForm={(e) => {
                                                setopenalert(false);
                                            }}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid
                                xs={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingBlock: 10,
                                }}
                            
                                >
                            </Grid>
                            <EditLanguage
                                loading={data.loading}
                                open={openalert}
                                close={() => {
                                    setopenalert(false);
                                }}
                                submitForm={(e) => {
                                    addTranslate({
                                        variables: {
                                            text: e.text1,
                                            translate: e.text2,
                                            languageId: id,
                                        },
                                    })
                                        .then((res) => {
                                            setdata([
                                                ...data,
                                                {
                                                    text: e.text1,
                                                    translate: e.text2,
                                                    id: res.data.addTranslate
                                                        .data.id,
                                                },
                                            ]);                                            
                                        })
                                        .catch((err) => {                                        
                                            console.log(err.message);
                                        });

                                    setopenalert(false);
                                }}
                                value={(e) => {
                                    setval(e);
                                }}
                            />
                            <EditLanguage
                                loading={data.loading}
                                open={openalertedit}
                                err=""
                                close={() => {
                                    setopenalertedit(false);
                                    
                                }}
                                submitForm={(e) => {                                    
                                    addTranslate({
                                        variables: {
                                            text: e.text1,
                                            translate: e.text2,
                                            id: e.id,
                                            languageId: id,
                                        },
                                    }).then((res) => {                                        
                                        data = data.map((item) => {
                                            if (item.id === e.id) {                                                
                                                return {
                                                    ...item,
                                                    text: e.text1,
                                                    translate: e.text2,
                                                    id: val.id,
                                                };
                                            } else {
                                                return item;
                                            }
                                        });
                                        setdata(data);
                                        setopenalertedit(false);
                                    });                                    
                                }}
                                value={{
                                    text1: val.text,
                                    text2: val.translate,
                                    id: val.id,
                                }}
                            />
                            <AddLanguage
                                loading={data.loading}
                                err=""
                                open={openadd}
                                close={() => {
                                    setopenadd(false);
                                }}
                            />
                            <Grid
                                xs={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 5,
                                }}
                            >                                
                                <Grid xs={11.3} className={classes.plusicon}>
                                    <SpeedDial
                                        ariaLabel="SpeedDial openIcon example"
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                        }}
                                        icon={<Plusicon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                onClick={() => {
                                                    action.type === "file"
                                                        ? setopenadd(true)
                                                        : setopenalert(true);
                                                }}
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Scrollbars>
        </Grid>
    );
};
