import { makeStyles } from '@mui/styles';


export const useStyles = makeStyles({
    rootw:{
        color:"red"
    },
    AddSport:{
        
        input:{backgroundColor: "red",},
        
        backgroundColor: "#fff",
        borderWidth: 2,
        paddingTop:"0px",
        fontFamily: "Montserrat-Regular !important-Regular !important",
        letterSpacing: 10,
        fontStyle: "normal",
        border: "100px solid white",
        '& label.Mui-focused': {
            color: '#0084AD',
        }, '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow',  borderRadius: "15px"
        },
        
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#DBDBDB',  borderRadius: "15px"
            },
            '&:hover fieldset': {
                borderColor: '#0084AD'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0084AD', borderRadius: "15px", borderWidth: 0.5
            },'&MuiInputBase-input':{
                backgroundColor:"red"
            },
            '&.MuiInputBase-input':{
                backgroundColor:"red"
            },
            '&.MuiOutlinedInput-root': {

                
                
                fontSize:'16px',
                color:"#31515C",
                padding:0,
                letterSpacing:"0px",
                fontFamily:"Montserrat-Regular !important-Regular !important",paddingLeft:".5vw"
                
                   ,'&.MuiOutlinedInput-input input':{
                    backgroundColor: "red" 
                }             
                
                /* THIS THE SIZE OF THE TEXTFEILD  */
            },
            
        },
    },
    rootss: {
        marginTop: 8,
        backgroundColor: "#fff",
        
        borderRadius: 40,
        fontFamily: "Montserrat-Regular !important-Regular !important",
        letterSpacing: 10,
        fontStyle: "normal",fontSize:200,
        border: "-0.5px solid #D5D8DD",
        '& label.Mui-focused': {
            color: '#0084AD',
        }, '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow', borderRadius: 40,
        },
        
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#DBDBDB', borderRadius: 40
            },
            '&:hover fieldset': {
                borderColor: '#0084AD'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0084AD', borderRadius: 40, borderWidth: 0.5
            },
            '&.MuiOutlinedInput-root': {
                height: "8vh",
                width: "19vw",
                fontSize:20,
                color:"#31515C",
                
                letterSpacing:1.5
                
                
                /* THIS THE SIZE OF THE TEXTFEILD  */
            }
        },
    },
    Text:{
        '& span':{
            fontSize:"13px"
            , fontFamily : "Montserrat-Regular !important-Regular !important"
        }
    },
    label:{
        '&.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected':{
            backgroundColor:"#EAEEFA",
            color:"#3b3bff"
        }
    },
    svgg :{
        '&.makeStyles-svgg-11':{
            backgroundColor:"#EAEEFA",
            color:"#3b3bff"
        }
    },
    rot: {
        '&:focus': {
            border: "1.5px solid cyan",
            justifyContent: "center"
        }
    },
    Container: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        width: "50%",
        backgroundColor:"white",
        height: window.innerHeight,
    },
    WelcomeText: {
        margin: 0, textAlign: "center", fontSize: 54, color: "white", backgroundColor: ""
        , fontFamily: "Montserrat-Regular !important-Regular !important", alignContent: "center",
        letterSpacing: 0.95, width: "100%",fontWeight:"normal"
    },
    Btn_Login: {
        height: "6.57407vh",
        width: "19vw", borderRadius: 40, backgroundColor: "#3b3bff", color: "white", fontsize: 200,
        letterSpacing: 1,
        fontFamily: "Montserrat-Regular !important-Regular !important",
        fontSize: "1.1vw",
        fontWeight: 100,
    },
   Nav:{
        "& nav":{
            paddingTop:"0px"
        }

    },
    rootSelect: {
        
        "&:hover div": {
          color: "red",
          width:"4.114583vw"
          
        },
        "& .MuiInputLabel-root": {
          color: "red",
          borderWidth:0
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-input": {
            borderColor: "white"
          
        },
        "&:hover .MuiInputLabel-root": {
            borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: "white"
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "white"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "white"
        }
      }
      
});
// class="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-circular MuiPaginationItem-textPrimary Mui-selected MuiPaginationItem-page css-bny1na-MuiButtonBase-root-MuiPaginationItem-root"
//MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root