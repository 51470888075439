import { Badge,IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';

export const Comments = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: "-10vw",
      top: "10px",
      backgroundColor:"#31515C",
     color:"white",
     width:"20px",height:"20px",borderRadius:"40px",
     fontSize:"10px"
      

    }
  }));


  export const Comments1=(props)=>{
      return(
        
        <Comments badgeContent={10} color="secondary">
        <div style={{width:"53px",display:"flex"}}>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="21" height="21" viewBox="0 0 21 21">
  <path id="Icon_Chat_Room" data-name="Icon Chat Room" d="M14.125-377.937H7.563l2.4-2.626h8.1a1.313,1.313,0,0,0,1.312-1.312v-7.874h1.312A1.312,1.312,0,0,1,22-388.437v9.187a1.314,1.314,0,0,1-1.312,1.314H18.063V-374Zm-9.188-5.251H2.312A1.313,1.313,0,0,1,1-384.5v-9.188A1.313,1.313,0,0,1,2.312-395H15.437a1.312,1.312,0,0,1,1.312,1.312v9.188a1.312,1.312,0,0,1-1.312,1.312H8.875l-3.938,3.937Z" transform="translate(-1 395)" fill={props.color}/>
</svg>
        </div>

        </Comments>
      
      )
  }
  


