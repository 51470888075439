import { gql } from "@apollo/client";

export const RESET_TRANSLATES = gql`
    mutation ($languageId: String!) {
        resetTranslate(languageId: $languageId) {
            id
            languageId
            text
            translate
        }
    }
`;

export const FETCH_LANGUAGES = gql`
    query {
        allLanguges {
            id
            title
            name
            image
        }
    }
`;

export const ADD_LANGUAGE = gql`
    mutation ($name: String!, $title: String!, $image: Upload) {
        addLanguage(data: { name: $name, title: $title, image: $image }) {
            message
            data {
                id
                name
                title
                image
            }
        }
    }
`;

export const REMOVE_LANGUAGES = gql`
    mutation ($id: String!) {
        removeLanguage(id: $id) {
            message
        }
    }
`;

export const FETCH_TRANSLATES = gql`
    query ($language: ID!, $page: Int!) {
        allTranslateAdmin(language: $language, page: $page) {
            docs {
                id
                languageId
                text
                translate
            }
            total
            limit
            page
            pages
        }
    }
`;

export const ADD_TRANSLATE = gql`
    mutation (
        $id: String
        $text: String!
        $translate: String!
        $languageId: String!
    ) {
        addTranslate(
            data: {
                id: $id
                text: $text
                translate: $translate
                languageId: $languageId
            }
        ) {
            message
            data {
                id
                text
                translate
                languageId
            }
        }
    }
`;

export const REMOVE_TRANSLATES = gql`
    mutation ($id: String!) {
        removeTranslate(id: $id) {
            message
        }
    }
`;
