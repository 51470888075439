import { gql } from "@apollo/client";

export const Data_schema = gql`
query {
    allPlayList {
        id
        name
        title        
        image
        createdAt
        
    }
}
`;
export const GET_DOG_PHOTO=gql`
query search {
    search {
        name
        email
        
        age
    }
}
`;
export const Deletecategory = gql`

# Increments a back-end counter and gets its resulting value

mutation deletitem($name: String) {
    

    deletitem(name: String){
        id 
        
    }

}

`;
export const REMOVE_PLAY = gql`
    mutation ($id: String!) {
        removePlay(id: $id) {
            message
        }
    }
`;

export const ADD_PLAY = gql`
    mutation ($name: String, $title: String, $image: Upload) {
        addPlay(input: { name: $name, title: $title, image: $image }) {
            message
            data {
                id
                name
                title
                image
                createdAt
            }
        }
    }
`;
export const EDIT_PLAY = gql`
    mutation ($id: ID ,$name: String, $title: String, $image: Upload) {
        editPlay(input: { id: $id, name: $name, title: $title, image: $image }) {
            message
            data {
                id
                name
                title
                image
                createdAt
            }
        }
    }
`;