import { Avatar, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Customavatar } from "../../components/avatar/customavatar";
export const Volounteers = ({ item }) => {
    return (
        <Grid xs={12} style={{ marginTop: 10 }}>
            <Grid container style={{ margin: "auto" }} xs={10}>
                <Grid xs={2}>
                    <Customavatar link="erfan" />
                </Grid>
                <Grid xs={2} style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        style={{
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            color: "#BAC4CC",
                            fontSize: "11px",
                        }}
                    >
                        {item.name}
                    </Typography>
                </Grid>
                <Grid xs={5} style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        style={{
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            color: "#BAC4CC",
                            fontSize: "11px",
                        }}
                    >
                        {item.email}
                    </Typography>
                </Grid>
                <Grid style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        style={{
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            color: "#31515C",
                            fontSize: "11px",
                            marginLeft: "5px",
                        }}
                    >
                        {item.status}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
