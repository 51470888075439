import React from "react";
import { Route, Routes,useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import Login from "../Pages/Auth/Login";
import { useStyles} from "../Pages/Auth/Styles";


const AuthRoute = () => {
    const classes = useStyles();
    
    console.log(localStorage.getItem('@token'))
   
    return (
        <Grid container>
            <Grid item xs={6}>
                <Grid className={classes.ImagePart} md={12}>
                    <div style={{ marginBottom: "19.5vh" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <span className={classes.WelcomeText}>Welcome To Encoy</span>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <h5
                                style={{
                                    margin: 0,
                                    color: "white",
                                    fontFamily: "Montserrat-Regular !important-Regular !important",
                                    fontSize: 24,
                                    letterSpacing: 0.5,
                                    width: "100%",
                                    fontWeight: "normal",
                                }}
                            >
                                Get out of digital world and enjoy the reality
                            </h5>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Routes>
                    <Route path="/" element={<Login />} />
                </Routes>
            </Grid>
        </Grid>
    );
};

export default AuthRoute;
