import { gql } from "@apollo/client";

export const Data_Comments = gql`
    query comments {
        comments {
            name
            email
            onlice
            id
            disable
            online
        }
    }
`;
