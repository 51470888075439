import { gql } from "@apollo/client";

export const EVENTS_DATA = gql`
  query {
    adminEventsList {
      id
      image
      title
      playId {
        title
      }
      userId {
        firstName
        lastName
      }
      date
      fromTime
      toTime
      country
      city
      location
      option {
        title
      }
      playType
      playmateCount
      playmateRemainsCount
      payment
      amount
      fromAge
      toAge
      playmateLevel {
        title
        priority
      }
      description
    }
  }
`;
export const cancelEventGql = gql`
  mutation (
    $eventId: String!
    $revokedDescription: String!
    $revokedReason: String!
  ) {
    removeEvent(
      remove: {
        eventId: $eventId
        revokedDescription: $revokedDescription
        revokedReason: $revokedReason
      }
    ) {
      message
    }
  }
`;
