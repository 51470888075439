import {
  Grid,
  Card,
  Typography,
  Divider,
  Collapse,
  ButtonBase,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { SearchBar } from "../../../../components/Searchbar";
import { Plusicon } from "../../../../Components1/MainDashboard/Container/Notiffication/icons/Plusicon";
import { NotifDetails } from "../../../../Components1/MainDashboard/Container/Notiffication/notifdetails";

const Array = [1, 2, 3, 4, 5];
const Texts = () => {
  const [open, setopen] = useState(false);
  const [trans, settranse] = useState(false);
  return (
    <Grid xs={12} style={{ marginBottom: "22px" }}>
      <span style={{ fontSize: "11px", color: "#31515C" }}>
        In publishing and graphic design, Lorem ipsum is a placeholder text
        commonly used to demonstrate the. https://encoy.com.tr/
      </span>
      <Grid container xs={12} style={{ paddingTop: "18px" }}>
        <Grid xs={2}>
          <span
            style={{
              fontSize: "9px",
              color: "#BAC4CC",
              fontFamily: "Montserrat-Regular !important-Regular !important",
              maxHeight: 20,
            }}
          >
            1/12/2022
          </span>{" "}
        </Grid>

        <Grid xs={10} style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <ButtonBase
              onClick={() => {
                setopen(!open);
              }}
              style={{
                fontSize: "9px",
                marginBottom: "5px",
                color: "#BAC4CC",
              }}
            >
              SHOW MORE
            </ButtonBase>
            <div
              transform="rotate(90deg)"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.427"
                onClick={() => {
                  setopen(!open);
                  settranse(true);
                }}
                height="5.714"
                viewBox="0 0 9.427 5.714"
              >
                <path
                  id="Path"
                  d="M6.6,0,3.3,3.3,0,0"
                  transform="translate(1.414 1.414)"
                  fill="none"
                  stroke="#f90"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
        </Grid>
        <NotifDetails
          open={open}
          data={{
            device: "all",
            category: "Team Sports",
            users: "All",
          }}
        />
      </Grid>
    </Grid>
  );
};
export const Notiffication = () => {
  const nav = useNavigate();
  return (
    <Grid spacing={1} style={{ marginTop: "15px" }}>
      <SearchBar />
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "white",
          borderRadius: "1vw",
          marginTop: "15px",
          boxShadow: "4px 4px 20px #d7e3eb",
          paddingTop: 50,
          paddingBottom: "20px",
        }}
      >
        <Grid xs={10.9} style={{ margin: "auto" }}>
          <Typography
            style={{
              fontFamily: "Montserrat-Regular !important-Regular !important",
              fontSize: "14px",
            }}
          >
            All Notiffications
          </Typography>
        </Grid>
        <Grid
          xs={10.9}
          style={{
            paddingTop: "7px",
            margin: "auto",
            paddingBottom: "32px",
          }}
        >
          <Divider
            style={{
              border: "1px solid rgb(235, 242, 247)",
              backgroundColor: "rgb(235, 242, 247)",
            }}
          ></Divider>
        </Grid>
        <Grid container xs={10.9} style={{ margin: "auto" }}>
          <Grid xs={5.5} style={{ lineHeight: "14px" }}>
            {Array.map(() => (
              <Texts />
            ))}
          </Grid>
          <Grid
            xs={5.5}
            style={{
              lineHeight: "14px",
              marginLeft: "4.5vw",
            }}
          >
            {Array.map(() => (
              <Texts />
            ))}
          </Grid>

          <Grid
            xs={12}
            style={{
              marginTop: "-27px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Plusicon
              press={() => {
                nav("sendnotif");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
