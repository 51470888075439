import MainDashboard from "../../Components1/MainDashboard/Container/MainDashboard/MainDashboard";
import Category1 from "./Container/Category";
import Events from "./Container/Events";
import Users from "./Container/Users";
// import Languages from "../../Pages/MainDashboard/Container/Languages";
import Questions from "./Container/Questions";
import Activities from "./Container/Activities";
import { Notiffication } from "./Container/Notiffication";
import { CommentsPage } from "./Container/Comments";
import Management from "./Container/Management";
import Language from "./Container/Languages/index";
// icons
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SportsVolleyballOutlinedIcon from '@mui/icons-material/SportsVolleyballOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
// import EventIcon from '@mui/icons-material/Event';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import Divider from "@mui/material/Divider";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';



export var Sidebar = [
    {
        id: 0,
        name: "Dashboard",
        path: "main",
        // show: false,
        component: <MainDashboard />,
        icon: ({ color }) => {
            return (
                <DashboardOutlinedIcon style={{ color: color }}/>

            );
        },
    },
    {
        id: 1,
        name: "Management",
        path: "management",
        // show: false,
        component: <Management />,
        icon: ({ color }) => (
            <ManageAccountsOutlinedIcon style={{ color: color }}/>
        ),
    },
    {
        id: 2,
        name: "Sports",
        path: "Sports",
        component: <Category1 />,
        icon: ({ color }) => {
            console.log(color);
            return (
                <SportsVolleyballOutlinedIcon style={{ color: color }}/>
            );
        },
    },
    {
        id: 3,
        name: "Users",
        path: "Users",
        // show: false,
        component: <Users />,
        icon: ({ color }) => {
            return (
                <PeopleOutlineIcon style={{ color: color }}/>
            );
        },
    },
    {
        id: 4,
        name: "Events",
        path: "Events",
        // show: false,
        component: <Events />,
        icon: ({ color }) => {
            return (
                <CalendarTodayOutlinedIcon style={{ color: color }}/>
            );
        },
        
    },
 {
        id: 5,
        name: "Notify",
        path: "notify",
        // show: false,n
        component: <Notiffication />,
        icon: ({ color }) => {
            return (
                <SendOutlinedIcon style={{ color: color }}/>

            );
        },
        extendable: [
            {
                id: 5.1,
                path: "mobile-notification",
                icon: ({ color }) => {
                    return (
                        <NotificationsNoneOutlinedIcon style={{ color: color }}/>
        
                    );
                },
                name: "Notification",
            },
            {
                id: 5.2,
                path: "email",
                icon: ({ color }) => {
                    return (
                        <ForwardToInboxIcon style={{ color: color }}/>
        
                    );
                },
                name: "Email",
            },
            {
                id: 5.3,
                path: "sms",
                icon: ({ color }) => {
                    return (
                        <AddCommentOutlinedIcon style={{ color: color }}/>
        
                    );
                },
                name: "SMS",
            },
        ],
    },
    // {
    //     id: 5,
    //     name: "Notification",
    //     path: "Notification",
    //     // show: false,n
    //     component: <Notiffication />,
    //     icon: ({ color }) => {
    //         return (
    //             <NotificationsNoneOutlinedIcon style={{ color: color }}/>

    //         );
    //     },
    // },
    
    {
        id: 6,
        name: "Comments",
        path: "Comments",
        // show: false,
        component: <CommentsPage />,
        icon: ({ color }) => {
            return (
                <CommentOutlinedIcon style={{ color: color }}/>
            );
        },
    },
    {
        id: 7,
        name: "Language",
        path: "Language",
        // show: false,
        component: <Language />,
        icon: ({ color }) => {
            return (
                <LanguageOutlinedIcon style={{ color: color }}/>
            );
        },
    },
    {
        id: 8,
        name: "Interests",
        path: "Interests",
        // show: false,
        component: <Questions />,
        icon: ({ color }) => {
            return <InterestsOutlinedIcon style={{ color: color }} />;
        },
    },
    {
        id: 9,
        name: "Activities",
        path: "Activities",
        // show: false,
        component: <Activities />,
        icon: ({ color }) => {
            return <SettingsAccessibilityOutlinedIcon style={{ color: color }}/>;
        },
    },
    
];
