import React from "react";
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from "@mui/styles";


const Styles = makeStyles({
    actionButton: {
        margin: "0 5px",
        minWidth: "unset",
    },
    userInfoButton: {
        backgroundColor: "#ccf9ee",
        color: "#03debb",
        width: "75%",
        "&:hover": {
            backgroundColor: "#ccf9ee",
        },
    },
});

const ActionButtons = ({ block, unblock, remove, edit, show, add, resetPass, functions }) => {
    const classes = Styles();
    return (
        <div>
            {block && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#f9e0e0", color: "#c80101" , marginLeft: 5, marginRight: 5,width:32,height:32,}}
                    onClick={(e) => functions.block()}
                    title="Block"
                >
                    <BlockOutlinedIcon />
                </IconButton>
            )}

            {unblock && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#ccf9ee", color: "#03debb" , marginLeft: 5, marginRight: 5,width:32,height:32,}}
                    onClick={(e) => functions.unblock()}
                    title="Unblock"
                >
                    <CheckCircleOutlinedIcon />
                </IconButton>
            )}

            {edit && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#DFDFFFCC", color: "#3B72FF" , marginLeft: 5, marginRight: 35,width:32,height:32,}}
                    onClick={(e) => functions.edit()}
                    title="Edit"
                >
                    <EditOutlinedIcon />
                </IconButton>
            )}

            {remove && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#FFDDEDCC", color: "#F8304F" , marginLeft: 0, marginRight: 0,width:32,height:32,}}
                    onClick={(e) => functions.remove()}
                    title="Delete"
                >
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            )}

            {show && (
                <IconButton
                    className={classes.actionButton}
                    style={{color: "#000" , marginLeft: 5, marginRight: 5,width:32,height:32,}}
                    onClick={(e) => functions.show()}
                    title="Show"
                >
                    <RemoveRedEyeIcon />
                </IconButton>
            )}

            {add && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#7FB5FF", color: "#fff" , marginLeft: 5, marginRight: 5,width:32,height:32,}}
                    onClick={(e) => functions.add()}
                    title="Add"
                >
                    <AddIcon />
                </IconButton>
            )}

            {resetPass && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#FFC54D", color: "#fff" , marginLeft: 5, marginRight: 5,width:32,height:32,}}
                    onClick={(e) => functions.resetPass()}
                    title="Change Password"
                >
                    <LockOpenOutlinedIcon />
                </IconButton>
            )}
        </div>
    );
};

export default ActionButtons;
