import { Grid, Typography } from "@mui/material";
import { PlusIcon } from "../../../../Components1/MainDashboard/Container/Languages/icons/plusicon";
import { HeaderLanguages } from "../../../../Components1/MainDashboard/Container/Languages/HeaderTitles";
import { Language } from "../../../../Components1/MainDashboard/Container/Languages/Language";
import { useState } from "react";
import { Customdivider } from "../../../../Components1/MainDashboard/Container/components/divider/Customdivider";
import { useMutation, useQuery } from "@apollo/client";
import { Editdialog } from "../../../../Components1/MainDashboard/Container/components/editadd/editadd";
import { AlertDialog } from "../../../../Components1/MainDashboard/Container/components/Dialog/AlertDialogs";
import DataTable, { } from "../../../../components/DataTables";
import {
    ADD_LANGUAGE,
    FETCH_LANGUAGES,    
} from "../../../../api/Languages/Languages";

function Languages() {
    const [open, setOpen] = useState(false);
    const [opendialog, setopendialog] = useState(false);
    const [value, setvalue] = useState("");
    const [langs, setLanguages] = useState([]);
    const [file, setfile] = useState();
    const [er, seter] = useState("");
    const [addLanguage, { data }] = useMutation(ADD_LANGUAGE);
    const { loading, error } = useQuery(FETCH_LANGUAGES, {
        fetchPolicy: "network-only",
        onCompleted: (data1) => {
            setLanguages(data1.allLanguges);
            console.log(data1);
        },
    });
    const submitLanguage = (formData) => {
        addLanguage({
            variables: {
                name: formData.name,
                title: formData.name,
                image: formData.image,
            },
        })
            .then((res) => {
                console.log("addLanguage ::: ", res);
                setLanguages([...langs, res.data.addLanguage.data]);
                setOpen(false);
            })
            .catch((err) => {
                console.log(err.message);
                seter(err);
            });
    };
    return (
        <Grid style={{ width: "100%", marginTop: "15px" }}>
            <Grid
                xs={8}
                style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    boxShadow: "4px 4px 20px #d7e3eb",
                    paddingTop: "35px",
                    paddingBottom: "35px",
                }}
            >
                <Grid xs={11} style={{ margin: "auto" }}>
                    <Typography
                        style={{
                            color: "#31515C",
                            fontFamily: "Montserrat-Regular !important",
                            fontSize: "1vw",
                            fontWeight: "unset",
                        }}
                    >
                        Languages
                    </Typography>
                </Grid>
                <Customdivider size={11} />
                <HeaderLanguages />

                <AlertDialog
                    open={opendialog}
                    actiontext="Delete"
                    close={() => {
                        setopendialog(false);
                    }}
                    message="Are you sure you want to delete this item?"
                    action={() => {
                        alert(value);
                    }}
                />
                {langs.map((item) => {
                    return (
                        <Language
                            edit={() => {
                                setOpen(true);
                            
                                setvalue(item);
                            }}
                            parameters={{ title: item.title }}
                            item={item}
                            delete={(id) => {
                                setvalue(id);
                                setopendialog(true);
                            }}
                        />
                    );
                })}
            </Grid>
            <Grid
                xs={8}
                style={{
                    display: open ? "none" : "flex",
                    justifyContent: "center",
                    marginTop: -27,
                }}
            >
                <PlusIcon
                    action={() => {
                        setOpen(!open);
                  
                    }}
                    display={open ? "none" : "block"}
                />
            </Grid>
            <Editdialog
                title="Add New Language "
                type="add_lang"
                fileget={(file) => {
                    setfile(file);
                }}
                err={er}
                submit={submitLanguage}
                i={open}
                close={() => {
                    setOpen(false);
                }}
            />

        </Grid>
        
    );
}
export default Languages;
