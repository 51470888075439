import React, { useState } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import {
    Button
} from "@mui/material";

export const CSV_Downloader = (dataCSV )=>{
    console.log(dataCSV.dataCSV)
    // const UsersData = dataCSV;
    const [Data, setData] = React.useState([])
    React.useEffect(() => {
        setData(dataCSV.dataCSV)
    }, [dataCSV])
    return(
        // <CSVLink data={CSV} headers={Headers} target="_blank">Download ME</CSVLink>
        <div>
        {/* console.log({data}) */}
        {/* <button onClick={() => console.log(dataCSV)}><DownloadIcon></DownloadIcon></button> */}
       <CSVLink data={Data ?? []} target="_blank" filename={'CSV Data.csv'}><Button variant="contained" disabled  style={{ color:"#FFF" ,backgroundColor: "#3b3bff", justifyContent: "center", display: 'flex', borderRadius:"8px", borderColor:"#3b3bff", padding:"10px" , width:"200px"}}>Download CSV Data</Button></CSVLink>
       
        
        </div>
        );
    
}