import { makeStyles } from "@mui/styles";

export const Styles = makeStyles({
    name: {
        fontSize: "13px !important",
        fontWeight: "bold !important",
    },
    paragrapgh: {
        fontSize: "11px !important",
        fontFamily: "Montserrat-Regular !important-Regular !important",
        color: "#31515C",
    },
    phoneanddate: {
        fontSize: "10px",
        fontFamily: "Montserrat-Regular !important-Regular !important",
        color: "#BAC4CC",
    },
    cellcontainer: {
        // display: "flex",
        // alignItems: "center",
        paddingTop:"16px"
        
    },
    searchbar: {
        backgroundColor: "white",
        borderRadius: "1vw !important",
        boxShadow: "4px 4px 20px #d7e3eb !important",
        padding: "12px 40px !important",
    },
    commentspart: {
        backgroundColor: "white",
        borderRadius: "20px",
        paddingTop: "40px",
    },
    checkbox: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});
