import { makeStyles } from "@mui/styles";
import pic1 from "./Images/playing.png";

export const useStyles = makeStyles({
    rootw: {
        color: "red",
    },
    AddSport: {
        input: { backgroundColor: "red" },

        backgroundColor: "#fff",
        borderWidth: 2,
        paddingTop: "0px",
        fontFamily: "Montserrat-Regular !important-Regular !important",
        letterSpacing: 10,
        fontStyle: "normal",
        border: "100px solid white",
        "& label.Mui-focused": {
            color: "#3b3bff",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "yellow",
            borderRadius: "15px",
        },

        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#DBDBDB",
                borderRadius: "15px",
            },
            "&:hover fieldset": {
                borderColor: "#3b3bff",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3b3bff",
                borderRadius: "15px",
                borderWidth: 0.5,
            },
            "&MuiInputBase-input": {
                backgroundColor: "red",
            },
            "&.MuiInputBase-input": {
                backgroundColor: "red",
            },
            "&.MuiOutlinedInput-root": {
                fontSize: "16px",
                color: "#31515C",
                padding: 0,
                letterSpacing: "0px",
                fontFamily: "Montserrat-Regular !important-Regular !important",
                paddingLeft: ".5vw",

                "&.MuiOutlinedInput-input input": {
                    backgroundColor: "red",
                },

                /* THIS THE SIZE OF THE TEXTFEILD  */
            },
        },
    },
    rootss: {
        marginTop: 8,
        backgroundColor: "#fff",

        borderRadius: 40,
        fontFamily: "Montserrat-Regular !important-Regular !important",
        letterSpacing: 10,
        fontStyle: "normal",
        fontSize: 200,
        border: "-0.5px solid #D5D8DD",
        "& label.Mui-focused": {
            color: "#3b3bff",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "yellow",
            borderRadius: 40,
        },

        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#DBDBDB",
                borderRadius: 40,
            },
            "&:hover fieldset": {
                borderColor: "#3b3bff",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3b3bff",
                borderRadius: 40,
                borderWidth: 0.5,
            },
            "&.MuiOutlinedInput-root": {
                height: "53px",
                width: "260px",
                fontSize: 20,
                color: "#31515C",

                letterSpacing: 1.5,

                /* THIS THE SIZE OF THE TEXTFEILD  */
            },
        },
    },
    Text: {
        "& span": {
            fontSize: "13px",
            fontFamily: "Montserrat-Regular !important-Regular !important",
        },
    },
    label: {
        "&.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected": {
            backgroundColor: "#EAEEFA",
            color: "#3b3bff",
        },
    },
    svgg: {
        "&.makeStyles-svgg-11": {
            backgroundColor: "#EAEEFA",
            color: "#3b3bff",
        },
    },
    rot: {
        "&:focus": {
            border: "1.5px solid cyan",
            justifyContent: "center",
        },
    },
    Container: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        // width: "50%",
        backgroundColor: "white",
        height: "100vh",
    },
    WelcomeText: {
        margin: 0,
        textAlign: "center",
        fontSize: 54,
        color: "white",
        backgroundColor: "",
        fontFamily: "Montserrat-Regular !important-Regular !important",
        alignContent: "center",
        letterSpacing: 0.95,
        width: "100%",
        fontWeight: "normal",
    },
    Btn_Login: {
        height: 53,
        width: 260,
        borderRadius: 40,
        backgroundColor: "red",
        color: "white",
        fontsize: 200,
        letterSpacing: 1,
        fontFamily: "Montserrat-Regular !important-Regular !important",
        fontSize: "1.1vw",
        fontWeight: 100,
    },
    ImagePart: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "flex-end",
        display: "flex",
        height: "100vh",
        backgroundImage: `url(${pic1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    
    Nav: {
        "& nav": {
            paddingTop: "0px",
        },
    },
    rootSelect: {
        "&:hover div": {
            color: "red",
            width: "4.114583vw",
        },
        "& .MuiInputLabel-root": {
            color: "red",
            borderWidth: 0,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
        },
        "&:hover .MuiOutlinedInput-input": {
            borderColor: "white",
        },
        "&:hover .MuiInputLabel-root": {
            borderColor: "white",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "white",
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "white",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
                borderColor: "white",
            },
    },
    root1ss: {
        "&:hover .MuiSelect-standard": {
            backgroundColor: "yellow",
        },
        "& .MuiSelect-standard-focused": {
            backgroundColor: "white",
        },
        "&:hover 	.MuiSelect-select": {
            backgroundColor: "white",

            borderColor: "yellow",
        },
        "&:hover .makeStyles-rootSelect-11": {
            backgroundColor: "yellow",
        },
    },
    form: {
        "& .MuiFormControl-root .css-1nrlq1o-MuiFormControl-root": {
            backgroundColor: "red",
        },
    },
    Search_category: {
        input: { backgroundColor: "red" },

        backgroundColor: "#fff",
        borderWidth: 2,
        paddingTop: "0px",
        fontFamily: "Montserrat-Regular !important-Regular !important",
        letterSpacing: 10,
        fontStyle: "normal",
        border: "100px solid white",
        "& label.Mui-focused": {
            color: "#3b3bff",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "yellow",
            borderRadius: "13px",
        },

        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#dce4eb",
                borderRadius: "13px",
            },
            "&:hover fieldset": {
                borderColor: "#3b3bff",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3b3bff",
                borderRadius: "13px",
                borderWidth: 0.5,
            },
            "&MuiInputBase-input": {
                backgroundColor: "red",
            },
            "&.MuiInputBase-input": {
                backgroundColor: "red",
            },
            "&.MuiOutlinedInput-root": {
                fontSize: "16px",
                color: "#31515C",
                padding: 0,
                letterSpacing: "0px",
                fontFamily: "Montserrat-Regular !important-Regular !important",
                paddingLeft: ".5vw",

                "&.MuiOutlinedInput-input input": {
                    backgroundColor: "red",
                },

                /* THIS THE SIZE OF THE TEXTFEILD  */
            },
        },
    },
    formAreaInput: {
        padding: 0,
        backgroundColor: "red",
    },
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#31515C",
            fontSize: "10px",
            height: "20px",
            minWidth: "20px !important",
            padding: "0px",
            borderRadius: 20,
        },
        "& .MuiTouchRipple-root": {
            color: "green",
        },
    },
    li: {
        "& .MuiButtonBase-root": {
            color: "red",
        },
    },
    root1212: {
        color: "blue",
    },
    leftIconButton: {
        color: "blue !important",
    },
    rightIconButton: {
        color: "red !important",
    },
    serachfeildcontainer: {
        justifyContent: "center",
        paddingTop: "15px",
        paddingBottom: "15px",
    },
});
// class="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-circular MuiPaginationItem-textPrimary Mui-selected MuiPaginationItem-page css-bny1na-MuiButtonBase-root-MuiPaginationItem-root"
//MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root
