import { Grid, Typography } from "@mui/material"
import { HeaderTitles } from "../../components/headertitles/HeaderTitle"


export const Header = ()=>{
    return   <Grid xs={12} style={{borderRadius: "14px",backgroundColor: "#EAEEFA" ,margin:"auto",marginTop:10 }}>
    <Grid
        xs={12}
        container
        style={{
            paddingTop: "13px",
            paddingBottom: "14px",
        }}
    >
        <Grid xs={5.15} style={{paddingLeft:"21px"}}>
           <HeaderTitles text={"text"}/>
        </Grid>
        
        <Grid xs={5}>
        <HeaderTitles text={"Translate"}/>
        </Grid>
       
        <Grid container xs={1.8} style={{backgroundColor:'none'}}>
       <div style={{width:"40%"}}> <HeaderTitles text={"Edit"} /></div>
        <HeaderTitles text={"Delete"} />
            
        </Grid>
    </Grid>
  
</Grid>
}