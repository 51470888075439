import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,

  Typography,
  Card,
  Grid,
} from "@mui/material";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItemText from '@mui/material/ListItemText';
import { Avatar } from "@mui/material";
import { Pagination, PaginationItem, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { Backdrops } from "../components/Backdrops";
import { makeStyles } from "@mui/styles"
// import currencySeparator from "../../shared/currencySeparator";

const Styles = makeStyles({
  TableContainer: {
    // marginTop: 20,
    boxShadow: "unset",
    Width: "100%",
    margin: "30px",
    marginBottom: 0,
    // backgroundColor: "#F2F2FF",
    borderRadius: "10px",
    color: "#fff",
  },
  tabelTitle: {
    fontSize: 20,
    fontFamily: "Montserrat-Medium.ttf !important",
    // padding: "20px 50px",

    // position: 'fixed'
  },
  Thead: {
    // backgroundColor: "#000",

    // borderRadius: "10px",
    // margin: 10,
    // margin:5,


    "& th:first-child": {
      borderRadius: "20px 0 0 20px",
    },
    "& th:last-child": {
      borderRadius: "0 20px 20px 0",
    },
    "& th": {
      color: "#919EB8",
      backgroundColor: "#F1F3F7",
      // borderRadius: "30px",
      padding: 20,
      textAlign: "center",
      // margin: 5,
      fontFamily: "Montserrat-Regular !important",
      fonyWeight: 700,

      fontSize: 13,
    },
  },
  TRow: {
    backgroundColor: "#fff",
    borderRadius: "20%",
    // margin:10,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#F2F2FF99 !important",

      // margin:10,
      "& td:first-child": {
        borderRadius: "20px 0 0 20px",
      },
      "& td:last-child": {
        borderRadius: "0 20px 20px 0",
      },
    },
    "& td": {
      padding: 5,
      // margin: 10,
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      borderBottom: "unset",
      
      fontSize: 11,
    },
  },

  pagination: {
    marginTop: 10,
    // marginBottom: 20,
    "& .MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#F6AACBCC"
    },
    "& .MuiPaginationItem-root.Mui-selected:hover": {
      backgroundColor: "#F6AACB"

    },
    "& ul": {
      justifyContent: "center",
      color: "#000",

    },
  },
});

const DataTable = (props, loading) => {
  const classes = Styles();
  const { headData, data, title, arrayFields, currentPage, route, onChange } = props;
  const handleChangePage = (e, value) => {
    props.changePage(value);
  };

  const arrayToString = (row, field) => {
    let arrayField = arrayFields.filter((item) => item.name === field)[0];
    let string = "";
    row[field].map((item, key) => {
      let text = "";
      arrayField.field.map((f) => {
        text = text + " " + (item[f] ? item[f] : "");
      });
      string =
        string +
        text +
        (key !== row[field].length - 1 ? arrayField.seprator : "");
    });
    return string;
  };

  const fixFieldValue = (row, fieldName) => {
    let fieldType = headData.filter((head) => head.field === fieldName)[0].type;
    switch (fieldType) {
      case "array":
        return arrayToString(row, fieldName);
      case "date":
        return row[fieldName] ? moment(row[fieldName], "DD/MM/YYYY") : "----";
      case "dateTime":
        return row[fieldName]
          ? moment(row[fieldName], "DD/MM/YYYY HH:mm:ss")
          : "----";
      default:
        return row[fieldName] ? row[fieldName] : "----";
    }
  };
  // if (loading) {
  //     return <Backdrops />;}
  // else {
  return (
    <Card style={{boxShadow: "unset"}} className={classes.TableContainer}>
      {title && <Typography className={classes.tabelTitle}>{title}</Typography>}
      <TableContainer style={{ padding: 1, maxHeight: "100%" }}>
        <Table stickyHeader>
          {/* <Grid style={{position: "fixed", width: "40%" ,borderRadius: 20, backgroundColor:"#000"}}> */}

          <TableHead style={{ margin: 20, maxHeight: "100%" }}>
            <TableRow className={classes.Thead}>
              {headData.map((cell, index) => (
                <TableCell key={index}>{cell.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* </Grid> */}
          <TableBody>
            {data.map((row, index) => (
              //   <Grid style={{ backgroundColor: "#f9fafc", borderRadius: "20%" }}
              <>
                <TableRow
                  hover={true}
                  style={{
                    opacity: row?.banned ? 0.6 : 1,
                    // backgroundColor: row?.banned ? "#ccc" : "#fff",
                  }}

                  className={classes.TRow}
                  key={index}

                >


                  {headData.map((cell, key) => (
                    <TableCell key={key} className={classes.rowTitle}>
                      {row[cell.field] === undefined &&
                        props[cell.field](row, index + 1)}
                      {row[cell.field] !== undefined &&
                        fixFieldValue(row, cell.field)}

                    </TableCell>

                  ))}
                </TableRow>
                {/* <TableRow ><TableCell style={{backgroundColor:"#F1F3F7"}}></TableCell></TableRow> */}
              </>
              //   </Grid>
            ))}
          </TableBody>


        </Table>
        {props.lastPage && (
          <Grid container>
            {/* <Grid item xs={6}>
                          <PerPage
                            limit={limit}
                            changeLimit={(limit) => changeLimit(limit)}
                          />
                        </Grid> */}
            {data.length === 0 ? (
              <Typography
                style={{ padding: 10, width: "100%", textAlign: "center" }}
              >
                There is no data to show
              </Typography>
            ) : (
              <Grid item xs={12}>
                <Pagination
                  // renderItem={(item) => (
                  //   <PaginationItem
                  //     component={Link}
                  //     to={`${route}?page=${item.page}`}
                  //     {...item}
                  //   />
                  // )}
                  size="small"
                  // color='standard'
                  onChange={(e, page) => onChange(page)}
                  defaultPage={parseInt(currentPage)}
                  className={classes.pagination}
                  count={props.lastPage}
                />
              </Grid>
            )}
          </Grid>
        )}
      </TableContainer>
    </Card>
  );
  // }
};

export default DataTable;
