import { gql } from "@apollo/client";

export const FETCH_USERS = gql`
query($page: Int!, $limit: Int, $role: String){
    usersList(page: $page, limit: $limit, role: $role) {
        metadata {
            total
            page
            totalPages
        }
        data {
          firebaseToken
          os
          img
          firstName
          lastName
          nickname
          mobile
          whatsApp
          gender
          email
          emailVerified
          country
          state
          city
          location
          birtday
          age
          weight
          height
          description
          role
          createdAt
          plays {
              title
              image
          }
        } 
    }
  }
`;