import { Grid, Typography } from "@mui/material";
import { HeaderTitles } from "../components/headertitles/HeaderTitle";

export const HeaderLanguages = () => {
    return (
        <Grid
            xs={11}
            style={{
                borderRadius: "14px",
                backgroundColor: "#EAEEFA",
                margin: "auto",
                marginTop: 10,
            }}
        >
            <Grid
                xs={12}
                container
                style={{
                    paddingTop: "13px",
                    paddingBottom: "14px",
                }}
            >
                <Grid xs={3.15} style={{ paddingLeft: "25px" }}>
                    <HeaderTitles text={"Title"} />
                </Grid>
                <Grid xs={3}>
                    <HeaderTitles text={"Icon"} />
                </Grid>
                <Grid xs={3.7}>
                    <HeaderTitles text={"Show Translate"} />
                </Grid>

                <Grid container xs={1.9} style={{ backgroundColor: "none" }}>
                    <div
                        style={{
                            width: "50%",
                        }}
                    >
                        <HeaderTitles text={"Edit"} />
                    </div>
                    <div
                        style={{
                            width: "38%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <HeaderTitles text={"Delete"} />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};
