

const NidrPic = ()=>{
    return(<svg id="Group_1698" data-name="Group 1698" xmlns="http://www.w3.org/2000/svg" width="206.066" height="160.521" viewBox="0 0 206.066 160.521">
    <rect id="Rectangle_1241" data-name="Rectangle 1241" width="79.065" height="22.498" rx="11.249" transform="translate(63.186 78.422)" fill="#3b3bff"/>
    <rect id="Rectangle_1242" data-name="Rectangle 1242" width="79.065" height="22.498" rx="11.249" transform="translate(63.186 0)" fill="#3b3bff"/>
    <rect id="Rectangle_1243" data-name="Rectangle 1243" width="52.067" height="22.498" rx="11.249" transform="translate(63.186 38.568)" fill="#3b3bff"/>
    <circle id="Ellipse_325" data-name="Ellipse 325" cx="11.249" cy="11.249" r="11.249" transform="translate(119.753 38.568)" fill="#f90"/>
    <g id="Group_1697" data-name="Group 1697" transform="translate(0 119.124)">
      <path id="Path_954" data-name="Path 954" d="M125.5,521.967q-1.8-11.634-14.527-11.634a15.478,15.478,0,0,0-10.96,4.306,13.612,13.612,0,0,0-4.531,10.478,14.65,14.65,0,0,0,14.656,14.528,25.153,25.153,0,0,0,9.771-1.928q5.4-2.314,6.878-6.235l4.307,4.178a17.236,17.236,0,0,1-8.421,7.585,26.9,26.9,0,0,1-11.7,2.507,21.808,21.808,0,0,1-15.4-5.946A20.066,20.066,0,0,1,95.6,510.4a21.652,21.652,0,0,1,15.363-6.043q9.642.065,15.106,4.371,6.492,5.078,6.492,15.749,0,1.287-.064,2.7H101.647v-5.142h23.784Z" transform="translate(-89.177 -504.354)" fill="#3b3bff"/>
      <path id="Path_955" data-name="Path 955" d="M267.264,521.967v23.205H260.9V522.289a12.013,12.013,0,0,0-3.223-8.485,11.58,11.58,0,0,0-16.5-.032,11.978,11.978,0,0,0-3.223,8.517v22.884H231.4V504.933h6.557v4.178a24.741,24.741,0,0,1,5.6-3.471A15.723,15.723,0,0,1,250,504.354a16.421,16.421,0,0,1,12.24,5.175A17.219,17.219,0,0,1,267.264,521.967Z" transform="translate(-184.472 -504.354)" fill="#3b3bff"/>
      <path id="Path_956" data-name="Path 956" d="M383.276,516.246q-5.464-5.913-12.149-5.914a13.936,13.936,0,0,0-10.285,4.371,14.325,14.325,0,0,0-4.307,10.413,13.93,13.93,0,0,0,4.307,10.253,14.086,14.086,0,0,0,10.285,4.275,14.65,14.65,0,0,0,8.26-2.443,13.229,13.229,0,0,0,5.239-6.62L389,534.759q-5.85,10.992-16.97,10.992a21.875,21.875,0,0,1-15.363-5.656,19.342,19.342,0,0,1-6.428-14.978,19.863,19.863,0,0,1,6.107-14.784,20.669,20.669,0,0,1,15.042-5.979q10.991,0,16.97,8.421Z" transform="translate(-264.102 -504.354)" fill="#3b3bff"/>
      <path id="Path_957" data-name="Path 957" d="M493.726,545.75a21.7,21.7,0,0,1-15.3-5.978A19.241,19.241,0,0,1,472,525.116a19.441,19.441,0,0,1,6.428-14.72,21.575,21.575,0,0,1,15.3-6.043,21.881,21.881,0,0,1,15.427,6.043,19.355,19.355,0,0,1,6.492,14.72,19.156,19.156,0,0,1-6.492,14.656A22.007,22.007,0,0,1,493.726,545.75Zm0-35.418a15.367,15.367,0,0,0-10.928,4.306,13.865,13.865,0,0,0-4.564,10.478,13.668,13.668,0,0,0,4.628,10.349,15.536,15.536,0,0,0,10.863,4.178,15.734,15.734,0,0,0,10.959-4.178,14.029,14.029,0,0,0,.065-20.827A15.706,15.706,0,0,0,493.726,510.333Z" transform="translate(-345.69 -504.354)" fill="#3b3bff"/>
      <path id="Path_958" data-name="Path 958" d="M613.911,506.3h7.751l8.678,13.306,8.678-13.306h7.828L633.682,526.37v20.109h-6.557V526.447Z" transform="translate(-440.779 -505.66)" fill="#3b3bff"/>
    </g>
  </svg>
  )
}

export default NidrPic;