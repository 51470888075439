import { gql } from "@apollo/client";

export const ACTIVITY_LIST = gql`
  query($ActivityFilter: ActivityFilter)  {
    activityList(filter: $ActivityFilter){
      userId{
        firstName
        lastName
        os
        country
        city
        createdAt
      }
      ip
      action
      screen
      createdAt
    }
  }
`;
export const ADD_USERS_ACTIVITY = gql`
  mutation {
    addActivity(input: { action: "test Click", screen: "first screen" }) {
      message
    }
  }
`;
