import * as React from 'react';
  import {
    ApolloClient,
    InMemoryCache,    
    ApolloProvider
  } from "@apollo/client";

import Avatar from '@mui/material/Avatar';

import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { baseurl } from './BaseUrl';
import MainRoute from './Routes/MainRoute';
import { ThemeProvider ,createTheme} from '@mui/material/styles';

 function App() {

  const httpLink = createUploadLink({

    uri:`${baseurl}api`,

    });    
    
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: localStorage.getItem("@token") ,
        }
      }
    });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
  const theme = createTheme({
    
    palette: {
        
        primary: {
          main: '#FED699',
        },
        secondary: {
          main: '#edf2ff',
        },error:{
          main:"#D80027"
        }
      },
    });
  return (
   
    <ApolloProvider client={client} >
      <ThemeProvider theme={theme}>
      <MainRoute/>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
