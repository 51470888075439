export const Check = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.35"
            height="19.35"
            viewBox="0 0 19.35 19.35"
        >
            <path
                id="Path_11867"
                data-name="Path 11867"
                d="M15.318,0H4.031A4.036,4.036,0,0,0,0,4.031V15.319A4.036,4.036,0,0,0,4.031,19.35H15.318a4.036,4.036,0,0,0,4.031-4.031V4.031A4.036,4.036,0,0,0,15.318,0Zm2.419,15.319a2.419,2.419,0,0,1-2.419,2.419H4.031a2.419,2.419,0,0,1-2.419-2.419V4.031A2.419,2.419,0,0,1,4.031,1.612H15.318a2.419,2.419,0,0,1,2.419,2.419Z"
                fill="#d0dbe5"
            />
        </svg>
    );
};
export const CircleRemove=()=>{
    return  <svg
    id="circle-remove"
    xmlns="http://www.w3.org/2000/svg"
    width="18.622"
    height="18.622"
    viewBox="0 0 18.622 18.622"
>
    <path
        id="circle-remove-2"
        data-name="circle-remove"
        d="M9.311,0a9.311,9.311,0,1,0,9.311,9.311A9.338,9.338,0,0,0,9.311,0Zm4.074,11.755-1.629,1.629L9.311,10.94,6.867,13.385,5.237,11.755,7.682,9.311,5.237,6.867,6.867,5.237,9.311,7.682l2.444-2.444,1.629,1.629L10.94,9.311Z"
        fill="#d0dbe5"
    />
</svg>
}
