export const  MailIconn =()=>{
    return(<svg   width="19.13" height="17.149" viewBox="0 0 19.13 17.149">
    <defs>
      <clipPath id="clip-path">
        <rect width="19.13" height="17.149" fill="none"/>
      </clipPath>
    </defs>
    <g id="Icon_Inbox" data-name="Icon Inbox" clip-path="url(#clip-path)">
      <path id="Path_358" data-name="Path 358" d="M17.934,1H1.2A1.21,1.21,0,0,0,0,2.225v14.7a1.21,1.21,0,0,0,1.2,1.225H17.934a1.21,1.21,0,0,0,1.2-1.225V2.225A1.21,1.21,0,0,0,17.934,1Zm-1.2,14.7H2.391V8.01l6.581,3.852a1.168,1.168,0,0,0,1.186,0L16.739,8.01Zm0-10.51L9.565,9.39l-7.174-4.2V3.45H16.739Z" transform="translate(0 -1)" fill="#d0dbe5"/>
    </g>
  </svg>
  
  )
  }
  export const PadLock =()=>{
    return(<svg id="padlock" xmlns="http://www.w3.org/2000/svg" width="16.327" height="20.082" viewBox="0 0 16.327 20.082">
    <g id="Group_27" data-name="Group 27" transform="translate(0)">
      <path id="Path_17" data-name="Path 17" d="M47.171,20.082H60.556a1.475,1.475,0,0,0,1.471-1.471v-8a1.475,1.475,0,0,0-1.471-1.471H59.521V5.657a5.657,5.657,0,1,0-11.315,0V9.141H47.171A1.475,1.475,0,0,0,45.7,10.612v8A1.475,1.475,0,0,0,47.171,20.082ZM49.213,5.657a4.651,4.651,0,1,1,9.3,0V9.141h-9.3Zm-2.506,4.955a.466.466,0,0,1,.464-.464H60.556a.466.466,0,0,1,.464.464v8a.466.466,0,0,1-.464.464H47.171a.466.466,0,0,1-.464-.464Z" transform="translate(-45.7)" fill="#707070"/>
      <path id="Path_18" data-name="Path 18" d="M196.55,309.8a2.05,2.05,0,1,0-2.05-2.05A2.053,2.053,0,0,0,196.55,309.8Zm0-3.089a1.044,1.044,0,1,1-1.044,1.044A1.045,1.045,0,0,1,196.55,306.711Z" transform="translate(-188.387 -293.141)" fill="#939393"/>
    </g>
  </svg>)
}
  