import { Avatar, Grid, Typography } from "@mui/material"
import { Disableicon } from "../../components/icons.js/actionicons"
import { useNavigate } from "react-router-dom"
import { Customavatar } from "../../components/avatar/customavatar";
export const Eventcomments = ({action}) => {
    
    return (
        <Grid
            md={11.96}
            container
            style={{ margin: "auto", paddingTop: "15px" }}
        >

            <Grid md={1.55} style={{ alignItems: "center", paddingTop: 2 }}>
                <Customavatar link="erfan"/>
            </Grid>
            <Grid md={6.15}>
                <Grid style={{ width: "90%" }} >
                    <Typography
                        style={{
                            fontSize: "13px",
                            fontWeight: "bold", color: "#31515C"
                        }}
                    >
                        Fariba
                    </Typography>
                    <Typography

                        style={{
                            fontSize: "11px",
                            fontFamily: "Montserrat-Regular !important-Regular !important", width: "100%", color: "#31515C"
                        }}
                    >
                        In publishing and graphic design, Lorem
                        ipsum is a placeholder text commonly
                        used to demonstrate the visual form.
                    </Typography>
                </Grid>
            </Grid>
            <Grid md={1.6} style={{ display: "flex", alignItems: "center" }}>

                <Typography
                    style={{
                        fontSize: "10px",
                        fontFamily: "Montserrat-Regular !important-Regular !important",
                        color: "#BAC4CC",
                    }}
                >
                    1400/11/21
                </Typography>
            </Grid>
            <Grid md={2.25} style={{ display: "flex", alignItems: "center" }}    >

                <Typography
                    style={{
                        fontSize: "10px",
                        fontFamily: "Montserrat-Regular !important-Regular !important",
                        color: "#BAC4CC"
                    }}
                >
                    09126283654
                </Typography>
            </Grid>

            <Grid xs={0.35} style={{ display: "flex", alignItems: "center" }}   >

                <Disableicon action={action} />

            </Grid>
        </Grid>)
}