import { Grid, Typography, TextField } from "@mui/material";
// import { AutoCompletes } from "../../../../../Components1/MainDashboard/Container/Autocompletes/AutoCompletes";
import { Filtration } from "../../../../../Components1/MainDashboard/Container/Notiffication/Filtraition";

export const Sendmessage = (props) => {
    return (
        <Grid xs={12}>
            <Grid xs={12} container style={{ marginTop: "35px" }}>
            </Grid>
            <Grid xs={12}>
                <Typography
                    style={{
                        fontSize: "1vw",
                        color: "#31515C",
                        fontFamily: "Montserrat-Regular !important-Regular !important",
                        marginTop: "8px",
                        marginBottom: 2,
                    }}
                >
                    {props.title}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <TextField
                    sx={{
                        backgroundColor: "#fff",
                        borderWidth: 2,
                        paddingTop: "0px",
                        fontFamily: "Montserrat-Regular !important-Regular !important",
                        letterSpacing: 10,
                        fontStyle: "normal",

                        "& label.Mui-focused": {
                            color: "#3b3bff",
                        },
                        "& .MuiInput-underline:after": {
                            borderBottomColor: "yellow",
                            borderRadius: "5px",
                        },

                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#DBDBDB",
                                borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                                borderColor: "#3b3bff",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#3b3bff",
                                borderRadius: "5px",
                                borderWidth: 0.5,
                            },
                            "&MuiInputBase-input": {
                                backgroundColor: "red",
                            },
                            "&.MuiInputBase-input": {},
                            "&.MuiOutlinedInput-input": {
                                backgroundColor: "red",
                            },
                            "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                    padding: "0px",
                                },
                            "&.MuiOutlinedInput-root": {
                                padidng: "0px",
                                fontSize: "16px",
                                color: "#31515C",
                                padding: 0,
                                letterSpacing: "0px",
                                fontFamily: "Montserrat-Regular !important-Regular !important",
                                paddingLeft: ".5vw",
                                height: "5.5vh",

                                /* THIS THE SIZE OF THE TEXTFEILD  */
                            },
                        },
                    }}
                    fullWidth
                ></TextField>
            </Grid>
            <Grid xs={12}>
                <Typography
                    style={{
                        fontSize: "1vw",
                        color: "#31515C",
                        marginBlock: "10px",
                    }}
                >
                    Description
                </Typography>
            </Grid>
            <Grid xs={12}>
                <TextField
                    multiline
                    inputProps={{
                        style: {
                            margin: 0,
                            height: "10vh",
                        },
                    }}
                    sx={{
                        backgroundColor: "#fff",
                        borderWidth: 2,
                        paddingTop: "0px",
                        fontFamily: "Montserrat-Regular !important-Regular !important",
                        letterSpacing: 10,
                        fontStyle: "normal",
                        "& .MuiInput-input": {
                            color: "red",
                        },
                        "& label.Mui-focused": {
                            color: "#3b3bff",
                        },
                        "& .MuiInput-underline:after": {
                            borderBottomColor: "yellow",
                            borderRadius: "5px",
                        },

                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#DBDBDB",
                                borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                                borderColor: "#3b3bff",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#3b3bff",
                                borderRadius: "5px",
                                borderWidth: 0.5,
                            },
                            "&MuiInputBase-input": {
                                backgroundColor: "red",
                            },
                            "&.MuiInputBase-input": {},
                            "&.MuiOutlinedInput-input": {
                                backgroundColor: "red",
                            },
                            "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                    padding: "0px",
                                },
                            "&.MuiOutlinedInput-root": {
                                padidng: "0px",
                                fontSize: "16px",
                                color: "#31515C",
                                padding: 0,
                                letterSpacing: "0px",
                                fontFamily: "Montserrat-Regular !important-Regular !important",
                                height: "11vh",
                                padding: ".5vw !important",

                                /* THIS THE SIZE OF THE TEXTFEILD  */
                            },
                        },
                    }}
                    fullWidth
                ></TextField>
            </Grid>
            <Grid
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "48px",
                }}
            >
                <div
                    onClick={() => {
                        alert("send");
                    }}
                    style={{
                        height: "2.3vw",
                        backgroundColor: "#3b3bff",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        width: "6.2vw",
                        borderRadius: "5px",
                    }}
                >
                    <span style={{ fontSize: "0.85vw", color: "white" }}>
                        Send
                    </span>
                </div>
            </Grid>
        </Grid>
    );
};
