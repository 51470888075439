  import React, { useEffect, useState } from 'react';
  import ReactDOM from 'react-dom';
  import './index.css';
  import App from './App';
  import reportWebVitals from './reportWebVitals';  
  
 
  ReactDOM.render(
    <App/>,
    document.getElementById('root')
  );

  
  reportWebVitals();
