import { Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tablecell } from "../components/tablecell/Tablecell";
import "./../../../MainDashboard/Container/components/icons.js/icon.css";

export const Language = (props) => {
    const nav = useNavigate();
    return (
        <Grid
            xs={11}
            sx={{
                "&:hover": {
                    background: "#EAEEFA",
                },
            }}
            style={{ borderRadius: "14px", margin: "auto", marginTop: 10 }}
        >
            <Grid
                xs={12}
                container
                style={{
                    paddingTop: "10px",
                    paddingBottom: "12px",
                }}
            >
                <Grid
                    xs={3.15}
                    style={{ alignItems: "center", display: "flex" }}
                >
                    <div
                        style={{
                            paddingLeft: "25px",
                        }}
                    >
                        <Tablecell text={props.item.title} />
                    </div>
                </Grid>
                <Grid xs={3} style={{ alignItems: "center", display: "flex" }}>
                    {props.item.icon}
                </Grid>
                <Grid
                    xs={3.7}
                    style={{ alignItems: "center", display: "flex" }}
                >
                    <svg
                        className="translate"
                        onClick={() => {
                            nav(`/Dashboard/language/${props.item.id}`);
                        }}
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 26.509 24.736"
                    >
                        <g id="language-outline" transform="translate(1.5 1.5)">
                            <path
                                id="Path_11854"
                                data-name="Path 11854"
                                d="M48,66.658H63.95M55.975,64v2.658m4.431,18.608,5.317-12.406,5.317,12.406m-9-3.544h7.366M60.921,66.658A27.218,27.218,0,0,1,56.363,75.8a27.859,27.859,0,0,1-6.591,5.926"
                                transform="translate(-48 -64)"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                            <path
                                id="Path_11855"
                                data-name="Path 11855"
                                d="M135.089,184.861a24.808,24.808,0,0,1-3.987-4.154A34.223,34.223,0,0,1,128,176"
                                transform="translate(-123.57 -169.797)"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                        </g>
                    </svg>
                </Grid>

                <Grid container xs={1.9} style={{ backgroundColor: "none" }}>
                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <svg
                            className="svgicon"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={props.edit}
                            viewBox="0 0 20.337 20.337"
                            style={{ height: "18px" }}
                        >
                            <path
                                id="Path_110"
                                data-name="Path 110"
                                d="M10.364,3.911,3.129,11.342,0,20.337,9,17.4l7.235-7.235Zm9.191-.587L17.013.782a2.371,2.371,0,0,0-3.52,0L11.342,2.933,17.209,9l2.347-2.347a2.479,2.479,0,0,0,.782-1.76A2.414,2.414,0,0,0,19.555,3.324Z"
                                transform="translate(0 0)"
                            />
                        </svg>
                    </div>
                    <div
                        style={{
                            width: "38%",
                            justifyContent: "center",
                            fontWeight: "unset",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            color: "#31515C",
                            fontSize: "0.7vw",
                            display: "flex",
                        }}
                    >
                        <svg
                            onClick={() => {
                                props.delete(props.item.id);
                            }}
                            id="Layer_1"
                            className="svgicon"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="22.054"
                            viewBox="0 0 24.058 22.054"
                        >
                            <path
                                id="Path_9384"
                                data-name="Path 9384"
                                d="M23.054,2H18.044V1a1,1,0,0,0-1-1H7.018a1,1,0,0,0-1,1V2H1A1,1,0,1,0,1,4.011h2V19.047a3.007,3.007,0,0,0,3.007,3.007H18.044a3.007,3.007,0,0,0,3.007-3.007V4.011h2a1,1,0,0,0,0-2.007ZM19.047,19.047a1,1,0,0,1-1,1H6.015a1,1,0,0,1-1-1V4.011H19.047Z"
                                transform="translate(0 0)"
                            />
                            <path
                                id="Path_9385"
                                data-name="Path 9385"
                                d="M13.668,21.1a1,1,0,0,0,1-1V12.083a1,1,0,0,0-2.007,0V20.1a1,1,0,0,0,1,1Z"
                                transform="translate(-4.647 -4.065)"
                            />
                            <path
                                id="Path_9386"
                                data-name="Path 9386"
                                d="M23.168,21.1a1,1,0,0,0,1-1V12.083a1,1,0,1,0-2.007,0V20.1A1,1,0,0,0,23.168,21.1Z"
                                transform="translate(-8.132 -4.065)"
                            />
                        </svg>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};
