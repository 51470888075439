import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
export function AlertDialog(props) {
    return (
        <Dialog
            open={props.open}
            sx={{ "& .MuiPaper-root": { borderRadius: "18px" } }}
            style={{ borderRadius: "200px" }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box
                style={{
                    width: "481px",
                    height: "282px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div style={{ width: "100%", margin: "auto" }}>
                    <div
                        style={{
                            display: "flex",
                            margin: "auto",
                            justifyContent: "center",
                        }}
                    >
                        <WarningAmberIcon
                            color="error"
                            style={{ width: "50px", height: "120px" }}
                        />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            style={{
                                color: "#161616",
                                marginBottom: 20,
                                fontFamily: "Montserrat-Regular !important",
                                fontSize: "15px",
                                fontWeight: "unset",
                            }}
                        >
                            Are you sure you want to delete this item?
                        </Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            id="cancell"
                            onClick={props.close}
                            style={{
                                background: "#F1F3F5",
                                border: "1.5px solid #D0DBE5",
                                color: "#BAC4CC",
                                fontFamily: "Montserrat-Regular !important",
                                width: "127px",
                                height: "42px",
                                borderRadius: "10px",
                                justifyContent: "center",
                                textAlign: "center",
                                textJustify: "center",
                                textTransform: "none",
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            style={{
                                marginLeft: 25,
                                background: "#FF9900",
                                fontFamily: "Montserrat-Regular !important",
                                width: "128px",
                                color: "white",
                                height: "42px",
                                borderRadius: "10px",
                                justifyContent: "center",
                                textAlign: "center",
                                textJustify: "center",
                                textTransform: "none",
                            }}
                            onClick={props.action}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </Box>
        </Dialog>
    );
}
