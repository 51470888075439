export const actions = [
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
            >
                <defs>
                    <filter
                        id="Path_11867"
                        x="0"
                        y="0"
                        width="60"
                        height="60"
                        filterUnits="userSpaceOnUse"
                    >
                        <feOffset dy="3" input="SourceAlpha"></feOffset>
                        <feGaussianBlur
                            stdDeviation="3"
                            result="blur"
                        ></feGaussianBlur>
                        <feFlood flood-opacity="0.161"></feFlood>
                        <feComposite operator="in" in2="blur"></feComposite>
                        <feComposite in="SourceGraphic"></feComposite>
                    </filter>
                </defs>
                <g
                    id="Group_18086"
                    data-name="Group 18086"
                    transform="translate(-1134 -883)"
                >
                    <g
                        transform="matrix(1, 0, 0, 1, 1134, 883)"
                        filter="url(#Path_11867)"
                    >
                        <path
                            id="Path_11867-2"
                            data-name="Path 11867"
                            d="M21,0A21,21,0,1,1,6.643,36.326,20.775,20.775,0,0,1,0,21,21,21,0,0,1,21,0Z"
                            transform="translate(9 6)"
                            fill="#fff"
                        ></path>
                    </g>
                    <g id="fi-rr-apps" transform="translate(1151.713 898)">
                        <path
                            id="Path_11493"
                            data-name="Path 11493"
                            d="M7.167,0H4.1A4.1,4.1,0,0,0,0,4.1V7.167a4.1,4.1,0,0,0,4.1,4.1H7.167a4.1,4.1,0,0,0,4.1-4.1V4.1A4.1,4.1,0,0,0,7.167,0ZM9.215,7.167A2.048,2.048,0,0,1,7.167,9.215H4.1A2.048,2.048,0,0,1,2.048,7.167V4.1A2.048,2.048,0,0,1,4.1,2.048H7.167A2.048,2.048,0,0,1,9.215,4.1Z"
                            transform="translate(0 0)"
                            fill="#3b3bff"
                        ></path>
                        <path
                            id="Path_11494"
                            data-name="Path 11494"
                            d="M20.167,0H17.1A4.1,4.1,0,0,0,13,4.1V7.167a4.1,4.1,0,0,0,4.1,4.1h3.072a4.1,4.1,0,0,0,4.1-4.1V4.1A4.1,4.1,0,0,0,20.167,0Zm2.048,7.167a2.048,2.048,0,0,1-2.048,2.048H17.1a2.048,2.048,0,0,1-2.048-2.048V4.1A2.048,2.048,0,0,1,17.1,2.048h3.072A2.048,2.048,0,0,1,22.215,4.1Z"
                            transform="translate(0.311 0)"
                            fill="#3b3bff"
                        ></path>
                        <path
                            id="Path_11495"
                            data-name="Path 11495"
                            d="M7.167,13H4.1A4.1,4.1,0,0,0,0,17.1v3.072a4.1,4.1,0,0,0,4.1,4.1H7.167a4.1,4.1,0,0,0,4.1-4.1V17.1A4.1,4.1,0,0,0,7.167,13Zm2.048,7.167a2.048,2.048,0,0,1-2.048,2.048H4.1a2.048,2.048,0,0,1-2.048-2.048V17.1A2.048,2.048,0,0,1,4.1,15.048H7.167A2.048,2.048,0,0,1,9.215,17.1Z"
                            transform="translate(0 0.311)"
                            fill="#3b3bff"
                        ></path>
                        <path
                            id="Path_11496"
                            data-name="Path 11496"
                            d="M20.167,13H17.1A4.1,4.1,0,0,0,13,17.1v3.072a4.1,4.1,0,0,0,4.1,4.1h3.072a4.1,4.1,0,0,0,4.1-4.1V17.1A4.1,4.1,0,0,0,20.167,13Zm2.048,7.167a2.048,2.048,0,0,1-2.048,2.048H17.1a2.048,2.048,0,0,1-2.048-2.048V17.1A2.048,2.048,0,0,1,17.1,15.048h3.072A2.048,2.048,0,0,1,22.215,17.1Z"
                            transform="translate(0.311 0.311)"
                            fill="#3b3bff"
                        ></path>
                    </g>
                </g>
            </svg>
        ),

        name: "Add an Existing File",
        type: "file",
    },
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
            >
                <g
                    id="Group_18087"
                    data-name="Group 18087"
                    transform="translate(-1134 -935)"
                >
                    <g
                        transform="matrix(1, 0, 0, 1, 1134, 935)"
                        filter="url(#Ellipse_1261)"
                    >
                        <circle
                            id="Ellipse_1261-2"
                            data-name="Ellipse 1261"
                            cx="21"
                            cy="21"
                            r="21"
                            transform="translate(9 6)"
                            fill="#fff"
                        />
                    </g>
                </g>
                <path
                    id="fi-rr-volleyball"
                    d="M13.531-.047A13.857,13.857,0,1,0,27.387,13.81,13.857,13.857,0,0,0,13.531-.047ZM24.912,12.061v0a11.516,11.516,0,0,1-3.387,1.419A20.984,20.984,0,0,0,17.986,3.161,11.549,11.549,0,0,1,24.912,12.061Zm-11.385-9.8c.292,0,.576.022.864.044a18.709,18.709,0,0,1,4.842,11.471,11.494,11.494,0,0,1-4.612-.8A13.871,13.871,0,0,0,9.492,3.006,11.438,11.438,0,0,1,13.527,2.261ZM7.171,4.181A11.551,11.551,0,0,1,10,6.794a21.045,21.045,0,0,0-7.734,9.454A11.48,11.48,0,0,1,7.171,4.181Zm-3.61,15.38A18.748,18.748,0,0,1,11.193,8.775a11.477,11.477,0,0,1,1.145,4.486A13.873,13.873,0,0,0,6.9,23.211,11.594,11.594,0,0,1,3.561,19.561Zm9.966,5.759a11.469,11.469,0,0,1-4.349-.858,11.509,11.509,0,0,1,.775-4.352A20.638,20.638,0,0,0,22.03,21.55,11.487,11.487,0,0,1,13.527,25.32ZM10.99,18.045a11.484,11.484,0,0,1,2.731-2.94,13.8,13.8,0,0,0,5.128,1.007,13.917,13.917,0,0,0,6.166-1.441,11.421,11.421,0,0,1-1.087,4.061A18.742,18.742,0,0,1,10.99,18.045Z"
                    transform="translate(16.569 14.047)"
                    fill="#3b3bff"
                />
            </svg>
        ),
        name: "Add New Translate",
    },
    // { icon: <PrintIcon />, name: 'Print' },
    // { icon: <ShareIcon />, name: 'Share' },
];