export const EncoyIcon = () => {
    return (
        <svg width="701" height="200" viewBox="0 0 701 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M322.127 127.023V184.024C322.127 189.134 317.985 193.276 312.875 193.276C307.766 193.276 303.624 189.134 303.624 184.024V127.871C303.624 118.352 300.46 110.247 294.228 103.65C287.997 97.0534 280.039 93.7548 270.261 93.7548C260.482 93.7548 252.524 97.0533 246.293 103.556C240.061 110.059 236.897 118.258 236.897 127.871V183.737C236.897 189.005 232.627 193.276 227.358 193.276C222.09 193.276 217.819 189.005 217.819 183.737V87.8381C217.819 82.5697 222.09 78.2989 227.358 78.2989H232.953C235.131 78.2989 236.897 80.065 236.897 82.2436C236.897 85.5188 240.682 87.393 243.422 85.5992C247.011 83.2503 250.277 81.508 253.196 80.3723C258.852 77.9219 265.084 76.6967 271.89 76.6967C285.888 76.6967 297.68 81.5974 307.458 91.493C317.237 101.389 322.127 113.263 322.127 127.023Z" fill="#3939F8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M328.262 127.023V184.024C328.262 192.523 321.373 199.412 312.875 199.412C304.377 199.412 297.488 192.523 297.488 184.024V127.871C297.488 119.849 294.876 113.272 289.768 107.864C284.785 102.589 278.487 99.8906 270.26 99.8906C262.028 99.8906 255.712 102.594 250.723 107.801C245.666 113.078 243.033 119.72 243.033 127.871V183.737C243.033 192.394 236.015 199.412 227.358 199.412C218.701 199.412 211.683 192.394 211.683 183.737V87.8381C211.683 79.1811 218.701 72.1632 227.358 72.1632H232.952C237.363 72.1632 241.112 74.9956 242.479 78.9406C245.395 77.1733 248.196 75.743 250.862 74.6966C257.336 71.9104 264.373 70.561 271.89 70.561C287.514 70.561 300.881 76.1085 311.823 87.1802C322.727 98.2144 328.262 111.635 328.262 127.023ZM253.195 80.3723C250.277 81.508 247.011 83.2503 243.422 85.5993C240.682 87.393 236.897 85.5188 236.897 82.2436C236.897 80.065 235.131 78.2989 232.952 78.2989H227.358C222.09 78.2989 217.819 82.5698 217.819 87.8381V183.737C217.819 189.005 222.09 193.276 227.358 193.276C232.626 193.276 236.897 189.005 236.897 183.737V127.871C236.897 118.258 240.061 110.059 246.293 103.556C252.524 97.0534 260.481 93.7549 270.26 93.7549C280.039 93.7549 287.996 97.0534 294.228 103.65C300.46 110.247 303.623 118.352 303.623 127.871V184.024C303.623 189.134 307.765 193.276 312.875 193.276C317.984 193.276 322.127 189.134 322.127 184.024V127.023C322.127 113.263 317.237 101.389 307.458 91.493C297.679 81.5975 285.887 76.6968 271.89 76.6968C265.083 76.6968 258.852 77.922 253.195 80.3723Z" fill="#3939F8"/>
<path d="M450.435 105.691C446.681 108.216 441.701 107.571 438.243 104.652C429.4 97.1883 419.745 93.4306 409.33 93.4306C397.957 93.4306 388.181 97.5211 380.004 105.702C371.826 113.883 367.69 123.737 367.69 135.079C367.69 146.328 371.826 155.904 380.004 163.992C388.181 171.987 397.957 176.077 409.33 176.077C417.977 176.077 425.873 173.753 432.829 169.198C436.585 166.77 439.755 163.867 442.337 160.489C445.612 156.205 452.129 154.656 456.036 158.374C458.484 160.703 459.104 164.408 457.301 167.266C446.36 184.606 431.167 193.276 411.868 193.276C394.385 193.276 379.816 187.977 368.066 177.379C355.847 166.409 349.738 152.278 349.738 135.172C349.738 118.624 355.565 104.772 367.127 93.5235C378.688 82.2747 392.975 76.6967 409.988 76.6967C427.364 76.6967 441.481 82.1759 452.23 93.1343C455.866 96.8402 454.743 102.795 450.435 105.691Z" fill="#3939F8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M429.499 164.044C432.693 161.98 435.331 159.551 437.463 156.763C440.038 153.394 443.84 151.121 447.856 150.394C451.938 149.654 456.64 150.479 460.266 153.929C464.638 158.09 465.992 164.99 462.49 170.54C450.504 189.536 433.417 199.412 411.868 199.412C393.037 199.412 376.956 193.658 363.962 181.94C350.382 169.746 343.602 153.924 343.602 135.172C343.602 116.998 350.078 101.551 362.848 89.1259C375.631 76.6884 391.486 70.561 409.988 70.561C428.768 70.561 444.547 76.5397 456.61 88.8376C463.413 95.7721 460.758 106.145 453.859 110.783C447.473 115.076 439.49 113.733 434.286 109.341C426.413 102.696 418.127 99.5664 409.33 99.5664C399.615 99.5664 391.392 102.988 384.343 110.04C377.288 117.098 373.826 125.403 373.826 135.079C373.826 144.609 377.248 152.631 384.306 159.617C391.367 166.515 399.611 169.942 409.33 169.942C416.824 169.942 423.544 167.944 429.467 164.065L429.499 164.044ZM380.004 163.992C371.826 155.904 367.69 146.328 367.69 135.079C367.69 123.737 371.826 113.883 380.004 105.702C388.181 97.5211 397.957 93.4306 409.33 93.4306C419.745 93.4306 429.4 97.1883 438.243 104.652C441.701 107.571 446.681 108.216 450.435 105.691C454.743 102.795 455.866 96.8402 452.23 93.1344C441.481 82.176 427.364 76.6968 409.988 76.6968C392.975 76.6968 378.688 82.2747 367.127 93.5236C355.565 104.772 349.738 118.624 349.738 135.172C349.738 152.278 355.847 166.409 368.066 177.379C379.816 187.977 394.385 193.276 411.868 193.276C431.167 193.276 446.36 184.606 457.301 167.266C459.104 164.408 458.484 160.703 456.036 158.374C452.129 154.656 445.612 156.205 442.337 160.489C439.755 163.867 436.585 166.77 432.829 169.198C425.873 173.753 417.977 176.077 409.33 176.077C397.957 176.077 388.181 171.987 380.004 163.992Z" fill="#3939F8"/>
<path d="M542.735 193.276C526.145 193.276 511.778 187.698 499.729 176.449C487.68 165.2 481.656 151.441 481.656 135.172C481.656 118.903 487.68 105.051 499.729 93.7095C511.778 82.3677 526.052 76.6967 542.735 76.6967C559.511 76.6967 573.877 82.3677 586.112 93.7095C598.253 105.051 604.371 118.903 604.371 135.172C604.371 151.441 598.253 165.2 586.112 176.449C573.97 187.698 559.511 193.276 542.735 193.276ZM542.735 93.5236C530.779 93.5236 520.583 97.614 512.056 105.702C503.529 113.79 499.266 123.645 499.266 135.265C499.359 146.886 503.715 156.554 512.242 164.457C520.769 172.266 530.964 176.263 542.735 176.263C554.692 176.263 564.887 172.359 573.507 164.457C582.126 156.647 586.482 146.886 586.575 135.265C586.668 123.645 582.404 113.79 573.692 105.702C564.98 97.5211 554.692 93.5236 542.735 93.5236Z" fill="#3939F8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M590.284 89.2099L590.301 89.2257C603.649 101.695 610.507 117.156 610.507 135.172C610.507 153.196 603.643 168.572 590.282 180.95C576.98 193.274 561.022 199.412 542.736 199.412C524.619 199.412 508.749 193.263 495.543 180.934C482.269 168.542 475.521 153.167 475.521 135.172C475.521 117.185 482.263 101.724 495.524 89.2418C508.715 76.8254 524.494 70.561 542.736 70.561C561.063 70.561 576.918 76.8196 590.284 89.2099ZM573.692 105.702C564.98 97.5211 554.692 93.5236 542.736 93.5236C530.779 93.5236 520.584 97.6141 512.057 105.702C503.53 113.79 499.266 123.645 499.266 135.265C499.359 146.886 503.715 156.554 512.242 164.457C520.769 172.266 530.965 176.263 542.736 176.263C554.692 176.263 564.887 172.359 573.507 164.457C582.127 156.647 586.483 146.886 586.576 135.265C586.668 123.645 582.405 113.79 573.692 105.702ZM516.4 159.944C523.791 166.707 532.503 170.127 542.736 170.127C553.199 170.127 561.903 166.77 569.361 159.934L569.387 159.909C576.731 153.256 580.361 145.128 580.44 135.216C580.519 125.346 576.987 117.133 569.518 110.199L569.492 110.175C561.938 103.082 553.16 99.6594 542.736 99.6594C532.344 99.6594 523.662 103.151 516.279 110.154C508.964 117.092 505.408 125.336 505.402 135.241C505.487 145.119 509.103 153.176 516.4 159.944ZM499.73 176.449C511.779 187.698 526.145 193.276 542.736 193.276C559.512 193.276 573.97 187.698 586.112 176.449C598.254 165.2 604.371 151.441 604.371 135.172C604.371 118.903 598.254 105.051 586.112 93.7096C573.878 82.3677 559.512 76.6968 542.736 76.6968C526.052 76.6968 511.779 82.3677 499.73 93.7096C487.681 105.051 481.656 118.903 481.656 135.172C481.656 151.441 487.681 165.2 499.73 176.449Z" fill="#3939F8"/>
<path d="M619.622 93.7888C615.697 87.7502 620.03 79.7647 627.233 79.7647C630.316 79.7647 633.188 81.3298 634.86 83.9205L648.699 105.368C652.324 110.986 660.541 110.986 664.166 105.368L677.98 83.9589C679.667 81.3442 682.566 79.7647 685.678 79.7647C692.943 79.7647 697.318 87.8175 693.363 93.9124L667.23 134.188C666.262 135.68 665.747 137.42 665.747 139.198V184.146C665.747 189.188 661.659 193.276 656.617 193.276C651.575 193.276 647.487 189.188 647.487 184.146V139.389C647.487 137.608 646.97 135.866 646 134.373L619.622 93.7888Z" fill="#3939F8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M640.016 80.5937L653.855 102.041C655.063 103.914 657.802 103.914 659.01 102.041L672.825 80.6321C675.642 76.2663 680.482 73.6289 685.678 73.6289C697.809 73.6289 705.114 87.0751 698.51 97.2521L672.377 137.528C672.054 138.025 671.882 138.605 671.882 139.198V184.146C671.882 192.577 665.048 199.412 656.617 199.412C648.186 199.412 641.351 192.577 641.351 184.146V139.388C641.351 138.795 641.179 138.214 640.856 137.717L614.477 97.1326C607.899 87.0121 615.162 73.6289 627.233 73.6289C632.4 73.6289 637.214 76.2519 640.016 80.5937ZM627.233 79.7647C620.031 79.7647 615.697 87.7501 619.622 93.7888L646 134.373C646.971 135.866 647.487 137.608 647.487 139.388V184.146C647.487 189.188 651.575 193.276 656.617 193.276C661.659 193.276 665.747 189.188 665.747 184.146V139.198C665.747 137.42 666.262 135.68 667.23 134.188L693.363 93.9123C697.318 87.8175 692.943 79.7647 685.678 79.7647C682.566 79.7647 679.667 81.3442 677.98 83.9588L664.166 105.368C660.541 110.986 652.324 110.986 648.699 105.368L634.86 83.9204C633.188 81.3297 630.316 79.7647 627.233 79.7647Z" fill="#3939F8"/>
<path d="M0 182.538C0 173.22 7.55444 165.665 16.8733 165.665H145.724C155.043 165.665 162.597 173.22 162.597 182.538C162.597 191.857 155.043 199.412 145.724 199.412H16.8733C7.55445 199.412 0 191.857 0 182.538Z" fill="#3939F8"/>
<path d="M141.123 119.647C151.289 119.647 159.53 111.406 159.53 101.24C159.53 91.0738 151.289 82.8326 141.123 82.8326C130.957 82.8326 122.715 91.0738 122.715 101.24C122.715 111.406 130.957 119.647 141.123 119.647Z" fill="#F8304F"/>
<path d="M0 99.9794C0 90.5149 7.67247 82.8424 17.1369 82.8424H88.4869C97.9514 82.8424 105.624 90.5149 105.624 99.9794V100.913C105.624 110.378 97.9514 118.05 88.4869 118.05H17.1369C7.67247 118.05 0 110.378 0 100.913V99.9794Z" fill="#3939F8"/>
<path d="M0 17.137C0 7.67248 7.67247 0 17.1369 0H146.476C155.941 0 163.613 7.67248 163.613 17.137V18.071C163.613 27.5355 155.941 35.208 146.477 35.208H17.137C7.67248 35.208 0 27.5355 0 18.071V17.137Z" fill="#3939F8"/>
</svg>

    );
};
