import { makeStyles } from "@mui/styles"
import './icon.css'



export const Pen = ({ action }) => {

  // return <svg className="svgicon" onClick={action} id="icon_edit" xmlns="http://www.w3.org/2000/svg" width="29.337" height="21.337" viewBox="0 0 29.337 21.337">
    
  //   <path className="svgicon" id="Path_110" data-name="Path 110" d="M10.364,3.911,3.129,11.342,0,20.337,9,17.4l7.235-7.235Zm9.191-.587L17.013.782a2.371,2.371,0,0,0-3.52,0L11.342,2.933,17.209,9l2.347-2.347a2.479,2.479,0,0,0,.782-1.76A2.414,2.414,0,0,0,19.555,3.324Z" transform="translate(9 1)"  />
  // </svg>
//   return<svg id="icon_edit" className="svgicon"    xmlns="http://www.w3.org/2000/svg" width="29.337" height="21.337" viewBox="0 0 29.337 21.337">
  
//   <path id="Path_110" data-name="Path 110" d="M10.364,3.911,3.129,11.342,0,20.337,9,17.4l7.235-7.235Zm9.191-.587L17.013.782a2.371,2.371,0,0,0-3.52,0L11.342,2.933,17.209,9l2.347-2.347a2.479,2.479,0,0,0,.782-1.76A2.414,2.414,0,0,0,19.555,3.324Z" transform="translate(9 1)" />
// </svg>
return<svg xmlns="http://www.w3.org/2000/svg" onClick={action} className="svgicon" viewBox="0 0 20.337 20.337" style={{height:"18px"}}>
  <path id="Path_110" data-name="Path 110" d="M10.364,3.911,3.129,11.342,0,20.337,9,17.4l7.235-7.235Zm9.191-.587L17.013.782a2.371,2.371,0,0,0-3.52,0L11.342,2.933,17.209,9l2.347-2.347a2.479,2.479,0,0,0,.782-1.76A2.414,2.414,0,0,0,19.555,3.324Z" transform="translate(0 0)" />
</svg> 
}
export const Eye = ({ action }) => {
  return <svg className="svgicon" onClick={action} xmlns="http://www.w3.org/2000/svg" width="22" height="19.136" viewBox="0 0 24.582 19.136">
    <g id="fi-rr-eye" transform="translate(0 -0.001)">
      <path id="Path_11574" data-name="Path 11574" d="M23.826,9.58c-1.588-2.586-5.2-6.925-11.54-6.925S2.335,6.994.747,9.58a5.025,5.025,0,0,0,0,5.285c1.588,2.586,5.2,6.925,11.54,6.925s9.952-4.339,11.54-6.925A5.025,5.025,0,0,0,23.826,9.58Zm-1.746,4.213c-1.364,2.218-4.451,5.95-9.794,5.95s-8.431-3.732-9.794-5.95a2.988,2.988,0,0,1,0-3.141C3.856,8.435,6.943,4.7,12.286,4.7s8.431,3.728,9.794,5.95A2.988,2.988,0,0,1,22.081,13.794Z" transform="translate(0.004 -2.654)" />
      <path id="Path_11575" data-name="Path 11575" d="M12.119,7a5.119,5.119,0,1,0,5.119,5.119A5.119,5.119,0,0,0,12.119,7Zm0,8.191a3.072,3.072,0,1,1,3.072-3.072A3.072,3.072,0,0,1,12.119,15.191Z" transform="translate(0.171 -2.551)" />
    </g>
  </svg>
}
export const Delete = ({ action }) => {
  return <svg className="svgicon" onClick={action} xmlns="http://www.w3.org/2000/svg" width="18.622" height="18.622" viewBox="0 0 18.622 18.622">
    <path id="circle-remove" d="M9.311,0a9.311,9.311,0,1,0,9.311,9.311A9.338,9.338,0,0,0,9.311,0Zm4.074,11.755-1.629,1.629L9.311,10.94,6.867,13.385,5.237,11.755,7.682,9.311,5.237,6.867,6.867,5.237,9.311,7.682l2.444-2.444,1.629,1.629L10.94,9.311Z"  />
  </svg>
}
export const Plusicon = ({ action }) => <svg className="svgicon" onClick={action} xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 64 64">
  <g id="Group_17156" data-name="Group 17156" transform="translate(-719 -532.844)">
    <g id="Component_89_1" data-name="Component 89 – 1" transform="translate(719 532.844)">
      <g id="Path_11391" data-name="Path 11391" fill="#3B3BFF">
        <path d="M 32 60.5 C 28.15097808837891 60.5 24.41895484924316 59.74694442749023 20.90758895874023 58.26176834106445 C 17.51424407958984 56.82649993896484 14.46597766876221 54.77106857299805 11.84745597839355 52.15254592895508 C 9.228933334350586 49.53402328491211 7.173500061035156 46.48575592041016 5.73823356628418 43.09241104125977 C 4.253055572509766 39.5810432434082 3.5 35.84902191162109 3.5 32 C 3.5 28.15097808837891 4.253055572509766 24.41895484924316 5.73823356628418 20.90758895874023 C 7.173500061035156 17.51424407958984 9.228933334350586 14.46597766876221 11.84745597839355 11.84745597839355 C 14.46597766876221 9.228933334350586 17.51424407958984 7.173500061035156 20.90758895874023 5.73823356628418 C 24.41895484924316 4.253055572509766 28.15097808837891 3.5 32 3.5 C 35.84902191162109 3.5 39.5810432434082 4.253055572509766 43.09241104125977 5.73823356628418 C 46.48575592041016 7.173500061035156 49.53402328491211 9.228933334350586 52.15254592895508 11.84745597839355 C 54.77106857299805 14.46597766876221 56.82649993896484 17.51424407958984 58.26176834106445 20.90758895874023 C 59.74694442749023 24.41895484924316 60.5 28.15097808837891 60.5 32 C 60.5 35.84902191162109 59.74694442749023 39.5810432434082 58.26176834106445 43.09241104125977 C 56.82649993896484 46.48575592041016 54.77106857299805 49.53402328491211 52.15254592895508 52.15254592895508 C 49.53402328491211 54.77106857299805 46.48575592041016 56.82649993896484 43.09241104125977 58.26176834106445 C 39.5810432434082 59.74694442749023 35.84902191162109 60.5 32 60.5 Z" stroke="#3B3BFF" />
        <path d="M 32 7 C 18.2149543762207 7 7 18.2149543762207 7 32 C 7 45.7850456237793 18.2149543762207 57 32 57 C 45.7850456237793 57 57 45.7850456237793 57 32 C 57 18.2149543762207 45.7850456237793 7 32 7 M 32 0 C 49.6731071472168 0 64 14.3268928527832 64 32 C 64 49.6731071472168 49.6731071472168 64 32 64 C 14.3268928527832 64 0 49.6731071472168 0 32 C 0 14.3268928527832 14.3268928527832 0 32 0 Z" stroke="3B3BFF" fill="#3B3BFF" />
      </g>
      <g id="Group_16987" data-name="Group 16987" transform="translate(-977 -530)">
        <rect id="Rectangle_6510" data-name="Rectangle 6510" width="7" height="29" rx="3" transform="translate(1005 548)" fill="#fff" />
        <rect id="Rectangle_6511" data-name="Rectangle 6511" width="7" height="29" rx="3" transform="matrix(-0.017, -1, 1, -0.017, 994.063, 566.253)" fill="#fff" />
      </g>
    </g>
  </g>
</svg>

export const DeleteIcon = ({ action }) => {

}

export const Translateicon = ({ action }) => {

}
export const Disableicon = ({ action }) => {
  return <svg className="disableicon" onClick={action} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18.622 18.622">
    <path id="circle-remove" d="M9.311,0a9.311,9.311,0,1,0,9.311,9.311A9.338,9.338,0,0,0,9.311,0Zm4.074,11.755-1.629,1.629L9.311,10.94,6.867,13.385,5.237,11.755,7.682,9.311,5.237,6.867,6.867,5.237,9.311,7.682l2.444-2.444,1.629,1.629L10.94,9.311Z"  />
  </svg>
}
export const Garbage=({action})=>{
  return <svg className="garbage" onClick={action} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="22.054" viewBox="0 0 24.058 22.054">
  <path id="Path_9384" data-name="Path 9384" d="M23.054,2H18.044V1a1,1,0,0,0-1-1H7.018a1,1,0,0,0-1,1V2H1A1,1,0,1,0,1,4.011h2V19.047a3.007,3.007,0,0,0,3.007,3.007H18.044a3.007,3.007,0,0,0,3.007-3.007V4.011h2a1,1,0,0,0,0-2.007ZM19.047,19.047a1,1,0,0,1-1,1H6.015a1,1,0,0,1-1-1V4.011H19.047Z" transform="translate(0 0)" />
  <path id="Path_9385" data-name="Path 9385" d="M13.668,21.1a1,1,0,0,0,1-1V12.083a1,1,0,0,0-2.007,0V20.1a1,1,0,0,0,1,1Z" transform="translate(-4.647 -4.065)" />
  <path id="Path_9386" data-name="Path 9386" d="M23.168,21.1a1,1,0,0,0,1-1V12.083a1,1,0,1,0-2.007,0V20.1A1,1,0,0,0,23.168,21.1Z" transform="translate(-8.132 -4.065)" />
</svg>
}
export const BlockIcon = ({ action }) => {
  // return <svg xmlns="http://www.w3.org/2000/svg" className="svgicon" width="22" height="22" viewBox="0 0 22 22" onClick={action}>
  //   <g id="Group_17628" data-name="Group 17628" transform="translate(-1232 -644)">
  //     <g id="Ellipse_1238" data-name="Ellipse 1238" transform="translate(1232 644)"  stroke-width="2.5">
  //       <circle cx="11" cy="11" r="11"  />
  //       <circle cx="11" cy="11" r="9.75"  />
  //     </g>
  //     <line id="Line_54" data-name="Line 54" x1="15" y2="9" transform="translate(1235.5 650.5)"   stroke-width="2.5" />
  //   </g>
  // </svg>
  return <svg xmlns="http://www.w3.org/2000/svg" className="svgiconblock" width="20" height="22" viewBox="0 0 22 22" onClick={action}>
  <g id="Group_17629" data-name="Group 17629" transform="translate(-1232 -644)">
    <g id="Ellipse_1238" data-name="Ellipse 1238" transform="translate(1232 644)" fill="none" stroke="#d0dbe5" stroke-width="2.5">
      <circle cx="11" cy="11" r="11" stroke="none"/>
      <circle cx="11" cy="11" r="9.75" fill="none" /> {/* it should be */}
    </g>
    <line id="Line_54" data-name="Line 54" x1="15" y2="9" transform="translate(1235.5 650.5)" fill="none" stroke="#d0dbe5" stroke-width="2.5"/>
  </g>
</svg>

}