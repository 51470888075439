import { Card, Checkbox, Grid } from "@mui/material";
import { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Userdata } from "../../../../Components1/MainDashboard/Container/Comments/Users";
import { SearchBar } from "../../../../components/Searchbar";
import { CheckedIcon } from "../../../../Components1/MainDashboard/Container/Comments/icons/Checkedicon"; 
import { Check, CircleRemove } from "../../../../Components1/MainDashboard/Container/Comments/icons/Check";
import { Styles } from "../../../../Components1/MainDashboard/Container/Comments/styles";
import { AlertDialog } from "../../../../Components1/MainDashboard/Container/components/Dialog/AlertDialogs";
import { Customdivider } from "../../../../Components1/MainDashboard/Container/components/divider/Customdivider";
import { Succsess } from "../../../../Components1/MainDashboard/Container/components/feedbacks/Feedbacks";
import { useQuery } from "@apollo/client";
import { Data_Comments } from "../../../../api/Comments/commentsApi"; 
import { Backdrops } from "../../../../components/Backdrops";
import { Singlecomment } from "../../../../Components1/MainDashboard/Container/Comments/singleComment";

export const CommentsPage = () => {
    
    const stylee = Styles();
    const [checking, setcheking] = useState(false);
    var [checked, setChecked] = useState(Userdata);
    const [open, setopen] = useState();
    const [val, setval] = useState({});
    const [opensnack, setopensnack] = useState();
    const [disableall, setdisableall] = useState();
    const handleChange1 = (event) => {
        setcheking(!checking);
        if (checking) {
            checked.map((item) => {
                item.select = false;
            });
        } else {
            checked.map((item) => {
                item.select = true;
            });
        }

        setChecked(checked);
    };
    const { loading, error, data1 } = useQuery(Data_Comments, {
        fetchPolicy: "network-only",
        onCompleted: (data12) => {
            setChecked(data12.comments);
        },
    });
    if (loading) {
        return <Backdrops />;
    }
    return (
        // <Grid
        //     xs={12}
        //     style={{ height: "100%", marginTop: "15px", borderRadius: "20px" }}
        // >
            
        //     <AlertDialog
        //         message={"Are you sure to apply changes ?"}
        //         actiontext="Yes"
        //         close={() => {
        //             setdisableall(false);
        //         }}
        //         open={disableall}
        //         action={() => {
        //             checked.map((item) => {
        //                 console.log(checked);
        //                 if (item.select === true) {
        //                     if (item.disable == true) {
        //                         item.disable = false;
        //                         item.select = false;
        //                     } else {
        //                         item.disable = true;
        //                         item.select = false;
        //                     }
        //                 }
        //             });
        //             setChecked([...checked]);
        //             setcheking(false);
        //             setdisableall(false);
        //         }}
        //     />
        //     <Scrollbars
        //         thumbSize={50}
        //         autoHideTimeout={10}
        //         autoHide
        //         hideTracksWhenNotNeeded={true}
        //         style={{ height: "100%", marginTop: "15px" }}
        //     >
        //         <Grid item md={12} style={{ paddingBottom: "1.3vh" }}>
        //                 <SearchBar />
        //         </Grid>
        <Grid spacing={1} style={{marginTop: "15px",}}>
        <SearchBar />
                <Grid md={12} className={stylee.commentspart} style={{ marginTop: "15px" }}>
                    <Grid md={11.2} style={{ margin: "auto" }}>
                        <Grid md={12} container>
                            <Grid
                                md={11.96}
                                container
                                style={{ paddingBottom: "2px", margin: "auto" }}
                            >
                                <Grid md={11.65}>
                                    <Checkbox
                                        checked={checking}
                                        checkedIcon={<CheckedIcon />}
                                        icon={
                                            <div className={stylee.checkbox}>
                                                <Check />
                                            </div>
                                        }
                                        sx={{
                                            color: "#D0DBE5",
                                            "&.Mui-checked": {
                                                color: "#3b3bff",
                                                borderRadius: 20,
                                                padding: 0
                                            },
                                        }}          
                                        onChange={handleChange1}
                                    />
                                </Grid>
                                <Grid
                                    md={0.35}
                                    onClick={async () => {
                                        setdisableall(true);
                                    }}
                                >
                                    <CircleRemove />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Customdivider size={12} />
                        <AlertDialog
                            message={
                                val.disable === true
                                    ? `Are you sure to Enable this comment?`
                                    : "Are you sure to Disable this comment?"
                            }
                            open={open}
                            close={() => {
                                setopen(false);
                            }}
                            action={async () => {
                                
                                checked.map((item) => {
                                    if (item.id === val.id) {
                                        item.disable = !item.disable;
                                    }
                                });
                                await setChecked(checked);
                                setopen(false);
                                setopensnack(true);
                            }}
                            actiontext={
                                val.disable === true ? "Enable" : "Disable"
                            }
                        />
                        {checked.map((item) => {
                            return (
                                <Singlecomment
                                    item={item}
                                    onchangecheckbox={() => {
                                        item.select = !item.select;
                                        setChecked([...checked]);
                                    }}
                                    action={() => {
                                        setopen(true);
                                        setval(item);
                                    }}
                                />
                            );
                        })}
                        <Grid
                            xs={11.3}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 10,
                                paddingBottom: 20,
                            }}
                        >
                        </Grid>
                    </Grid>
                </Grid>

                <Succsess
                    title="Task Successfully Done!"
                    action={() => {
                        setopensnack(false);
                    }}
                    snackopen={opensnack}
                />
            {/* </Scrollbars> */}
        </Grid>
    );
};
