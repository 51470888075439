import { makeStyles } from "@mui/styles";


export const Styles = makeStyles({
    headertext:{
        fontFamily: "Montserrat-Regular !important-Regular !important",
        fontSize: "12px !important",
    },
    category1:{
        verticalAlign: "middle",
        alignItems: "center",
        display: "flex",
        color: "#BAC4CC",
        fontWeight: "bold",
        letterSpacing: "0.5px !important",
    },
    eventname:{fontFamily: "Montserrat-Regular !important-Regular !important",
    paddingLeft: 23,
    color: "#BAC4CC",
    fontSize: "12px !important",
    fontWeight: "bold",
    letterSpacing: "0.5px",},

    containerr:{backgroundColor: "white",
    borderRadius: "1vw",
    boxShadow: "4px 4px 20px rgb(208, 221, 232)",},
    cardd:{ backgroundColor: "white",
    borderRadius: "1vw !important",
    boxShadow: "4px 4px 20px #d7e3eb",
    padding: "12px 40px",},

    text: {
        '&.MuiTypography-root': { fontFamily: "Montserrat-Regular !important-Regular !important", fontSize: "11px", letterSpacing: "0.5px", color: "#31515C" }
    },
    rootstyle: {
        "&.MuiGrid-root": {
            verticalAlign: "middle", alignItems: "center", display: "flex"
        }
    }, firstcoloumn: { fontSize: "11px !important", fontFamily: "Montserrat-Regular !important-Regular !important !important", marginBottom: "5px !important", letterSpacing: 0.5, color: "#BAC4CC !important" }
    , secondcoloumn: { fontSize: "11px !important", fontFamily: "Montserrat-Regular !important-Regular !important !important", marginBottom: "5px !important", color: "#31515C !important" },
    volounteers: {
        fontFamily: "Montserrat-Regular !important-Regular !important !important", fontSize: '11px !important', color: "#BAC4CC !important"
    },
    container: {"&:hover":{
        backgroundColor:"#EAEEFA",border:"1px solid #8ECADD"
    }, borderRadius: 8, border: "1px solid #E4EAF0", margin: "auto", marginBottom: 13 },
    rootstyledisbale: {
        "&.MuiGrid-root": {
            verticalAlign: "middle", alignItems: "center", display: "flex",backgroundColor:"#dfe3e7"
        }
    },textdisable: {
        '&.MuiTypography-root': { fontFamily: "Montserrat-Regular !important-Regular !important", fontSize: "11px", letterSpacing: "0.5px", color: "#a9c1d6" }
    },containerdisable: {"&:hover":{
        backgroundColor:"#dfe3e7 !imporatnt",border:"1px solid #8ECADD"
    }, borderRadius: 8, border: "1px solid #E4EAF0", margin: "auto", marginBottom: 13,backgroundColor:"#dfe3e7 " }


})
