import { Grid, Typography } from "@mui/material";
import { Garbage, Pen } from "../../components/icons.js/actionicons";
import { useState } from "react";
import { AlertDialog } from "../../components/Dialog/AlertDialogs";

export const Feild = ({    
    setopenedit,
    value,
    item,
    delet,
}) => {
    const [open, setopen] = useState();

    return (
        <Grid
            xs={12}
            sx={{
                "&:hover": {
                    background: "#EAEEFA",
                },
            }}
            style={{ borderRadius: "14px", margin: "auto", marginTop: 10 }}
        >
            <Grid
                xs={12}
                container
                style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}
            >
                <AlertDialog
                    open={open}
                    message="Are you sure you want to Delete this item?"
                    actiontext="Delete"
                    action={() => {
                        delet(item);
                        setopen(false);
                        console.log(item);
                    }}
                    close={() => {
                        setopen(false);
                    }}
                />

                <Grid
                    xs={5.15}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <span
                        style={{
                            paddingLeft: "25px",
                            fontWeight: "unset",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            color: "#31515C",
                            fontSize: "11px",
                        }}
                    >
                        {item.text}
                    </span>
                </Grid>

                <Grid xs={5} style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        style={{
                            fontWeight: "unset",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            color: "#31515C",
                            fontSize: "11px",
                        }}
                    >
                        {item.translate}
                    </Typography>
                </Grid>

                <Grid container xs={1.8} style={{ backgroundColor: "none" }}>
                    <div
                        style={{
                            width: "40%",
                            fontWeight: "unset",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            color: "#31515C",
                            fontSize: "11px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >                    
                        <Pen
                            action={() => {
                                value(item);
                                setopenedit();
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: "23%",
                            justifyContent: "center",
                            fontWeight: "unset",
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            color: "#31515C",
                            fontSize: "11px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Garbage
                            action={() => {
                                setopen(true);
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};
