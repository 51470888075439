import { Button, Card, Grid, TextField } from "@mui/material";
//
import { useStyles } from "../StartPage/Styles";
import { SearchIcon } from "../Components1/MainDashboard/Container/Category/Icons/SearchIcons";
import { SearchFilterComments } from "../Components1/MainDashboard/Container/Comments/Filteration";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";

export const SearchBar = () => {
  const classes = useStyles();
  return (
    <Card
      fullWidth
      style={{
        backgroundColor: "white",
        borderRadius: "1vw",
        boxShadow: "4px 4px 20px #d7e3eb",
        padding: "12px 40px",
      }}
    >
      <Grid
        container
        spacing={1.2}
        style={{
          // justifyContent: "center",

          paddingTop: "1vh",
          paddingBottom: "1vh",
        }}
      >
        <Grid item xs={12} style={{ display: "flex" }}>
          <form style={{ marginRight: 15, width: "100%" }}>
            <TextField
            style={{ width: "80%" }}
              InputProps={{
                style: { padding: 0, borderRadius: "1vw", width: "100%"  },
                startAdornment: <SearchIcon />,
              }}
              placeholder="Search"
              inputProps={{
                style: {
                  paddingTop: 18,
                  paddingBottom: 18,
                  
                },
              }}
              className={classes.Search_category}
            />
          </form>
          <Grid item xs={0.5} style={{ display: "flex"}}>
            {/* <Button
              // variant="contained"
              // disabled
              sx={{
                // paddingBlock: "6px !important",
                backgroundColor: "#F1F3F7",

                "&:hover": {
                  backgroundColor: "#000",
                },
              }}
              style={{
                color: "#FFF",
                justifyContent: "center",
                display: "flex",
                borderRadius: "8px",
                borderColor: "#3b3bff",
                padding: "10px",
                width: "58px",
              }}
            >
              CSV
            </Button> */}
            <IconButton
              sx={{
                // paddingBlock: "6px !important",
                backgroundColor: "#F1F3F7",

                "&:hover": {
                  backgroundColor: "#DFDFFF55",
                },
              }}
              style={{
                color: "#919EB8",
                justifyContent: "center",
                display: "flex",
                borderRadius: "15px",
                borderColor: "#3b3bff",
                padding: "10px",
                width: "58px",
              }}
            >
              <FilterListIcon style={{ width: 30, height: 30 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
