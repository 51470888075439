import React from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from "@mui/material";

const styles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        backgroundColor: "#fff",
        flexDirection: "column",
    },
    progress: {
        marginTop: 20,
        color: "#3b3bff",marginLeft:"100px"
    },
}));

export const Backdrops = ({loading}) => {
    const classes = styles();
    
    return (
        // <MuiBackdrop className={classes.backdrop} open={loading}>
            
        // </MuiBackdrop>
        <div style={{width:"100%",height:"100%",display:"flex",justifyContent:"center" , alignItems:"center"}}>
       <div>
       <CircularProgress color="inherit" className={classes.progress}/>
        <Typography style={{fontFamily:"Montserrat-Regular !important-Regular !important",display:"flex",justifyContent:"center" , alignItems:"center" , padding:"10px"}}>
            Loading the content please wait ...
        </Typography>
       </div>

        </div>
    );
};


