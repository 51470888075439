import { useEffect, useState } from "react";
import { Button, Card, Grid, TextField, Typography, Avatar } from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
//
import Scrollbars from "react-custom-scrollbars-2";
import { SearchBar } from "../../../../components/Searchbar";
import { AlertDialog } from "../../../../Components1/MainDashboard/Container/components/Dialog/AlertDialogs";
import { Styles } from "../../../../Components1/MainDashboard/Container/Events/Styles";
import { Backdrops } from "../../../../components/Backdrops";
import DataTable from "../../../../components/DataTables";
import ActionButtons from "../../../../components/Buttons/ActionButtons";
import { EVENTS_DATA } from "../../../../api/Events/eventsApi";
import { baseurl } from "../../../../BaseUrl";
import { CSV_Downloader } from "../../../../components/CSV_Downloader";
import DefaultAvatar from "../../../../assets/svg/DefaultAvatar.svg";


function Events() {

  const { loading, error, data } = useQuery(EVENTS_DATA);
  const HeaderData = [
    { field: "EventImage", title: "Image" },
    { field: "title", title: "Event Name" },
    { field: "players", title: "Players" },
    
    // { field: "CreatorFirstName", title: "First Name" },
    // { field: "CreatorLastName", title: "Last Name" },
    { field: "from", title: "From" },
    { field: "to", title: "To" },
    { field: "loc", title: "Location" },
    // { field: "country", title: "Country" },
    // { field: "action", title: "Action" },



  ];
  if (loading) return <Backdrops loading={loading} />;
  if (error)
    return (
      <span>
        Error Fetching Data ! <br /> Please Reload
      </span>
    );
  if (data !== null) {
    return (

      <Grid spacing={1} style={{marginTop: "15px",}}>
      <SearchBar />
              <Grid
                style={{
                  height: "calc(80vh - 110px)",
                  borderRadius: "15px",
                  marginTop: "15px",
                  paddingLeft:"8px",
                }}
                spacing={1}
                container
              >
                {/* <UsersLists loading={loading} data1={data } error = {error}/> */}
                <Grid
                  item
                  xs={8}
                  style={{
                    backgroundColor: "white",
                    // width: "66%",
                    // height: "100%",
                    borderRadius: "15px",
                    boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    // marginLeft: "8px",
                    marginRight: 0,
                    // paddingLeft: "15px",
                  }}
                >
                    <Scrollbars style={{borderRadius: "15px"}}>
                  <DataTable
                    
                    // xs={5}
                    // title="Events"
                    loading={loading}
                    headData={HeaderData}
                    data={data?.adminEventsList ?? []}
                    action={(item) => (
                      <ActionButtons
                          functions={{
                            show: () => "",
                          }}
                          show
                      />
                  )}
                  from={(item) =>
                    moment(item?.fromTime).format("DD/MMM/YYYY    HH:mm")
                  }
                  to={(item) =>
                    moment(item?.toTime).format("DD/MMM/YYYY    HH:mm")
                  }
                  players={(item) =>
                    item.playmateCount == 100000076 ? 'Unlimited' : item.playmateCount
                  }
                  loc={(item) => 
                    item.city + ' - ' + item.country
                  }

                  EventImage={(item) => (
                            // item?.img ? (
                            // <img
                            //   style={{
                            //     width: "40px",
                            //     height: "40px",
                            //     marginTop: 5,
                            //     borderRadius: "100%",
                            //   }}
                            //   src={baseurl + item?.img}
                            // />
                            <Avatar
                              sx={{
                                width: "100px",
                                height: "55px",
                                borderRadius: "10%",
                                margin: 1,
                                // marginLeft: "25%",
                                // marginRight: "25%",

                              }}
                              alt={item.firstName?.toUpperCase()}
                              src={baseurl + item?.image}
                              />
                      )
                    }
                  />
                  </Scrollbars>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ paddingTop:0 , paddingLeft:15 ,height: 500, backgroundColor: "none" }}
                >
                  <Card
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      height: "48%",
                      borderRadius: "15px",
                      boxShadow: "4px 4px 15px rgba(206, 211, 228, 0.4)",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      // marginBottom: "",
                    }}
                  >
                    <Grid
                      style={{
                        justifyContent: "center",
                        display: "inline-block",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#31515c",
                          margin: 20,
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Total Events : {data?.adminEventsList.length}
                      </Typography>
                      <Typography style={{ color: "#31515c", margin: 20 }}>
                        {/* <CSV_Downloader dataCSV={data?.usersList} title={'Download Users Data'} fileName={"Users Data"}/> */}
                        {/* <CSV_Downloader dataCSV={data?.allEventsList}/> */}
    
                      </Typography>
                    </Grid>
                  </Card>
                  
                  <Card
                  shadow
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      height: "48%",
                      borderRadius: "15px",
                      boxShadow: "4px 4px 10px 15px rgba(206, 211, 228, 0.2)",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      marginTop : 15,
                      // marginBottom: "",
                    }}
                  >
                    <Grid
                      style={{
                        justifyContent: "center",
                        display: "inline-block",
                        alignItems: "center",
                       
                      }}
                    >
                      <Typography
                        style={{
                          color: "#31515c",
                          margin: 20,
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* Total Users : {data?.allEventsList.length} */}
                      </Typography>
                    </Grid>
                  </Card>
    
                  {/* <Card
                                
                                style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                    height: "47.6%",
                                    marginTop: "5%",
                                    borderRadius: "15px",
                                    boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                                    justifyContent: "center",
                                    alignItems:"center",
                                    display: 'flex'
                                    
                                }}
                            >
                                 
                            </Card> */}
                </Grid>
              </Grid>
              </Grid>

      );
  }
}
export default Events;
