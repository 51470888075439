import { Avatar, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const Customavatar=({link,customsize})=>{
    const nav = useNavigate()
    return (
    <Avatar
        onClick={()=>{
            nav(`/Dashboard/users/${link}`)
        }}
        sx={{
            width:customsize ? customsize.width :30,
            height: customsize ? customsize.height :30,cursor:"pointer"
        }}
        alt="Remy Sharp"
        src="https://lbmslab.org/lab/wp-content/uploads/2018/09/beautiful-beauty-casual-762020.jpg"
    ></Avatar>)
}