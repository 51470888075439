import { InputAdornment, SvgIcon, TextField } from "@mui/material";
import { useStyles } from './Styles';


export function TextFields(props) {
    const classes = useStyles();
    
    return (<TextField 
        type={props.Feild}
        id={props.id}
        onChange={props.onch}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start" style={{ marginRight: 10 }}>
                    <SvgIcon style={{paddingTop:6,fontSize:16,margin: '0 10px', width:19.13,height:17.15}}>
                        {props.Icon}
                    </SvgIcon>
                </InputAdornment>
            ),
        }}
        className={classes.rootss} placeholder={props.holder}  />)

}
