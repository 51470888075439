import { Autocomplete, Divider, Grid } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const TopNavigate = () => {
    const nav = useNavigate();
    const param = useLocation();

    return (
        <Grid xs={11} style={{ margin: "auto", paddingTop: "50px" }}>
            <Grid container xs={12}>
                <Grid
                    onClick={() => {
                        nav("sms");
                    }}
                    xs={1.75}
                    style={{
                        backgroundColor: param.pathname.includes("sms")
                            ? "#99CEDE"
                            : "white",
                        display: "flex",
                        justifyContent: "center",
                        height: "50px",
                        border: param.pathname.includes("sms")
                            ? "none"
                            : "1px solid #0084AD",
                        marginRight: "8px",
                        alignItems: "center",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        color: param.pathname.includes("sms")
                            ? "#3b3bff"
                            : "#31515C",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            fontSize: "0.8vw",
                            letterSpacing: 0.4,
                        }}
                    >
                        SMS
                    </span>
                </Grid>
                <Grid
                    onClick={() => {
                        console.log(param);
                        nav("mobilenotif");
                    }}
                    xs={1.63}
                    style={{
                        backgroundColor: param.pathname.includes("mobilenotif")
                            ? "#99CEDE"
                            : "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "8px",
                        border: param.pathname.includes("mobilenotif")
                            ? "none"
                            : "1px solid #0084AD",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        color: param.pathname.includes("mobilenotif")
                            ? "#3b3bff"
                            : "#31515C",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            fontSize: "0.8vw",
                            letterSpacing: 0.4,
                        }}
                    >
                        Notiffications
                    </span>
                </Grid>
                <Grid
                    onClick={() => {
                        nav("email");
                    }}
                    s
                    xs={1.63}
                    style={{
                        backgroundColor: param.pathname.includes("email")
                            ? "#99CEDE"
                            : "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: param.pathname.includes("email")
                            ? "none"
                            : "1px solid #0084AD",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        color: param.pathname.includes("email")
                            ? "#3b3bff"
                            : "#31515C",
                    }}
                >
                    <span
                        x={{
                            "&:hover": {
                                backgroundColor: "#EAEEFA",
                            },
                        }}
                        style={{
                            fontFamily: "Montserrat-Regular !important-Regular !important",
                            fontSize: "0.8vw",
                            letterSpacing: 0.4,
                        }}
                    >
                        Email
                    </span>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <Grid xs={7.25}>
                    <Grid xs={12}>
                        <Divider
                            style={{ border: "1px solid rgb(235, 242, 247)" }}
                        ></Divider>
                    </Grid>
                    <Outlet />
                </Grid>
            </Grid>
        </Grid>
    );
};

export const MessagePart = () => {
    return (
        <Grid
            xs={12}
            style={{
                backgroundColor: "white",
                height: "86%",
                marginTop: "15px",
                borderRadius: "25px",
            }}
        >
            <TopNavigate />
        </Grid>
    );
};
