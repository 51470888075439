export const Plusicon=(props)=><svg onClick={props.press} xmlns="http://www.w3.org/2000/svg"  width="50px" height="50px" viewBox="0 0 64 64">
<g id="Group_17156" data-name="Group 17156" transform="translate(-719 -532.844)">
  <g id="Component_89_1" data-name="Component 89 – 1" transform="translate(719 532.844)">
    <g id="Path_11391" data-name="Path 11391" fill="#f90">
      <path d="M 32 60.5 C 28.15097808837891 60.5 24.41895484924316 59.74694442749023 20.90758895874023 58.26176834106445 C 17.51424407958984 56.82649993896484 14.46597766876221 54.77106857299805 11.84745597839355 52.15254592895508 C 9.228933334350586 49.53402328491211 7.173500061035156 46.48575592041016 5.73823356628418 43.09241104125977 C 4.253055572509766 39.5810432434082 3.5 35.84902191162109 3.5 32 C 3.5 28.15097808837891 4.253055572509766 24.41895484924316 5.73823356628418 20.90758895874023 C 7.173500061035156 17.51424407958984 9.228933334350586 14.46597766876221 11.84745597839355 11.84745597839355 C 14.46597766876221 9.228933334350586 17.51424407958984 7.173500061035156 20.90758895874023 5.73823356628418 C 24.41895484924316 4.253055572509766 28.15097808837891 3.5 32 3.5 C 35.84902191162109 3.5 39.5810432434082 4.253055572509766 43.09241104125977 5.73823356628418 C 46.48575592041016 7.173500061035156 49.53402328491211 9.228933334350586 52.15254592895508 11.84745597839355 C 54.77106857299805 14.46597766876221 56.82649993896484 17.51424407958984 58.26176834106445 20.90758895874023 C 59.74694442749023 24.41895484924316 60.5 28.15097808837891 60.5 32 C 60.5 35.84902191162109 59.74694442749023 39.5810432434082 58.26176834106445 43.09241104125977 C 56.82649993896484 46.48575592041016 54.77106857299805 49.53402328491211 52.15254592895508 52.15254592895508 C 49.53402328491211 54.77106857299805 46.48575592041016 56.82649993896484 43.09241104125977 58.26176834106445 C 39.5810432434082 59.74694442749023 35.84902191162109 60.5 32 60.5 Z" stroke="none"/>
      <path d="M 32 7 C 18.2149543762207 7 7 18.2149543762207 7 32 C 7 45.7850456237793 18.2149543762207 57 32 57 C 45.7850456237793 57 57 45.7850456237793 57 32 C 57 18.2149543762207 45.7850456237793 7 32 7 M 32 0 C 49.6731071472168 0 64 14.3268928527832 64 32 C 64 49.6731071472168 49.6731071472168 64 32 64 C 14.3268928527832 64 0 49.6731071472168 0 32 C 0 14.3268928527832 14.3268928527832 0 32 0 Z" stroke="none" fill="#fff"/>
    </g>
    <g id="Group_16987" data-name="Group 16987" transform="translate(-977 -530)">
      <rect id="Rectangle_6510" data-name="Rectangle 6510" width="7" height="29" rx="3" transform="translate(1005 548)" fill="#fff"/>
      <rect id="Rectangle_6511" data-name="Rectangle 6511" width="7" height="29" rx="3" transform="matrix(-0.017, -1, 1, -0.017, 994.063, 566.253)" fill="#fff"/>
    </g>
  </g>
</g>
</svg>