import { Card, Grid, Typography } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import { Bar_Chart } from "../../../../components/Charts/BarChart";
import PieChart from "../../../../components/Charts/PieChart";

import { useQuery } from "@apollo/client";
import Divider from "@mui/material/Divider";
//
import {
  STATISTICS
} from "../../../../api/Statistics/statisticsApi";
import Apexcharts from "apexcharts";

const MainDashboard = () => {
  const { loading, error, data } = useQuery(STATISTICS);
  console.log({data});
  console.log({error});
  return (
    <Grid container style={{ marginTop: "15px" }}>
      {/* <Grid item xs={3.5}>
        {" "}
        <Card
          style={{
            borderRadius: 15,
            height: "39vh",
            boxShadow: "4px 4px 20px #d7e3eb",
          }}
        ></Card>
      </Grid> */}
      <Grid item xs={3.5}>
        {" "}
        <Card
          style={{
            borderRadius: 15,
            height: "40vh",
            boxShadow: "4px 4px 20px #d7e3eb",
            // marginTop: 15,
          }}
        >
          <Grid
            item
            xs={12}
            style={{ marginLeft: 70, marginTop: 30, marginBottom: 30 }}
          >
            <Typography>Sports</Typography>
          </Grid>
          <Divider
            style={{
              margin: "20px auto",
              width: "75%",
              borderWidth: "2px",
              borderColor: "#F2F2FF",
            }}
          />
          <PieChart />
        </Card>
      </Grid>
      <Grid item xs={3}>
        {" "}
        <Card
          style={{
            display: "flex",
            borderRadius: 15,
            height: "40vh",
            boxShadow: "4px 4px 20px #d7e3eb",
            marginLeft: 15,
            // padding:25,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Grid> */}
          {/* </Grid> */}
        </Card>
      </Grid>

      <Grid item xs={5.5}>
        <Card
          style={{
            borderRadius: 15,
            height: "40vh",
            boxShadow: "4px 4px 20px #d7e3eb",
            marginLeft: 15,
          }}
        >
          <Grid
            item
            xs={12}
            style={{ marginLeft: 70, marginTop: 30, marginBottom: 30 }}
          >
            <Typography>Interest</Typography>
          </Grid>
          <Divider
            style={{
              margin: "20px auto 0px auto",
              width: "85%",
              borderWidth: "2px",
              borderColor: "#F2F2FF",
            }}
          />
          <Bar_Chart data={data?.getInterestStatistics} color={'#F6AACB'}/>
        </Card>
      </Grid>
      <Grid item xs={7.5}>
        <Card
          style={{
            borderRadius: 15,
            height: "40vh",
            boxShadow: "4px 4px 20px #d7e3eb",
            marginTop: 15,
          }}
        >
          <Grid
            item
            xs={12}
            style={{ marginLeft: 70, marginTop: 30, marginBottom: 30 }}
          >
            <Typography>Sports</Typography>
          </Grid>
          <Divider
            style={{
              margin: "20px auto 0px auto",
              width: "85%",
              borderWidth: "2px",
              borderColor: "#F2F2FF",
            }}
          />
          <Bar_Chart data={data?.getPlayStatistics} color={'#3B72FF'}/>
        </Card>
      </Grid>
      <Grid item xs={4.5}>
        <Card
          style={{
            borderRadius: 15,
            height: "40vh",
            boxShadow: "4px 4px 20px #d7e3eb",
            marginLeft: 15,
            marginTop: 15,
          }}
        ></Card>
      </Grid>
    </Grid>
  );
};

export default MainDashboard;
