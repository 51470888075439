import { makeStyles } from "@mui/styles";

export const header = makeStyles({

    title:{
        
        fontWeight: "bold !important",
        fontFamily: "Montserrat-Regular !important-Regular !important !important",
        color: "#31515C",
        fontSize: "12px !important",
}

})