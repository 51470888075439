import { Grid,Divider } from "@mui/material"
import { Styles } from "./style"


// size
export const Customdivider = ({size})=>{

    const classes = Styles()

return <Grid xs={size} style={{ margin: "auto" }}>
<Divider
    className={classes.divider}
/>
</Grid>
}