import { makeStyles } from "@mui/styles";


export const Styles = makeStyles({
    divider:{
        width: "100%",
        marginTop: "9px !important",
        marginBottom: "10px ",
        borderWidth: "1px !important",
        borderRadius: "20px !important",
        border: "1px solid rgb(235, 242, 247) !important",
        backgroundcolor:"rgb(235, 242, 247) !important"
    }
})


