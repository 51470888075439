import { Card, Grid, Typography, Avatar } from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { useQuery } from "@apollo/client";
//
import { FETCH_USERS } from "../../../../api/Users/usersApi";
import { baseurl } from "../../../../BaseUrl";
import DataTable from "../../../../components/DataTables";
import { SearchBar } from "../../../../components/Searchbar";
import { CSV_Downloader } from "../../../../components/CSV_Downloader";
import DefaultAvatar from "../../../../assets/svg/DefaultAvatar.svg";
import { Backdrops } from "../../../../components/Backdrops";
import ActionButtons from "../../../../components/Buttons/ActionButtons";

const Management = () => {
  const HeaderData = [
    { field: "avatar", title: "Image" },
    { field: "Name", title: "Name" },
    { field: "email", title: "Email" },
    { field: "role", title: "Role" },
    { field: "mobile", title: "Mobile" },
    { field: "os", title: "OS" },
    // { field: "action", title: "Action" },
  ];
  const { loading, error, data, refetch } = useQuery(FETCH_USERS, {
    variables: { page: 1, limit: 8, role: "TESTER" }
  });
  console.log(data);
  if (loading) return <Backdrops loading={loading} />;
  if (error)
    return (
      <span>
        Error Fetching Data ! <br /> Please Reload
      </span>
    );
  if (data !== null) {
    return (

      <Grid spacing={1} style={{ marginTop: "15px", }}>
        <SearchBar />
        <Grid item xs={12} style={{ marginLeft: 8 }}>
          <Grid
            style={{
              height: "calc(80vh - 110px)",
              borderRadius: "15px",
              marginTop: "15px",
            }}
            spacing={1}
            container
          >
            {/* <UsersLists loading={loading} data1={data } error = {error}/> */}
            <Grid
              item
              xs={8}
              style={{
                backgroundColor: "white",
                // width: "66%",
                // height: "100%",
                borderRadius: "15px",
                boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                // marginLeft: "8px",
                marginRight: 0,
                // paddingLeft: "15px",
              }}
            >
              <Scrollbars style={{ borderRadius: "15px" }}>
              <DataTable
                  // xs={5}
                  // title="Users"
                  loading={loading}
                  headData={HeaderData}
                  data={data?.usersList?.data ?? []}
                  Name={(item) => item.firstName + " " + item.lastName}
                  // Pagination={{item} => (
                  //   slots
                  // )}
                  lastPage={data?.usersList?.metadata?.totalPages}
                  currentPage={1}
                  onChange={(page) => refetch({ page: page, limit: 8, role: "TESTER" })}

                  action={(item) => (
                    <ActionButtons
                      functions={{
                        show: () => "",
                      }}
                      show
                    />
                  )}
                  avatar={(item) =>
                    <Avatar sx={{width: "40px",height: "40px", margin: 1, marginLeft:"25%"}} alt={item.firstName} src={baseurl + item?.img} />
                  }
                />
              </Scrollbars>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                paddingTop: 0,
                paddingLeft: 15,
                height: 500,
                backgroundColor: "none",
              }}
            >
              <Card
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "48%",
                  borderRadius: "15px",
                  boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "",
                }}
              >
                <Grid
                  style={{
                    justifyContent: "center",
                    display: "inline-block",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#31515c",
                      margin: 20,
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Total Users : {data?.usersList?.metadata.total}

                  </Typography>
                  <Typography style={{ color: "#31515c", margin: 20 }}>
                    {/* <CSV_Downloader dataCSV={data?.usersList} title={'Download Users Data'} fileName={"Users Data"}/> */}
                    {/* <CSV_Downloader dataCSV={data?.usersList} /> */}
                  </Typography>
                </Grid>
              </Card>

              <Card
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "48%",
                  borderRadius: "15px",
                  boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 15,
                  // marginBottom: "",
                }}
              >
                <Grid
                  style={{
                    justifyContent: "center",
                    display: "inline-block",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#31515c",
                      margin: 20,
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Total Users : {data?.usersList.length} */}
                  </Typography>
                </Grid>
              </Card>

              {/* <Card
                            
                            style={{
                                backgroundColor: "white",
                                width: "100%",
                                height: "47.6%",
                                marginTop: "5%",
                                borderRadius: "15px",
                                boxShadow: "4px 4px 20px rgb(208, 221, 232)",
                                justifyContent: "center",
                                alignItems:"center",
                                display: 'flex'
                                
                            }}
                        >
                             
                        </Card> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};
export default Management;
