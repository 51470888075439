import { gql, useMutation } from '@apollo/client';
export const STATISTICS = gql`
query{
    getPlayStatistics {
          name
          count
    }
    getInterestStatistics {
      name
      count
    }
  }
`;